import React from "react";
import { useApp } from "../../contexts/AppContext";

const Logout = () => {
  const { signOut } = useApp();

  const logout = async () => {
    await signOut();
  };

  return <div onClick={logout}>Sair</div>;
};

export default Logout;
