import { forwardRef } from "react";
import { styled } from "styled-components";

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;

  label {
    color: #000000;
    font-family: "Nunito Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.16px;
    line-height: 24px;
  }

  select {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #cfd3d8;
    border-radius: 4px;
    height: 40px;
    width: 100%;
  }
`;

const Select = forwardRef(
  ({ value, label, name, option = [], onChange, ...rest }, ref) => {
    return (
      <SelectWrapper>
        {label && (
          <label htmlFor="input-field" className="px-1">
            {label}
          </label>
        )}
        <select
          ref={ref}
          value={value}
          name={name}
          className="form-control"
          onChange={onChange}
          {...rest}
        >
          {option.map((item) => (
            <option key={item.label} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </SelectWrapper>
    );
  }
);

export default Select;
