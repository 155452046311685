import { forwardRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import cn from "classnames";
import { User } from "@styled-icons/fa-solid";

export const Avatar = forwardRef(({ source, onChange }, ref) => {
  const [loadImage, setLoadImage] = useState(false);

  const handleChange = (e) => {
    const file = e?.target?.files?.[0];
    if (file) onChange(file);
  };

  const url = source?.url || (source && URL.createObjectURL(source));

  return (
    <label
      for="avatar"
      className="cursor-pointer flex flex-col items-center gap-2"
    >
      {url ? (
        <div className="w-32 h-32 rounded-full overflow-hidden">
          {!loadImage && (
            <div className="w-full h-full flex items-center justify-center">
              <CircularProgress className="w-12 h-12" />
            </div>
          )}
          <img
            alt="imagem de perfil"
            src={url}
            className={cn("w-full h-full object-cover", {
              hidden: !loadImage,
            })}
            accept="image/*"
            onLoad={() => {
              setLoadImage(true);
            }}
          />
        </div>
      ) : (
        <div className="flex w-32 h-32 bg-gray-100 rounded-full mb-2 border-2 border-purple-heart">
          <User className="h-20 w-20 m-auto text-purple-heart/20" />
        </div>
      )}
      <span className="bg-purple-heart text-white px-8 py-2 rounded-3xl font-bold w-fit">
        Selecionar imagem
      </span>
      <input
        accept="image/png, image/jpeg"
        className="hidden"
        type="file"
        name="avatar"
        id="avatar"
        ref={ref}
        onChange={handleChange}
      />
    </label>
  );
});
