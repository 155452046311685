import PropTypes from "prop-types";
import { styled } from "styled-components";

const TextStyled = styled.p`
  font-family: "Nunito Sans-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 24px;
  color: #212429;
  ${({ variant }) => {
    switch (variant) {
      case "primary":
        return `
                    color: #212429;
                `;
      case "danger":
        return `
                    color: #FF0000;
                `;
      case "tertiary":
        return `
                    color: #6E7679;
                `;
      case "secondary":
        return `
                    color: #274b6c;
                `;
      default:
        return `
                    color: #212429;
                `;
    }
  }}
  ${({ size }) => {
    switch (size) {
      case "sm":
        return `
                    font-size: 12px;
                    line-height: 16px;
                `;
      case "md":
        return `
                    font-size: 16px;
                    line-height: 24px;
                `;
      case "lg":
        return `
                    font-size: 20px;
                    line-height: 30px;
                `;
      default:
        return `
                    font-size: 16px;
                    line-height: 24px;
                `;
    }
  }}
`;

const Text = ({ children, ...props }) => {
  return <TextStyled {...props}>{children}</TextStyled>;
};

Text.propTypes = {
  variant: PropTypes.oneOf(["primary", "danger", "tertiary", "secondary"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Text;
