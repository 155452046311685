import ODS_1 from "../../assets/ods/ods_01.jpg";
import ODS_2 from "../../assets/ods/ods_02.jpeg";
import ODS_3 from "../../assets/ods/ods_03.jpg";
import ODS_4 from "../../assets/ods/ods_04.jpg";
import ODS_5 from "../../assets/ods/ods_05.jpg";
import ODS_6 from "../../assets/ods/ods_06.jpg";
import ODS_7 from "../../assets/ods/ods_07.jpg";
import ODS_8 from "../../assets/ods/ods_08.jpg";
import ODS_9 from "../../assets/ods/ods_09.jpg";
import ODS_10 from "../../assets/ods/ods_10.jpg";
import ODS_11 from "../../assets/ods/ods_11.jpg";
import ODS_12 from "../../assets/ods/ods_12.jpg";
import ODS_13 from "../../assets/ods/ods_13.jpg";
import ODS_14 from "../../assets/ods/ods_14.jpg";
import ODS_15 from "../../assets/ods/ods_15.jpg";
import ODS_16 from "../../assets/ods/ods_16.jpg";
import ODS_17 from "../../assets/ods/ods_17.jpg";

export const BG_COLORS = [
  "#FDF3BB",
  "#DBEFFD",
  "#EAD3F4",
  "#F7CECD",
  "#EFF6D3",
  "#D0E4D7",
  "#FAE0F2",
];

export const ODsImages = {
  ODS_1,
  ODS_2,
  ODS_3,
  ODS_4,
  ODS_5,
  ODS_6,
  ODS_7,
  ODS_8,
  ODS_9,
  ODS_10,
  ODS_11,
  ODS_12,
  ODS_13,
  ODS_14,
  ODS_15,
  ODS_16,
  ODS_17,
};

export const ODS = [
  {
    id: 1,
    title: "ODS 1 - Erradicação da pobreza",
    image: ODS_1,
  },
  {
    id: 2,
    title: "ODS 2 - Fome zero e agricultura sustentável",
    image: ODS_2,
  },
  {
    id: 3,
    title: "ODS 3 - Saúde e bem-estar",
    image: ODS_3,
  },
  {
    id: 4,
    title: "ODS 4 - Educação de qualidade",
    image: ODS_4,
  },
  {
    id: 5,
    title: "ODS 5 - Igualdade de gênero",
    image: ODS_5,
  },
  {
    id: 6,
    title: "ODS 6 - Água potável e saneamento",
    image: ODS_6,
  },
  {
    id: 7,
    title: "ODS 7 - Energia limpa e acessível",
    image: ODS_7,
  },
  {
    id: 8,
    title: "ODS 8 - Trabalho decente e crescimento econômico",
    image: ODS_8,
  },
  {
    id: 9,
    title: "ODS 9 - Indústria, inovação e infraestrutura",
    image: ODS_9,
  },
  {
    id: 10,
    title: "ODS 10 - Redução das desigualdades",
    image: ODS_10,
  },
  {
    id: 11,
    title: "ODS 11 - Cidades e comunidades sustentáveis",
    image: ODS_11,
  },
  {
    id: 12,
    title: "ODS 12 - Consumo e produção responsáveis",
    image: ODS_12,
  },
  {
    id: 13,
    title: "ODS 13 - Ação contra a mudança global do clima",
    image: ODS_13,
  },
  {
    id: 14,
    title: "ODS 14 - Vida na água",
    image: ODS_14,
  },
  {
    id: 15,
    title: "ODS 15 - Vida terrestre",
    image: ODS_15,
  },
  {
    id: 16,
    title: "ODS 16 - Paz, justiça e instituições eficazes",
    image: ODS_16,
  },
  {
    id: 17,
    title: "ODS 17 - Parcerias e meios de implementação",
    image: ODS_17,
  },
];

export const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "90%",
    sm: "60%",
    md: "50%",
    lg: 400,
    xl: 400,
  },
  boxShadow: 24,
};

export const boxDeleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: 1,
  width: {
    xs: "90%",
    sm: "60%",
    md: "50%",
    lg: 400,
    xl: 400,
  },
  boxShadow: 24,
  bgcolor: "background.paper",
  p: 2,
  outline: "none",
};
