import { toast } from "react-toastify";

import { gql, useMutation } from "../contexts/ApiContext";

import LikeButton from "../components/LikeButton";

const LIKE_TOPIC = gql`
  mutation likeTopic($input: LikeTopicMutationInput!) {
    likeTopic(input: $input) {
      errors
      topic {
        id
        isLiked
        likesCount
      }
    }
  }
`;

export default function TopicLikeButtonContainer({ topic }) {
  const [likeTopic] = useMutation(LIKE_TOPIC, {
    variables: { input: { userTopicId: topic.id } },
  });

  const handleLikeTopic = async () => {
    const response = await likeTopic();
    const errors = response?.data?.likeTopic?.errors;

    if (errors) {
      toast.error("Não foi possível curtir o tópico");
    }
  };

  return (
    <LikeButton
      isLiked={topic.isLiked}
      onClick={handleLikeTopic}
      likesCount={topic.likesCount}
    />
  );
}
