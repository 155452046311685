import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useApolloClient } from "../constants/ApolloClient";
import { useApp } from "../contexts/AppContext";
import { PrivateRoutes } from "./private.routes";
import { PublicRoutes } from "./public.routes";

export default function MainRoutes() {
  const apiClient = useApolloClient();
  const { currentUserId } = useApp();

  return (
    <ApolloProvider client={apiClient}>
      <BrowserRouter>
        {currentUserId ? <PrivateRoutes /> : <PublicRoutes />}
      </BrowserRouter>
      <ToastContainer />
    </ApolloProvider>
  );
}
