export const firebaseConfig = {
  // apiKey: process.env.REACT_APP_FirebaseApiKey,
  // authDomain: process.env.REACT_APP_FirebaseAuthDomain,
  // projectId: process.env.REACT_APP_FirebaseProjectId,
  // storageBucket: process.env.REACT_APP_FirebaseStorageBucket,
  // messagingSenderId: process.env.REACT_APP_FirebaseMessagingSenderId,
  // appId: process.env.REACT_APP_FirebaseAppId,
  apiKey: "AIzaSyBrpmgROnNV4bmFXHr1bfv6I3rNaqQULVU",
  authDomain: "licita-canvas-dev.firebaseapp.com",
  projectId: "licita-canvas-dev",
  storageBucket: "licita-canvas-dev.appspot.com",
  messagingSenderId: "280881986685",
  appId: "1:280881986685:web:3f29a290cd8dffcf498514",
};
