import { gql, useMutation } from "../../contexts/ApiContext";
import { FeasibilityAnalysisFragment } from "../../fragments/feasibilityAnalysisFragment";

import { Flex } from "../../components/Flex";
import { ButtonConfetti } from "../../components/ButtonConfetti";
import DragAndDropPostIt from "../../components/DragAndDropPostIt";

export const feasibilityAnalysisCols = [
  {
    name: "Justificativa técnica",
    key: "technicalJustificationCards",
    kind: "technical_justification",
    tooltip:
      "Descreva as justificativas técnicas que respaldam a viabilidade da solução considerada. Isso envolve analisar se a solução atende aos requisitos técnicos, se é compatível com os sistemas existentes, se possui recursos necessários para o pleno funcionamento, se é escalável e se atende aos padrões e normas técnicas estabelecidas. Explique como a solução irá RESOLVER O PROBLEMA e satisfazer as necessidades da administração.",
  },
  {
    name: "Justificativa econômica",
    key: "economicJustificationCards",
    kind: "economic_justification",
    tooltip:
      "Apresente as justificativas econômicas que sustentam a viabilidade da solução. Isso inclui analisar os custos e benefícios financeiros, como o retorno sobre o investimento (ROI), a redução de custos operacionais, o aumento da eficiência ou outros aspectos econômicos relevantes. Avalie se a solução é economicamente vantajosa e se possui um plano de negócios sólido.",
  },
  {
    name: "Justificativa jurídica",
    key: "legalJustificationCards",
    kind: "legal_justification",
    tooltip:
      "Descreva as justificativas jurídicas que comprovam a viabilidade da solução dentro do contexto legal. Verifique se a solução está em conformidade com as leis, regulamentos, normas e diretrizes aplicáveis. Analise aspectos como a propriedade intelectual, a conformidade com as regras de licitação e contratação pública, a segurança jurídica e a conformidade com os princípios éticos e de responsabilidade.",
  },
];

const CREATE_CANVA_CARD = gql`
  mutation createCanvaCard($input: CreateCanvaCardMutationInput!) {
    createCanvaCard(input: $input) {
      errors
      success
      canvaCard {
        canva {
          id
          name
          feasibilityAnalysis {
            ...FeasibilityAnalysisFragment
          }
        }
      }
    }
  }
  ${FeasibilityAnalysisFragment}
`;

const UPDATE_CANVA_CARD = gql`
  mutation updateCard($input: UpdateCardMutationInput!) {
    updateCard(input: $input) {
      errors
      success
      canvaCard {
        canva {
          id
          name
          feasibilityAnalysis {
            ...FeasibilityAnalysisFragment
          }
        }
      }
    }
  }
  ${FeasibilityAnalysisFragment}
`;

const DELETE_CANVA_CARD = gql`
  mutation deleteCanvaCard($input: DeleteCardMutationInput!) {
    deleteCard(input: $input) {
      errors
      success
      canva {
        id
        name
        feasibilityAnalysis {
          ...FeasibilityAnalysisFragment
        }
      }
    }
  }
  ${FeasibilityAnalysisFragment}
`;

const StepForecastAnalysis = ({ setStep, canva, canEdit }) => {
  const [updateCanvaCard, { loading: loadingUpdate }] =
    useMutation(UPDATE_CANVA_CARD);
  const [createCanvaCard, { loading: loadingCreate }] =
    useMutation(CREATE_CANVA_CARD);
  const [deleteCanvaCard, { loading: loadingDelete }] =
    useMutation(DELETE_CANVA_CARD);

  const handleNextStep = () => {
    setStep(5);
  };

  return (
    <div>
      <DragAndDropPostIt
        canvaId={canva?.id}
        colsCards={canva?.feasibilityAnalysis}
        cols={feasibilityAnalysisCols}
        createCanvaCard={createCanvaCard}
        updateCanvaCard={updateCanvaCard}
        deleteCanvaCard={deleteCanvaCard}
        disabled={!canEdit}
        loading={loadingUpdate || loadingCreate || loadingDelete}
        containerStyle={"bg-red-canva grid grid-cols-1 lg:grid-cols-3"}
      />
      {canEdit && (
        <Flex justifyContent="end" className="mt-4">
          <ButtonConfetti title={"Salvar"} onClick={() => handleNextStep()} />
        </Flex>
      )}
    </div>
  );
};

export default StepForecastAnalysis;
