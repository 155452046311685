import { gql } from "../contexts/ApiContext";
import { CanvaCardFragment } from "./canvaCardFragment";

export const CenterPointFragment = gql`
  fragment CenterPointFieldsFragment on CenterPoint {
    forWhoCards {
      ...CanvaCardFragment
    }
    functionalCards {
      ...CanvaCardFragment
    }
    operationalCards {
      ...CanvaCardFragment
    }
    previsionCards {
      ...CanvaCardFragment
    }
    problemToBeSolvedCards {
      ...CanvaCardFragment
    }
    quantityEstimatesCards {
      ...CanvaCardFragment
    }
    technicalCards {
      ...CanvaCardFragment
    }
    whyCards {
      ...CanvaCardFragment
    }
  }
  ${CanvaCardFragment}
`;
