import { toast } from "react-toastify";

import { gql, useMutation } from "../contexts/ApiContext";

import LikeButton from "../components/LikeButton";

const LIKE_CANVA = gql`
  mutation likeCanva($input: LikeCanvaMutationInput!) {
    likeCanva(input: $input) {
      errors
      canva {
        id
        isLiked
        likesCount
      }
    }
  }
`;

export default function CanvaLikeButtonContainer({ canva, className }) {
  const [likeCanva] = useMutation(LIKE_CANVA, {
    variables: { input: { canvaUid: canva.uid } },
  });

  const handleLikeCanva = async () => {
    const response = await likeCanva();
    const errors = response?.data?.likeCanva?.errors;

    if (errors) {
      toast.error("Não foi possível curtir o canvas");
    }
  };

  return (
    <LikeButton
      isLiked={canva.isLiked}
      onClick={handleLikeCanva}
      likesCount={canva.likesCount}
      className={className}
    />
  );
}
