import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { Modal } from "@mui/material";
import cn from "classnames";

import Button from "../Button";
import { ODsImages } from "../../screens/Canva/contants";

export function ModalSustainableGoals({
  open,
  onClose,
  onConfirm,
  options,
  loading,
  initialValues,
}) {
  const [selectedOdds, setSelectedOdds] = useState(initialValues || []);

  const handleSelectOdd = (data) => {
    const filteredOdds = selectedOdds?.filter((Ods) => Ods?.id !== data?.id);

    if (filteredOdds?.length >= selectedOdds?.length) {
      setSelectedOdds((old) => [...old, data]);
      return;
    }

    setSelectedOdds(filteredOdds);
  };

  useEffect(() => {
    if (initialValues) {
      setSelectedOdds(initialValues);
    }
  }, [initialValues]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal flex items-center justify-center"
    >
      <div className="m-2 w-full max-w-lg rounded-md bg-white px-4 py-6">
        <p className="text-shark font-semibold">
          Selecione os ODSs alcançados pelo projeto
        </p>
        <div>
          <div className="mt-6 grid grid-cols-5 gap-2">
            {options?.map((option) => {
              const isSelected = selectedOdds?.find(
                (selectedOdd) => selectedOdd?.id === option?.id
              );
              return (
                <div
                  className="cursor-pointer"
                  onClick={() => handleSelectOdd(option)}
                >
                  <Tooltip id={option?.id} className="z-50 max-w-xs" />
                  <img
                    data-tooltip-id={option?.id}
                    data-tooltip-content={option?.title}
                    data-tooltip-float="true"
                    className={cn("h-full w-full", {
                      "opacity-20": isSelected,
                    })}
                    src={ODsImages?.[option?.fileName]}
                    alt={option?.title}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button type="button" variant="outline" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={() => onConfirm(selectedOdds)}
            loading={loading}
          >
            Confirmar
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalSustainableGoals;
