import ConfettiExplosion from "react-confetti-explosion";
import { useEffect, useState } from "react";

import Button from "./Button";

export function ButtonConfetti({ title, onClick = () => {}, loading = false }) {
  const [isExploding, setIsExploding] = useState(false);

  const handleConfetti = () => {
    setIsExploding(true);
  };

  useEffect(() => {
    let timer;

    if (isExploding) {
      timer = setTimeout(() => {
        setIsExploding(false);
        onClick();
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isExploding]);

  return (
    <div>
      <div>
        {isExploding && (
          <ConfettiExplosion
            colors={[
              "#fff2b3",
              "#ffdecb",
              "#d6f0ff",
              "#dbe2ff",
              "#efd2f7",
              "#eef6d0",
              "#ffcbcb",
              "#cbe5d6",
              "#ffdff3",
              "#561DE2",
              "#ABEFE3",
            ]}
          />
        )}
      </div>
      <Button
        type="submit"
        variant="secondary"
        loading={loading}
        onClick={() => {
          handleConfetti();
        }}
      >
        {title}
      </Button>
    </div>
  );
}
