import { ApolloClient, from, fromPromise, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { relayStylePagination } from "@apollo/client/utilities";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

import { Server } from "./Server";
import { refreshToken } from "./Auth";
import { getAccessToken } from "./Storage";
import { useApp } from "../contexts/AppContext";

export const useApolloClient = () => {
  const { currentUserId, updateToken, signOut } = useApp();

  const NewAPIClient = () => {
    const path = currentUserId ? "/user" : "";

    const cache = new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            publicCanvas: relayStylePagination(),
            topics: relayStylePagination(),
            myTopics: relayStylePagination(),
            myNotifications: relayStylePagination(),
          },
        },
        Canva: {
          fields: {
            comments: relayStylePagination(),
          },
        },
        Topic: {
          fields: {
            comments: relayStylePagination(),
          },
        },
      },
    });

    const errorLink = onError(({ networkError, operation, forward }) => {
      if (networkError?.statusCode === 401) {
        return fromPromise(
          refreshToken()
            .then((newToken) => {
              updateToken(newToken);
              return newToken;
            })
            .catch(async () => {
              return await signOut();
            })
        )
          .filter((value) => Boolean(value))
          .flatMap(() => forward(operation));
      }
    });

    const authLink = setContext(async () => {
      const token = await getAccessToken();

      return {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
      };
    });

    const upLoadLink = createUploadLink({
      uri: `${Server.url}${path}/graphql.json`,
    });

    return new ApolloClient({
      link: from([errorLink, authLink, upLoadLink]),
      cache,
      defaultOptions: {
        query: {
          fetchPolicy: "cache-and-network",
        },
      },
      connectToDevTools: true,
    });
  };

  return NewAPIClient();
};
