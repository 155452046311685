import { twMerge } from "tailwind-merge";

export default function UserAvatar({ user, className }) {
  const avatar = user?.avatar?.url;

  return (
    <div
      className={twMerge(
        "flex shrink-0 items-center justify-center border border-white bg-gray-100 w-12 h-12 rounded-full",
        className
      )}
    >
      {avatar ? (
        <img
          alt=""
          src={avatar}
          className={"w-full h-full rounded-full object-cover"}
        />
      ) : (
        <p className="uppercase leading-none font-bold">
          {user.name?.slice(0, 2)}
        </p>
      )}
    </div>
  );
}
