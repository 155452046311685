import { useState } from "react";
import { Trash } from "@styled-icons/fa-solid";

import { useApp } from "../../contexts/AppContext";

import Button from "../../components/Button";
import UserAvatar from "../../components/UserAvatar";
import { ModalDelete } from "../../components/ModalDelete";
import { RichTextInput } from "../../components/RichTextInput";
import LikeButton from "../LikeButton";

export default function CommentsSection({
  comments = [],
  title,
  onCreate,
  onDelete,
  loadingDelete,
  loadingCreate,
  onLikeComment,
}) {
  const { currentUserId } = useApp();
  const [newComment, setNewComment] = useState("");
  const [commentToDeleteId, setCommentToDeleteId] = useState(null);

  const handleSendComment = () => {
    onCreate(newComment);

    setNewComment("");
  };

  const handleDeleteComment = async () => {
    await onDelete(commentToDeleteId);

    setCommentToDeleteId(null);
  };

  const handleLikeComment = async (id) => {
    await onLikeComment(id);
  };

  return (
    <div className="mt-4 mx-auto w-full lg:max-w-[50%] space-y-2">
      <label
        htmlFor="comment"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {title || "Comente este canvas"}
      </label>
      <RichTextInput
        disableEditing={true}
        value={newComment}
        onChange={setNewComment}
      />
      <div className="flex justify-end">
        <Button
          onClick={handleSendComment}
          variant="secondary"
          disabled={!newComment.trim()}
          loading={loadingCreate}
        >
          Enviar
        </Button>
      </div>

      <ul id="comments" className="divide-y divide-gray-100">
        {comments.map((comment) => (
          <CanvaCommentItem
            key={comment.node.id}
            comment={comment.node}
            onDelete={setCommentToDeleteId}
            canDelete={comment.node.user.id == currentUserId}
            handleLikeComment={handleLikeComment}
          />
        ))}
      </ul>

      <ModalDelete
        open={commentToDeleteId !== null}
        title="Deseja excluir o comentário?"
        onClose={() => setCommentToDeleteId(null)}
        loading={loadingDelete}
        onConfirm={handleDeleteComment}
      />
    </div>
  );
}

const CanvaCommentItem = ({
  comment,
  onDelete,
  canDelete,
  handleLikeComment,
}) => {
  const dateFormatted = new Date(comment.createdAt).toLocaleString();

  return (
    <li id={comment?.id} className="flex gap-x-4 py-5">
      <UserAvatar user={comment.user} />
      <div className="flex-1">
        <p className="text-lg font-semibold leading-6 text-gray-900">
          {comment.user.name}
        </p>
        <p className="text-xs text-gray-500">
          <time dateTime={comment.createdAt}>{dateFormatted}</time>
        </p>
        <div
          className="text-justify mt-3 leading-6 text-gray-600"
          dangerouslySetInnerHTML={{ __html: comment.content }}
        />
      </div>
      <div className="flex items-start justify-center">
        <LikeButton
          isLiked={comment?.isLiked}
          onClick={() => handleLikeComment(comment.id)}
          likesCount={comment?.likesCount}
        />
        {canDelete && (
          <div className="h-fit w-fit">
            <Trash
              className="h-4 w-4 text-red-500 cursor-pointer"
              onClick={() => onDelete(comment.id)}
            />
          </div>
        )}
      </div>
    </li>
  );
};
