import { useState } from "react";
import * as React from "react";
import { toast } from "react-toastify";
import { styled } from "styled-components";
import { useMutation } from "@apollo/client";

import { gql, useQuery } from "../../contexts/ApiContext";

import ModalShareCanva from "../../components/ModalShareCanva";
import { ModalInviteContainer } from "../../containers/ModalInviteContainer";
import { ModalCreateCanvasContainer } from "../../containers/ModalCreateCanvasContainer";
import Button from "../../components/Button";
import Sidebar from "../../components/Sidebar";
import { ModalDelete } from "../../components/ModalDelete";
import OutletTabs from "../../components/OutletTabs";

const Title = styled.h1`
  color: #212429;
  font-size: 28px;
  font-weight: 600;
  margin: 10px 0 0 0;
`;

export const GET_CANVAS = gql`
  query getCanvas {
    myCanvas {
      nodes {
        id
        name
        uid
        isFavorite
        user {
          id
          name
        }
      }
    }
    sharedCanvas {
      nodes {
        id
        name
        uid
        isFavorite
        user {
          id
          name
        }
      }
    }
    favoriteCanvas {
      nodes {
        id
        name
        uid
        isFavorite
        user {
          id
          name
        }
      }
    }
  }
`;

const DELETE_CANVAS = gql`
  mutation createCanva($input: DeleteCanvaMutationInput!) {
    deleteCanva(input: $input) {
      success
      errors
    }
  }
`;

const tabs = [
  { name: "Meus Canvas", path: "/canvas/my-canvas" },
  {
    name: "Canvas Compartilhados",
    path: "/canvas/shared-canvas",
  },
  {
    name: "Canvas Favoritados",
    path: "/canvas/favorite-canvas",
  },
];

const Canvas = () => {
  const [canvasNameShare, setCanvasNameShare] = useState("");
  const [openShare, setOpenShare] = useState(false);
  const [generatedLink, setGeneratedLink] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [canvaId, setCanvaId] = useState(null);

  const {
    data,
    refetch,
    loading: loadingCanvas,
  } = useQuery(GET_CANVAS, {
    fetchPolicy: "no-cache",
  });

  const [deleteCanvasMutation, { loading: loadingDeleteCanvas }] =
    useMutation(DELETE_CANVAS);

  const myCanvas = data?.myCanvas?.nodes || [];
  const sharedCanvas = data?.sharedCanvas?.nodes || [];
  const favoriteCanvas = data?.favoriteCanvas?.nodes || [];

  const showOpenDialog = (event, canvaId) => {
    event.stopPropagation();
    event.preventDefault();
    setCanvaId(canvaId);
    setDeleteOpen(true);
  };

  const showOpenShareDialog = (event, canvaId, name) => {
    event.stopPropagation();
    event.preventDefault();

    setCanvasNameShare(name);
    setOpenShare(true);
    setGeneratedLink(`${window.location.origin}/invite/${canvaId}`);
  };

  const confirmDeleteCanvas = async () => {
    if (canvaId) {
      const result = await deleteCanvasMutation({
        variables: { input: { id: canvaId } },
      });

      const errors = result?.data?.deleteCanva?.errors;

      if (errors) {
        toast.error(
          "Não foi possivel deletar o canva, tente novamente mais tarde!"
        );
        return;
      }
      refetch();
      toast.success("Canva deletado com sucesso!");
      setCanvaId(null);
      setDeleteOpen(false);
    }
  };

  return (
    <Sidebar onCreateCanvas={refetch}>
      <div className="flex-1 p-6">
        <div className="flex items-center justify-between">
          <Title>Licita Canvas</Title>
          <ModalCreateCanvasContainer
            CreateButton={(props) => (
              <Button variant="secondary" {...props}>
                Criar Licita Canvas
              </Button>
            )}
          />
        </div>
        <div className="mt-4">
          <OutletTabs
            tabs={tabs}
            context={{
              myCanvas,
              sharedCanvas,
              favoriteCanvas,
              loadingCanvas,
              showOpenDialog,
              showOpenShareDialog,
            }}
          />
        </div>
      </div>
      <ModalDelete
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onConfirm={confirmDeleteCanvas}
        loading={loadingDeleteCanvas}
        title="Deseja excluir o Licita Canvas?"
      />
      <ModalShareCanva
        open={openShare}
        onClose={() => setOpenShare(false)}
        generatedLink={generatedLink}
        canvaName={canvasNameShare}
      />
      <ModalInviteContainer />
    </Sidebar>
  );
};

export default Canvas;
