import { toast } from "react-toastify";
import { Star } from "@styled-icons/fa-solid";
import cn from "classnames";

import { gql, useMutation } from "../contexts/ApiContext";
import { GET_CANVAS } from "../screens/Canvas";

const FAVORITE_CANVA = gql`
  mutation favoriteCanva($input: FavoriteCanvaMutationInput!) {
    favoriteCanva(input: $input) {
      favoriteCanvas {
        nodes {
          id
          name
          uid
          isFavorite
          user {
            id
            name
          }
        }
      }
      errors
      canva {
        id
        isFavorite
      }
    }
  }
`;

export function FavoriteIconContainer({ isFavorite, uid }) {
  const [favoriteCanva] = useMutation(FAVORITE_CANVA);

  const handleFavorite = async () => {
    const response = await favoriteCanva({
      variables: { input: { canvaUid: uid } },
      refetchQueries: [GET_CANVAS],
    });

    const errors = response?.data?.favoriteCanva?.errors;

    if (errors) {
      toast.error("Não foi possível favoritar o Licita Canvas.");
      return;
    }

    isFavorite
      ? toast.success("Licita Canvas desfavoritado com sucesso!")
      : toast.success("Licita Canvas favoritado com sucesso!");
  };

  return (
    <Star
      isFavorite={isFavorite}
      className={cn("w-5 h-5 text-[#636e7c] cursor-pointer", {
        "text-[#FFC107]": isFavorite,
      })}
      onClick={handleFavorite}
    />
  );
}
