import { useEffect, useRef } from "react";
import { toast } from "react-toastify";

import { gql, useMutation, useQuery } from "../contexts/ApiContext";
import useScrollObserver from "../hooks/useScrollObserver";

import CommentsSection from "../components/CommentsSection";
import Loader from "../components/Loader";
import { useLocation } from "react-router-dom";

const GET_TOPIC_COMMENTS = gql`
  query getTopic($id: ID!, $after: String) {
    topic(id: $id) {
      id
      user {
        id
      }
      comments(after: $after, first: 5) {
        edges {
          node {
            id
            user {
              id
              name
              avatar {
                id
                url
              }
            }
            isLiked
            likesCount
            content
            createdAt
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

const CREATE_TOPIC_COMMENT = gql`
  mutation createTopicComment($input: CreateTopicCommentMutationInput!) {
    createTopicComment(input: $input) {
      success
      errors
    }
  }
`;

const DELETE_TOPIC_COMMENT = gql`
  mutation deleteTopicComment($input: DeleteTopicCommentMutationInput!) {
    deleteTopicComment(input: $input) {
      success
      errors
    }
  }
`;

const LIKE_TOPIC_COMMENT = gql`
  mutation likeComment($input: LikeCommentMutationInput!) {
    likeComment(input: $input) {
      success
      errors
    }
  }
`;

export default function TopicCommentsContainer({ id }) {
  const observerRef = useRef(null);

  const location = useLocation();
  const lastHash = useRef("");

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1);
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
        lastHash.current = "";
      }, 500);
    }
  }, [location]);

  const { data, loading, refetch, fetchMore } = useQuery(GET_TOPIC_COMMENTS, {
    variables: {
      id,
    },
  });
  const [createCommentMutation, { loading: loadingCreateComment }] =
    useMutation(CREATE_TOPIC_COMMENT);
  const [deleteCommentMutation, { loading: loadingDeleteComment }] =
    useMutation(DELETE_TOPIC_COMMENT);
  const [LikeCommentMutation] = useMutation(LIKE_TOPIC_COMMENT);

  const topic = data?.topic;
  const topicComments = topic?.comments?.edges || [];
  const pageInfo = topic?.comments?.pageInfo;

  const fetchMoreData = () => {
    !!pageInfo?.hasNextPage &&
      fetchMore({
        variables: { after: pageInfo.endCursor },
      });
  };

  const handleCreateComment = async (comment) => {
    const response = await createCommentMutation({
      variables: { input: { content: comment, topicId: id } },
    });
    const errors = response?.data?.createTopicComment?.errors;

    if (errors) {
      toast.error("Não foi possível adicionar o comentário");
      return;
    }

    toast.success("Comentário adicionado com sucesso!");
    refetch();
  };

  const handleDeleteComment = async (id) => {
    const response = await deleteCommentMutation({
      variables: { input: { id } },
    });
    const errors = response?.data?.deleteTopicComment?.errors;

    if (errors) {
      toast.error("Não foi possível deletar o comentário");
      return;
    }

    toast.success("Comentário deletado com sucesso!");
    refetch();
  };

  const handleLikeComment = async (id) => {
    const response = await LikeCommentMutation({
      variables: {
        input: {
          likeableType: "UserTopicComment",
          likeableId: id,
        },
      },
    });

    const errors = response?.data?.deleteTopicComment?.errors;

    if (errors) {
      return;
    }

    refetch();
  };

  useScrollObserver(observerRef, fetchMoreData);

  return (
    <>
      <CommentsSection
        title="Comente este tópico"
        comments={topicComments}
        onCreate={handleCreateComment}
        onDelete={handleDeleteComment}
        onLikeComment={handleLikeComment}
        loadingCreate={loadingCreateComment}
        loadingDelete={loadingDeleteComment}
      />

      <div ref={observerRef} />

      {loading && <Loader />}
    </>
  );
}
