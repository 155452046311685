import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { Trash, UserPlus, Edit, Lock, Unlock } from "@styled-icons/fa-solid";
import styled from "styled-components";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { gql, useMutation, useQuery } from "../../contexts/ApiContext";
import { useApp } from "../../contexts/AppContext";

import image1 from "../../assets/step_01.png";
import image2 from "../../assets/step_02.png";
import image3 from "../../assets/step_03.png";
import image4 from "../../assets/step_04.png";
import image5 from "../../assets/step_05.png";
import image6 from "../../assets/step_06.png";
import image7 from "../../assets/step_07.png";
import image8 from "../../assets/step_08.png";
import Collapse from "../../components/Collapse";
import StepForecastAnalysis from "./StepForecastAnalysis";
import StepMarketResearch from "./StepMarketResearch";
import StepProblems from "./StepProblems";
import StepResponsibility from "./StepResponsibility";
import StepRiskAnalysis from "./StepRiskAnalysis";
import StepSolution from "./StepSolution";
import StepTeam from "./StepTeam";
import { TeamFragment } from "../../fragments/teamFragment";
import { CenterPointFragment } from "../../fragments/centerPointFragment";
import { MarketingResearchesFragment } from "../../fragments/marketingResearchesFragment";
import { FeasibilityAnalysisFragment } from "../../fragments/feasibilityAnalysisFragment";
import { SolutionFragment } from "../../fragments/solutionFragment";
import { ModalEditCanvaName } from "../../components/ModalEditCanvaName";
import { ModalDelete } from "../../components/ModalDelete";
import { RiskAnalysisFragment } from "../../fragments/riskAnalysisFragment";
import { EnvironmentalResponsibilityFragment } from "../../fragments/environmentalResponsibilityFragment";
import ModalShareCanva from "../../components/ModalShareCanva";
import { FavoriteIconContainer } from "../../containers/FavoriteIconContainer";
import { ModalExportContainer } from "../../containers/ModalExportContainer";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { ConclusionFragment } from "../../fragments/conclusionFragment";
import StepConclusion from "./StepConclusion";
import CanvaCommentsContainer from "../../containers/CanvaCommentsContainer";
import { AvatarList } from "../../components/AvatarList";
import Sidebar from "../../components/Sidebar";
import CanvaLikeButtonContainer from "../../containers/CanvaLikeButtonContainer";
import ViewsCount from "../../components/ViewsCount";

const EditIcon = styled(Edit)`
  cursor: pointer;
  color: #636e7c;
  width: 16px;
  height: 16px;
  margin-top: -5px;
`;

const UserPlusIcon = styled(UserPlus)`
  cursor: pointer;
  color: #636e7c;
  width: 20px;
  height: 20px;
`;

const TrashIcon = styled(Trash)`
  cursor: pointer;
  color: #e34263;
  width: 20px;
  height: 20px;
`;

const Wrapper = styled.div`
  padding: 34px 45px;

  @media screen and (max-width: 768px) {
    padding: 34px 20px;
  }

  @media screen and (min-width: 1441px) {
    width: 90%;
    margin: 0 auto;
  }
  @media screen and (min-width: 1740px) {
    width: 85%;
    margin: 0 auto;
  }
  @media screen and (min-width: 1900px) {
    width: 85%;
    margin: 0 auto;
  }
`;

const GET_CANVA = gql`
  query getCanva($uid: String!) {
    canva(uid: $uid) {
      id
      uid
      name
      isFavorite
      isPublic
      isLiked
      viewsCount
      likesCount
      updatedAt
      user {
        id
        name
        avatar {
          id
          url
        }
      }
      users {
        id
        name
        avatar {
          id
          url
        }
      }
      conclusivePositioning
      ...TeamFragment
      centerPoints {
        ...CenterPointFieldsFragment
      }
      marketingResearches {
        ...MarketingResearchesFragment
      }
      feasibilityAnalysis {
        ...FeasibilityAnalysisFragment
      }
      solution {
        ...SolutionFragment
      }
      riskAnalysis {
        ...RiskAnalysisFragment
      }
      environmentalResponsibility {
        ...EnvironmentalResponsibilityFragment
      }
      conclusion {
        ...ConclusionFragment
      }
      sustainableGoals {
        id
        title
        fileName
      }
    }
  }
  ${TeamFragment}
  ${CenterPointFragment}
  ${MarketingResearchesFragment}
  ${FeasibilityAnalysisFragment}
  ${SolutionFragment}
  ${RiskAnalysisFragment}
  ${EnvironmentalResponsibilityFragment}
  ${ConclusionFragment}
`;

const UPDATE_CANVA = gql`
  mutation updateTeam($input: UpdateCanvaMutationInput!) {
    updateCanva(input: $input) {
      errors
      canva {
        id
        name
        isPublic
      }
    }
  }
`;

const DELETE_CANVAS = gql`
  mutation createCanva($input: DeleteCanvaMutationInput!) {
    deleteCanva(input: $input) {
      success
      errors
    }
  }
`;

const Canva = () => {
  const { currentUserId } = useApp();
  const navigate = useNavigate();
  let { uid } = useParams();
  let id = "";

  const location = useLocation();
  const hash = location?.hash?.split("#")[1];
  const isFromCommunity = location?.pathname.includes("community");

  const [step, setStep] = useState(Number(hash));
  const [editName, setEditName] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const { data, loading: loadingCanva } = useQuery(GET_CANVA, {
    variables: {
      uid: `${uid}`,
    },
    pollInterval: 5000,
  });

  const [updateCanva, { loading: loadingUpdateCanva }] =
    useMutation(UPDATE_CANVA);

  const [deleteCanvasMutation, { loading: loadingDeleteCanvas }] =
    useMutation(DELETE_CANVAS);

  const canva = data?.canva;
  const sharedUser = canva?.users?.find((user) => user?.id == currentUserId);
  const canEdit = canva?.user?.id == currentUserId || sharedUser;
  const users = [canva?.user, ...(canva?.users || [])];

  const breadcrumbCustomRoutes = [
    { path: "/canvas/my-canvas/:uid", breadcrumb: canva?.name },
    { path: "/canvas/shared-canvas/:uid", breadcrumb: canva?.name },
    { path: "/community/canvas/:uid", breadcrumb: canva?.name },
  ];

  const showOpenDialog = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setDeleteOpen(true);
  };

  const showOpenShareDialog = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setOpenShare(true);
  };

  const confirmDeleteCanvas = async () => {
    const result = await deleteCanvasMutation({
      variables: { input: { id: canva?.id } },
    });

    const errors = result?.data?.deleteCanva?.errors;

    if (errors) {
      toast.error(
        "Não foi possivel delete o canva, tente novamente mais tarde!"
      );
      return;
    }

    toast.success("Canva deletado com sucesso!");
    navigate(-1);
    setDeleteOpen(false);
  };

  const handleEditName = () => {
    setEditName(true);
  };

  const handleUpdateName = async (values) => {
    const response = await updateCanva({
      variables: { input: { ...values, id: canva?.id } },
    });
    const errors = response?.data?.updateCanva?.errors;

    if (errors) {
      toast.error("Não foi possível alterar o canva");
      return;
    }

    toast.success("Licita Canvas alterado com sucesso!");

    setEditName(false);
  };

  const handleUpdateVisibility = async () => {
    const response = await updateCanva({
      variables: { input: { id: canva?.id, isPublic: !canva?.isPublic } },
    });
    const errors = response?.data?.updateCanva?.errors;

    if (errors) {
      toast.error("Não foi possível alterar a visibilidade do Licita Canvas.");
      return;
    }

    toast.success("Visibilidade do Licita Canvas alterada com sucesso!");
  };

  useEffect(() => {
    const element = document.getElementById(hash);

    if (element) {
      element?.scrollIntoView();
    }
  }, [loadingCanva]);

  return (
    <Sidebar onlyResponsiveMenu>
      <Wrapper>
        {loadingCanva && (
          <Box
            sx={{
              position: "absolute",
              right: "50%",
              left: "50%",
              top: "50%",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!loadingCanva && (
          <>
            <div>
              <Breadcrumbs customRoutes={breadcrumbCustomRoutes} />
              <div className="flex flex-wrap justify-between mb-4 pb-3 border-b">
                <div className="flex flex-col justify-between">
                  <div className="flex items-center gap-4 mt-2">
                    <button
                      className="flex items-center gap-1 w-fit border border-primary rounded-3xl px-2 py-1"
                      onClick={handleUpdateVisibility}
                      disabled={!canEdit}
                    >
                      {canva?.isPublic ? (
                        <Unlock className="h-4 w-4 text-primary" />
                      ) : (
                        <Lock className="h-4 w-4 text-primary" />
                      )}
                      <p className="text-sm">
                        {canva?.isPublic ? "Público" : "Privado"}
                      </p>
                    </button>
                    <AvatarList users={users} />
                  </div>
                  <h1 className="text-xl font-semibold text-primary mt-2">
                    {canva?.name}{" "}
                    {canEdit && <EditIcon onClick={handleEditName} />}
                  </h1>
                </div>
                <div className="flex flex-col items-end ml-auto">
                  <div className="flex gap-1 mt-4 mb-5 md:mt-0">
                    <ViewsCount
                      viewsCount={canva.viewsCount}
                      className="justify-end"
                    />
                    <CanvaLikeButtonContainer
                      canva={canva}
                      className="justify-end"
                    />
                  </div>
                  <div className="space-x-2">
                    {canEdit && (
                      <>
                        <UserPlusIcon
                          onClick={(event) => showOpenShareDialog(event, id)}
                        />
                        <FavoriteIconContainer
                          isFavorite={canva?.isFavorite}
                          uid={uid}
                        />
                      </>
                    )}
                    <ModalExportContainer uid={uid} />
                    {canEdit && (
                      <TrashIcon
                        onClick={(event) => showOpenDialog(event, id)}
                      />
                    )}
                  </div>
                  <span className="text-xs text-gray-500 mt-2">
                    Atualizado em {new Date(canva?.updatedAt).toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <Collapse
                id="1"
                title="Pessoas"
                bgColor="#FDF3BB"
                index={1}
                image={image1}
                isOpen={isFromCommunity || step === 1}
                onClick={() => navigate("#1")}
              >
                <StepTeam setStep={setStep} canva={canva} canEdit={canEdit} />
              </Collapse>
              <Collapse
                id="2"
                title="Pontos Centrais"
                bgColor="#DBEFFD"
                index={2}
                image={image2}
                isOpen={isFromCommunity || step === 2}
                onClick={() => navigate("#2")}
              >
                <StepProblems
                  setStep={setStep}
                  canva={canva}
                  canEdit={canEdit}
                />
              </Collapse>
              <Collapse
                id="3"
                title="Pesquisa Mercadológica"
                bgColor="#DBEFFD"
                index={3}
                isOpen={isFromCommunity || step === 3}
                image={image3}
                onClick={() => navigate("#3")}
              >
                <StepMarketResearch
                  setStep={setStep}
                  canva={canva}
                  canEdit={canEdit}
                />
              </Collapse>
              <Collapse
                id="4"
                title="Análise de Viabilidade"
                bgColor="#DBEFFD"
                index={4}
                isOpen={isFromCommunity || step === 4}
                image={image4}
                onClick={() => navigate("#4")}
              >
                <StepForecastAnalysis
                  setStep={setStep}
                  canva={canva}
                  canEdit={canEdit}
                />
              </Collapse>
              <Collapse
                id="5"
                title="Solução"
                bgColor="#DBEFFD"
                index={5}
                isOpen={isFromCommunity || step === 5}
                image={image5}
                onClick={() => navigate("#5")}
              >
                <StepSolution
                  setStep={setStep}
                  canva={canva}
                  canEdit={canEdit}
                />
              </Collapse>
              <Collapse
                id="6"
                title="Responsabilidade ambiental"
                bgColor="#DBEFFD"
                index={6}
                isOpen={isFromCommunity || step === 6}
                image={image6}
                onClick={() => navigate("#6")}
              >
                <StepResponsibility
                  setStep={setStep}
                  canva={canva}
                  canEdit={canEdit}
                />
              </Collapse>
              <Collapse
                id="7"
                title="Análise de riscos"
                bgColor="#DBEFFD"
                index={7}
                isOpen={isFromCommunity || step === 7}
                image={image7}
                onClick={() => navigate("#7")}
              >
                <StepRiskAnalysis
                  setStep={setStep}
                  canva={canva}
                  canEdit={canEdit}
                />
              </Collapse>
              <Collapse
                id="8"
                title="Conclusão"
                bgColor="#ABEFE3"
                index={8}
                isOpen={isFromCommunity || step === 8}
                image={image8}
                onClick={() => navigate("#8")}
              >
                <StepConclusion
                  setStep={setStep}
                  canva={canva}
                  canEdit={canEdit}
                />
              </Collapse>
              <CanvaCommentsContainer uid={uid} />
            </div>
          </>
        )}
      </Wrapper>
      <ModalDelete
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onConfirm={confirmDeleteCanvas}
        loading={loadingDeleteCanvas}
        title="Deseja excluir o Licita Canvas?"
      />
      <ModalShareCanva
        open={openShare}
        onClose={() => setOpenShare(false)}
        canvaName={canva?.name}
        generatedLink={`${window.location.origin}/invite/${uid}`}
      />
      <ModalEditCanvaName
        open={editName}
        onClose={() => {
          setEditName(false);
        }}
        onSubmit={handleUpdateName}
        loading={loadingUpdateCanva}
        initialValues={{ name: canva?.name }}
      />
    </Sidebar>
  );
};

export default Canva;
