import { useState, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import {
  Handshake,
  Heart,
  PlusCircle,
  Star,
  X,
  User,
  Bell,
  QuestionCircle as Question,
} from "@styled-icons/fa-solid";

import { PrinterFill } from "@styled-icons/bootstrap";

import licitaCanvaPDF from "../../assets/licita_canvas.pdf";

import { Policy, Web } from "@styled-icons/material";
import { ChatBubble, Menu } from "@styled-icons/material";
import { LogoutRounded } from "@mui/icons-material";
import cn from "classnames";

import { useNotification } from "../../contexts/NotificationContext";
import { useApp } from "../../contexts/AppContext";

import { ModalCreateCanvasContainer } from "../../containers/ModalCreateCanvasContainer";
import { Navbar } from "../Navbar";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Sidebar({
  onCreateCanvas,
  headerStyle,
  onlyResponsiveMenu = false,
  children,
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { signOut } = useApp();
  const { unreadMessages } = useNotification();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const onCreate = () => {
    onCreateCanvas ? onCreateCanvas() : navigate("/canvas/my-canvas");
  };

  const logout = async () => {
    await signOut();
  };

  const navigation = [
    { name: "Novo Licita Canvas", icon: PlusCircle, current: false },
    {
      name: "Licita Canvas",
      href: "/canvas/my-canvas",
      icon: Heart,
      current:
        pathname == "/canvas/my-canvas" || pathname == "/canvas/shared-canvas",
    },
    {
      name: "Favoritos",
      href: "/canvas/favorite-canvas",
      icon: Star,
      current: pathname == "/canvas/favorite-canvas",
    },
    {
      name: "Fórum de Discussão",
      href: "/topics/my-topics",
      icon: ChatBubble,
      current:
        pathname == "/topics/my-topics" || pathname == "/topics/all-topics",
    },
    {
      name: "Comunidade",
      href: "/community",
      icon: Handshake,
      current: pathname == "/community",
    },
    {
      name: "Dúvidas Frequentes",
      href: "/frequently-asked-questions",
      icon: Question,
      current: pathname == "/frequently-asked-questions",
    },
    {
      name: "Notificações",
      href: "/notifications",
      icon: Bell,
      number: unreadMessages,
      current: pathname == "/notifications",
    },
    {
      name: "Licita Canvas Impresso",
      href: "/licita-canvas-impresso",
      icon: PrinterFill,
    },
  ];

  return (
    <>
      <Navbar className={twMerge("fixed", headerStyle)} />
      <div className="flex flex-1">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <X className="h-4 w-4 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white py-8 px-5 pb-2">
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item, index) => (
                          <li key={item.name}>
                            {index == 0 ? (
                              <ModalCreateCanvasContainer
                                onCreate={onCreateCanvas}
                                CreateButton={(props) => (
                                  <MenuItem item={item} {...props} />
                                )}
                              />
                            ) : (
                              <MenuItem item={item} />
                            )}
                          </li>
                        ))}
                        <li>
                          <MenuItem
                            item={{
                              name: "Perfil",
                              href: "/profile",
                              icon: User,
                              current: false,
                            }}
                          />
                        </li>
                        <li>
                          <MenuItem
                            item={{
                              name: "Termos de uso",
                              href: "/privacy-policy",
                              icon: Policy,
                              current: false,
                            }}
                          />
                        </li>
                        <li>
                          <MenuItem
                            item={{
                              name: "Sair",
                              icon: LogoutRounded,
                              current: false,
                            }}
                            onClick={logout}
                          />
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <div
          className={cn(
            "fixed mt-20 pb-24 w-56 py-8 hidden lg:inset-y-0 lg:z-50 ",
            {
              "lg:flex lg:flex-col": !onlyResponsiveMenu,
            }
          )}
        >
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-5">
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="-mx-2 space-y-1">
                {navigation.map((item, index) => (
                  <li key={item.name}>
                    {index == 0 ? (
                      <ModalCreateCanvasContainer
                        onCreate={onCreate}
                        CreateButton={(props) => (
                          <MenuItem item={item} {...props} />
                        )}
                      />
                    ) : (
                      <MenuItem item={item} />
                    )}
                  </li>
                ))}
                <li>
                  <MenuItem
                    item={{
                      name: "Soluções Governamentais",
                      href: "https://www.solucoesgovernamentais.com.br/",
                      icon: Web,
                      current: false,
                    }}
                    target="_blank"
                  />
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="fixed right-6 z-20 items-center flex lg:hidden">
          <button
            type="button"
            className="p-1 h-20 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Menu className="h-6 w-6 text-primary" aria-hidden="true" />
          </button>
        </div>
        <div
          className={cn("flex-1 mt-20", {
            "lg:ml-56": !onlyResponsiveMenu,
          })}
        >
          {children}
        </div>
      </div>
    </>
  );
}

const MenuItem = ({ item, ...rest }) => {
  const number = item.number > 99 ? "99+" : item.number;

  return (
    <a
      href={item.href}
      className={classNames(
        item.current
          ? "bg-gray-50 text-indigo-600"
          : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
        "group cursor-pointer flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold items-center"
      )}
      download={item?.download}
      target={item?.target}
      {...rest}
    >
      <item.icon
        className="text-purple-heart h-6 w-6 shrink-0"
        aria-hidden="true"
      />
      {item.name}
      {!!item.number && (
        <span className="ml-auto inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-heart text-white">
          {number}
        </span>
      )}
    </a>
  );
};
