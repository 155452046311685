import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { SearchOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import "./style.css";

import { gql, useQuery } from "../../contexts/ApiContext";
import { useApp } from "../../contexts/AppContext";
import Logout from "../auth/Logout";
import logo from "../../assets/logo.png";
import { useForm } from "react-hook-form";

const Menu = styled.ul`
  width: 150px;
  display: none;
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #ccc;
  top: 50px;
  right: 0;

  li {
    list-style: none;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #212429;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-bottom: 1px solid #ccc;

    &:hover {
      background-color: #f1f3f5;
      color: #636e7c;
    }
  }
`;

const WrapperMenu = styled.div`
  position: relative;

  &:hover {
    ${Menu} {
      display: block;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const GET_ME = gql`
  query getMe {
    me {
      id
      name
      avatar {
        id
        url
      }
    }
  }
`;

export const Navbar = ({ className }) => {
  const { data } = useQuery(GET_ME);
  const { currentUserId } = useApp();
  const navigate = useNavigate();

  const user = data?.me;
  const isLogged = !!currentUserId;

  return (
    <div className={twMerge("navbar h-20 z-10", className)}>
      <div className="navbar-menu">
        <a className="block" href="/">
          <img src={logo} alt="logo" className="w-48 ml-3 lg:m-0" />
        </a>
      </div>

      {!isLogged && (
        <a
          href="/login"
          className="bg-lavender-canva px-12 py-3 rounded-full font-bold"
        >
          Entrar
        </a>
      )}
      {isLogged && (
        <>
          <SearchInput />
          <WrapperMenu>
            <UserAvatar user={user} />
            <Menu>
              <li onClick={() => navigate("/profile")}>
                <Link to="/profile">Perfil</Link>
              </li>
              <li onClick={() => navigate("/privacy-policy")}>
                <Link to="/privacy-policy">Termos de uso</Link>
              </li>
              <li>
                <Logout />
              </li>
            </Menu>
          </WrapperMenu>
        </>
      )}
    </div>
  );
};

Navbar.propTypes = {
  foto: PropTypes.string,
};

const UserAvatar = ({ user }) => {
  const avatar = user?.avatar?.url;
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className="flex cursor-pointer items-center justify-center ring-4 ring-lavender-canva bg-gray-100 w-14 h-14 rounded-full">
      {avatar ? (
        <img
          alt="imagem de perfil"
          src={avatar}
          className={twMerge(
            "w-full h-full rounded-full object-cover transition-opacity duration-500",
            imageLoaded ? "opacity-100" : "opacity-0"
          )}
          onLoad={handleImageLoad}
        />
      ) : (
        <p className="uppercase leading-none font-bold">
          {user && user.name.slice(0, 2)}
        </p>
      )}
    </div>
  );
};

const SearchInput = () => {
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const search = searchParams.get("search") || "";

  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      search: searchParams.get("search"),
    },
  });

  const onSubmit = (values) => {
    const search = values?.search;
    navigate(
      search.trim()
        ? `/community?search=${encodeURIComponent(search)}`
        : "/community"
    );
  };

  useEffect(() => {
    reset({ search });
  }, [search]);

  return (
    <div className="hidden flex-1 lg:pl-[8%] lg:flex">
      <form
        className="flex flex-1 max-w-[440px] items-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          type="text"
          name="search"
          placeholder="Pesquise Licita Canvas por palavras-chave"
          className="flex-1 w-[124px] py-2 pl-12 pr-2 rounded-full bg-lavender-canva focus-visible:outline-none focus-visible:ring-1 ring-purple-heart"
          {...register("search")}
        />
        <button
          type="submit"
          className="flex items-center justify-center absolute bg-purple-heart h-7 w-7 rounded-full ml-2 text-lavender-canva cursor-pointer"
        >
          <SearchOutlined className="h-6 w-6" />
        </button>
      </form>
    </div>
  );
};
