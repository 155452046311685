import { gql } from "../contexts/ApiContext";
import { CanvaCardFragment } from "./canvaCardFragment";

export const ConclusionFragment = gql`
  fragment ConclusionFragment on Conclusion {
    additionalInformationCards {
      ...CanvaCardFragment
    }
  }
  ${CanvaCardFragment}
`;
