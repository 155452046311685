import { gql } from "../contexts/ApiContext";
import { CanvaCardFragment } from "./canvaCardFragment";

export const FeasibilityAnalysisFragment = gql`
  fragment FeasibilityAnalysisFragment on FeasibilityAnalysis {
    economicJustificationCards {
      ...CanvaCardFragment
    }
    legalJustificationCards {
      ...CanvaCardFragment
    }
    technicalJustificationCards {
      ...CanvaCardFragment
    }
  }
  ${CanvaCardFragment}
`;
