import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import { Trash, Edit } from "@styled-icons/fa-solid";

const WrapperCard = styled.div`
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: ${(props) => (props.disabled ? "default" : "grab")};
`;

const ContentCard = styled.p`
  font-size: 14px;
  color: #212429;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const HeaderCard = styled.div`
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid rgba(33, 36, 41, 0.25);
  gap: 10px;
  padding-bottom: 5px;
`;

export default function SortablePostIt({
  item,
  onEditPostIt,
  onDeletePostIt,
  disabled,
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.id, data: item });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <PostIt
        id={item.id}
        item={item}
        onEditPostIt={onEditPostIt}
        onDeletePostIt={onDeletePostIt}
        disabled={disabled}
      />
    </div>
  );
}

export const PostIt = ({ item, onEditPostIt, onDeletePostIt, disabled }) => (
  <WrapperCard bgColor={item.bgColor} disabled={disabled}>
    {!disabled && (
      <HeaderCard>
        <Edit
          size="16"
          color="#7E8A98"
          onClick={() => onEditPostIt(item)}
          className="cursor-pointer"
        />
        <Trash
          size="16"
          color="#7E8A98"
          onClick={() => onDeletePostIt(item)}
          className="cursor-pointer"
        />
      </HeaderCard>
    )}
    <ContentCard>{item.content}</ContentCard>
  </WrapperCard>
);
