import { AngleLeft } from "@styled-icons/fa-solid";
import { NavLink } from "react-router-dom";

import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";

const breadcrumbRoutes = [
  { path: "/canvas/my-canvas", breadcrumb: "Meus Canvas" },
  { path: "/canvas/shared-canvas", breadcrumb: "Canvas Compartilhados" },
  { path: "/community", breadcrumb: "Comunidade" },
  { path: "/", breadcrumb: "Início" },
];

export function Breadcrumbs({ customRoutes }) {
  const routes = [...breadcrumbRoutes, ...customRoutes];
  const breadcrumbs = useReactRouterBreadcrumbs(routes, {
    excludePaths: ["/community/canvas"],
  });

  return (
    <div className="mb-4">
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <NavLink
          key={match.pathname}
          to={match.pathname}
          className="underline text-shark-200 hover:text-purple-heart transition-colors duration-200"
        >
          {breadcrumb}
          {breadcrumbs.length - 1 !== index && (
            <AngleLeft className="h-4 w-4 mx-1" />
          )}
        </NavLink>
      ))}
    </div>
  );
}
