import { Link, Outlet, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export default function OutletTabs({ tabs = [], context = [] }) {
  const { pathname } = useLocation();

  return (
    <>
      <div className="border-b border-gray-200">
        <div className="-mb-px flex space-x-2 sm:space-x-8">
          {tabs.map((tab) => (
            <Link
              key={tab.name}
              to={tab.path}
              className={twMerge(
                "border-b-2 uppercase px-1 pb-2 text-sm font-medium",
                tab.path == pathname && "border-purple-heart text-purple-heart",
                tab.path != pathname &&
                  "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
              )}
            >
              {tab.name}
            </Link>
          ))}
        </div>
      </div>
      <div className="mt-4 space-y-3">
        <Outlet context={context} />
      </div>
    </>
  );
}
