import React from "react";
import { saveAs } from "file-saver";
import {
  AlignmentType,
  Document,
  ExternalHyperlink,
  Header,
  HeadingLevel,
  ImageRun,
  LevelFormat,
  NumberFormat,
  Packer,
  PageNumber,
  Paragraph,
  ShadingType,
  TextRun,
  VerticalPositionRelativeFrom,
} from "docx";

import { centerPointsCols } from "../../screens/Canva/StepProblems";
import { marketingResearchesCols } from "../../screens/Canva/StepMarketResearch";
import { feasibilityAnalysisCols } from "../../screens/Canva/StepForecastAnalysis";
import { solutionsCols } from "../../screens/Canva/StepSolution";
import { ODsImages } from "../../screens/Canva/contants";
import { environmentalResponsibilityCols } from "../../screens/Canva/StepResponsibility";
import { riskAnalysisCols } from "../../screens/Canva/StepRiskAnalysis";
import { conclusionCols } from "../../screens/Canva/StepConclusion";

import image1 from "../../assets/step_01.png";
import image2 from "../../assets/step_02.png";
import image3 from "../../assets/step_03.png";
import image4 from "../../assets/step_04.png";
import image5 from "../../assets/step_05.png";
import image6 from "../../assets/step_06.png";
import image7 from "../../assets/step_07.png";
import image8 from "../../assets/step_08.png";
import Button from "../Button";

const DEFAULT_CONCLUSIVE_POSITIONING =
  "Após todo o estudo realizado neste projeto, concluiu-se que a solução indicada no tópico 5 atende às necessidades da Administração Pública previstas no tópico 2, conforme análises de viabilidade contidas no tópico 4 e demais justificativas contidas no presente documento. \nO Licita Canvas elaborado pela Equipe de Planejamento é parte integrante deste ETP, bem como todos os demais documentos que o dão suporte, como pesquisas mercadológicas, memórias de cálculo, estimativas, previsões orçamentárias e demais documentos anexos.";

export default function ExportCanvaDocButton({ canva, onClick }) {
  const canvaTitle = `Licita Canvas - ${canva?.name}`;
  const canvaTeam = {
    projectOrigin: canva?.projectOrigin || "",
    projectManager: canva?.projectManager || "",
    technicalManager: canva?.technicalManager || "",
    estimator: canva?.estimator || "",
    editor: canva?.editor || "",
    legal: canva?.legal || "",
    assistants: canva?.assistants || "",
    expenseOrganizer: canva?.expenseOrganizer || "",
    approvers: canva?.approvers || "",
    experts: canva?.experts || "",
    market: canva?.market || "",
    influencers: canva?.influencers || "",
    financial: canva?.financial || "",
  };
  const centerPoints = canva?.centerPoints;
  const marketingResearches = canva?.marketingResearches;
  const feasibilityAnalysis = canva?.feasibilityAnalysis;
  const solutions = canva?.solution;
  const environmentalResponsibility = canva?.environmentalResponsibility;
  const sustainableGoals = canva?.sustainableGoals;
  const riskAnalysis = canva?.riskAnalysis;
  const conclusion = canva?.conclusion;

  async function exportDoc() {
    const [
      image1Blob,
      image2Blob,
      image3Blob,
      image4Blob,
      image5Blob,
      image6Blob,
      image7Blob,
      image8Blob,
    ] = await Promise.all([
      fetch(image1).then((r) => r.blob()),
      fetch(image2).then((r) => r.blob()),
      fetch(image3).then((r) => r.blob()),
      fetch(image4).then((r) => r.blob()),
      fetch(image5).then((r) => r.blob()),
      fetch(image6).then((r) => r.blob()),
      fetch(image7).then((r) => r.blob()),
      fetch(image8).then((r) => r.blob()),
    ]);
    const sustainableGoalsBlob = await Promise.all(
      sustainableGoals.map((sustainableGoal) =>
        fetch(ODsImages[sustainableGoal.fileName]).then((r) => r.blob())
      )
    );

    const doc = new Document({
      styles: {
        paragraphStyles: [
          {
            id: "Normal",
            name: "Normal",
            quickFormat: true,
            run: {
              size: 22,
              font: "Arial",
            },
            paragraph: {
              spacing: {
                before: 200,
              },
              alignment: AlignmentType.JUSTIFIED,
            },
          },
          {
            id: "title",
            name: "Title",
            quickFormat: true,
            paragraph: {
              alignment: AlignmentType.CENTER,
              heading: HeadingLevel.TITLE,
              spacing: {
                after: 200,
              },
            },
            run: {
              font: "Arial",
              size: 14,
            },
          },
        ],
      },
      numbering: {
        config: [
          {
            reference: "numbering",
            levels: [
              {
                level: 0,
                text: "%1.",
                format: LevelFormat.DECIMAL,
                alignment: AlignmentType.LEFT,
                style: {
                  run: {
                    size: 24,
                    font: "Arial",
                    bold: true,
                  },
                  paragraph: {
                    indent: { left: 720, hanging: 120 },
                  },
                },
              },
            ],
          },
        ],
      },
      sections: [
        {
          properties: {
            page: {
              pageNumbers: {
                start: 1,
                formatType: NumberFormat.DECIMAL,
              },
            },
          },
          headers: {
            default: generateHeader(),
          },
          children: [
            generateProjectTitle("ESTUDO TÉCNICO PRELIMINAR"),
            generateProjectTitle(canvaTitle),
            generateCreatedWith("https://licita-canvas.vercel.app"),
            generateEmptyLine(),
            ...generateTitle("Pessoas", image1Blob, "fff2b3"),
            generateItem("Origem: ", canvaTeam.projectOrigin),
            generateItemTitle("Equipe de Planejamento"),
            generateItem("Gerente do Projeto: ", canvaTeam.projectManager),
            generateItem("Responsável Técnico: ", canvaTeam.technicalManager),
            generateItem("Orçamentista: ", canvaTeam.estimator),
            generateItem("Redator: ", canvaTeam.editor),
            generateItem("Jurídico: ", canvaTeam.legal),
            generateItem("Auxiliares: ", canvaTeam.assistants),
            generateItemTitle("Stakeholders"),
            generateItem(
              "Organizador de Despesas: ",
              canvaTeam.expenseOrganizer
            ),
            generateItem("Aprovadores: ", canvaTeam.approvers),
            generateItem("Especialistas: ", canvaTeam.experts),
            generateItem("Mercado: ", canvaTeam.market),
            generateItem("Influenciadores: ", canvaTeam.influencers),
            generateItem("Financeiro: ", canvaTeam.financial),
            ...generateTitle("Pontos Centrais", image2Blob, "d6f0ff"),
            ...centerPointsCols
              .slice(0, 4)
              .flatMap((col) =>
                generateListItems(col.name, centerPoints?.[col.key])
              ),
            generateItemTitle("Requisitos da aquisição/contratação"),
            ...centerPointsCols
              .slice(4, 8)
              .flatMap((col) =>
                generateListItems(col.name, centerPoints?.[col.key])
              ),
            ...generateTitle("Pesquisa Mercadológica", image3Blob, "efd2f7"),
            ...marketingResearches.flatMap((marketingResearch, index) =>
              marketingResearchesCols.flatMap((col) =>
                generateListItems(
                  `${col.name} ${index + 1}`,
                  marketingResearch?.[col.key]
                )
              )
            ),
            ...generateTitle("Análise de Viabilidade", image4Blob, "ffcbcb"),
            ...feasibilityAnalysisCols.flatMap((col) =>
              generateListItems(col.name, feasibilityAnalysis?.[col.key])
            ),
            ...generateTitle("Solução", image5Blob, "eef6d0"),
            ...solutionsCols.flatMap((col) =>
              generateListItems(col.name, solutions?.[col.key])
            ),
            ...generateTitle(
              "Responsabilidade Ambiental",
              image6Blob,
              "cbe5d6"
            ),
            generateItem("ODS alcançados pelo projeto"),
            new Paragraph({
              style: "Normal",
              children: sustainableGoalsBlob.flatMap((blob) => [
                new ImageRun({
                  data: blob,
                  transformation: {
                    width: 60,
                    height: 60,
                  },
                }),
              ]),
            }),
            ...environmentalResponsibilityCols.flatMap((col) =>
              generateListItems(
                col.name,
                environmentalResponsibility?.[col.key]
              )
            ),
            ...generateTitle("Análise de Riscos", image7Blob, "ffdff3"),
            ...riskAnalysisCols.flatMap((col) =>
              generateListItems(col.name, riskAnalysis?.[col.key])
            ),
            ...generateTitle("Conclusão", image8Blob, "ABEFE3"),
            ...conclusionCols.flatMap((col) =>
              generateListItems(col.name, conclusion?.[col.key])
            ),
            generateItem(
              "Posicionamento Conclusivo: ",
              canva.conclusivePositioning || DEFAULT_CONCLUSIVE_POSITIONING
            ),
            generateDate(),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `licita-canvas-${canva.name}.docx`);
    });
  }

  return (
    <Button
      type="submit"
      variant="secondary"
      onClick={() => onClick(exportDoc)}
    >
      DOCX
    </Button>
  );
}

function generateHeader() {
  return new Header({
    children: [
      new Paragraph({
        alignment: AlignmentType.RIGHT,
        children: [
          new TextRun({
            children: ["Página ", PageNumber.CURRENT],
          }),
          new TextRun({
            children: [" de ", PageNumber.TOTAL_PAGES],
          }),
        ],
      }),
    ],
  });
}

function generateProjectTitle(title) {
  return new Paragraph({
    style: "title",
    children: [
      new TextRun({
        text: title,
        bold: true,
        allCaps: true,
        size: 28,
      }),
    ],
  });
}

function generateCreatedWith(url) {
  return new Paragraph({
    style: "title",
    children: [
      new TextRun({
        text: "Elaborado com o apoio da ferramenta ",
      }),
      new ExternalHyperlink({
        children: [
          new TextRun({
            text: "Licita Canvas ",
            style: "Hyperlink",
          }),
        ],
        link: url,
      }),
    ],
  });
}

function generateItem(title, content) {
  return new Paragraph({
    style: "Normal",
    children: [
      new TextRun({ text: title, bold: true }),
      new TextRun({ text: content }),
    ],
  });
}

function generateItemTitle(title) {
  return new Paragraph({
    style: "Normal",
    children: [
      new TextRun({
        text: title,
        bold: true,
        size: 24,
        break: 1,
      }),
    ],
  });
}

function generateListItems(title, items) {
  const list = [
    new Paragraph({
      style: "Normal",
      children: [
        new TextRun({
          text: title,
          bold: true,
        }),
      ],
    }),
  ];

  items.forEach((item) => {
    list.push(
      new Paragraph({
        style: "Normal",
        bullet: {
          level: 0,
        },
        children: [
          new TextRun({
            text: item.content,
          }),
        ],
      })
    );
  });

  return list;
}

function generateTitle(title, image, color) {
  return [
    generateEmptyLine(),
    new Paragraph({
      numbering: {
        reference: "numbering",
        level: 0,
      },
      shading: {
        type: ShadingType.SOLID,
        color,
        fill: color,
      },
      spacing: {
        before: 0,
        after: 0,
      },
      children: [
        new ImageRun({
          data: image,
          transformation: {
            width: 40,
            height: 40,
          },
          floating: {
            horizontalPosition: {
              offset: 914400,
            },
            verticalPosition: {
              relative: VerticalPositionRelativeFrom.LINE,
              offset: -100000,
            },
          },
        }),
        new TextRun({
          text: title,
          bold: true,
          size: 24,
        }),
      ],
    }),
    generateEmptyLine(),
  ];
}

function generateEmptyLine() {
  return new Paragraph({
    style: "Normal",
    children: [
      new TextRun({
        text: "",
      }),
    ],
  });
}

function generateDate() {
  return new Paragraph({
    style: "Normal",
    children: [
      new TextRun({
        text: "Local, ",
        bold: true,
        break: 2,
      }),
      new TextRun({
        bold: true,
        text: new Date().toLocaleDateString("pt-BR", {
          day: "numeric",
          month: "long",
          year: "numeric",
        }),
      }),
    ],
  });
}
