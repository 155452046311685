import { ThumbUp } from "@mui/icons-material";
import cn from "classnames";
import { twMerge } from "tailwind-merge";

import { countNumberMask } from "../../utils/countNumberMask";

export default function LikeButton({
  isLiked,
  onClick,
  likesCount = 0,
  className,
}) {
  return (
    <div
      className={twMerge("flex items-center min-w-[44px] gap-1.5", className)}
    >
      <button onClick={onClick}>
        <ThumbUp
          className={cn("h-5 w-5 mb-0.5", {
            "text-purple-heart hover:opacity-80": isLiked,
            "text-gray-400 hover:text-gray-500": !isLiked,
          })}
        />
      </button>
      <span className="text-sm">{countNumberMask(likesCount)}</span>
    </div>
  );
}
