import { useState } from "react";
import * as React from "react";
import { Box, Modal } from "@mui/material";
import { toast } from "react-toastify";
import styled from "styled-components";

import { gql, useMutation } from "../contexts/ApiContext";

import Input from "../components/Input";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";

const TitleDialog = styled.h1`
  color: #212429;
  font-size: 28px;
  font-weight: 600;
  margin: 10px 0 0 0;
`;

const boxCreateCanvaStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: 1,
  width: {
    xs: "90%", // theme.breakpoints.up('xs')
    sm: "90%", // theme.breakpoints.up('sm')
    md: 400, // theme.breakpoints.up('md')
    lg: 400, // theme.breakpoints.up('lg')
    xl: 500, // theme.breakpoints.up('xl')
  },
  boxShadow: 24,
  bgcolor: "background.paper",
  p: 2,
  outline: "none",
};

const CREATE_CANVAS = gql`
  mutation createCanva($input: CreateCanvaMutationInput!) {
    createCanva(input: $input) {
      success
      errors
      canva {
        id
        uid
      }
    }
  }
`;

export function ModalCreateCanvasContainer({ onCreate, CreateButton }) {
  const [openCreateCanvas, setOpenCreateCanvas] = useState(false);
  const [canvasName, setCanvasName] = useState("");
  const navigate = useNavigate();

  const [createCanvasMutation, { loading: loadingCreateCanvas }] =
    useMutation(CREATE_CANVAS);

  const createCanvas = async (event) => {
    event.preventDefault();
    if (!canvasName) {
      toast.error("Nome do canvas é obrigatório");
    } else {
      const result = await createCanvasMutation({
        variables: {
          input: {
            name: canvasName,
          },
        },
      });

      const errors = result?.data?.createCanva?.errors;
      const canvaUid = result?.data?.createCanva?.canva?.uid;

      if (errors) {
        toast.error("Não foi possivel criar o canvas, tente mais tarde!");
        return;
      }

      navigate(`/canvas/my-canvas/${canvaUid}`);
      setOpenCreateCanvas(false);
      toast.success("Canva criado com sucesso!");
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setCanvasName(value);
  };

  return (
    <>
      {CreateButton && (
        <CreateButton onClick={() => setOpenCreateCanvas(true)} />
      )}
      <Modal
        className="modal"
        open={openCreateCanvas}
        onClose={() => setOpenCreateCanvas(false)}
        aria-labelledby="modal-modal-title-canva"
        aria-describedby="modal-modal-description-canva"
      >
        <Box sx={boxCreateCanvaStyle}>
          <TitleDialog>Digite o nome do canvas</TitleDialog>
          <form className="mt-2">
            <div>
              <Input
                name="name"
                placeholder="Ex.: Meu Licita Canvas"
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex justify-end gap-2">
              <Button
                type="submit"
                variant="outline"
                onClick={() => setOpenCreateCanvas(false)}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="secondary"
                onClick={createCanvas}
                loading={loadingCreateCanvas}
              >
                Criar
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
}
