import { Modal } from "@mui/material";
import { toast } from "react-toastify";

import { Whatsapp } from "@styled-icons/fa-brands";
import { MailOutline } from "@styled-icons/material";
import Button from "../Button";

function ModalShareCanva({ open, onClose, generatedLink, canvaName }) {
  return (
    <Modal
      className="modal flex items-center justify-center"
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title-share"
      aria-describedby="modal-modal-description-share"
    >
      <div className="bg-white max-w-sm w-full px-4 py-6 m-2 rounded-md">
        <p className="text-shark font-semibold text-2xl">
          Deseja compartilhar o Licita Canvas?
        </p>
        <p className="text-shark text-sm font-normal mb-5 mt-2">
          Lembre-se que o outro usuário deverá conter uma conta também
        </p>
        <div className="flex justify-end gap-2 my-5">
          <a
            href={generatedLink}
            target="_blank"
            rel="noreferrer"
            className="italic underline break-all"
          >
            {generatedLink}
          </a>
        </div>
        <div className="flex justify-start gap-4">
          <div>Compartilhar via:</div>
          <div className="flex gap-4 mb-2">
            <a
              href={`https://api.whatsapp.com/send?text=${generatedLink}%20Colabore comigo no Licita Canvas: ${canvaName}`}
              title="Compartilhar via WhatsApp"
              target="_blank"
              rel="noreferrer"
            >
              <span>
                <Whatsapp className="w-4 h-4" /> WhatsApp
              </span>
            </a>
            <a
              href={`mailto:?subject=[LICITA CANVAS]&body=Colabore%20comigo%20no%20Licita%20Canvas%20${canvaName}%20-%20${generatedLink}`}
              title="Compartilhar via E-mail"
              target="_blank"
              rel="noreferrer"
            >
              <span>
                <MailOutline className="w-4 h-4" /> E-mail
              </span>
            </a>
          </div>
        </div>
        <div className="flex justify-end gap-2">
          <Button type="submit" variant="outline" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="secondary"
            onClick={() => {
              navigator.clipboard.writeText(
                `Colabore comigo no Licita Canvas: ${canvaName} \n${generatedLink}`
              );
              toast.success("Link copiado com sucesso!");
            }}
          >
            Copiar link
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalShareCanva;
