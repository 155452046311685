import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { gql, useQuery } from "../../contexts/ApiContext";
import useScrollObserver from "../../hooks/useScrollObserver";

import Loader from "../../components/Loader";
import UserAvatar from "../../components/UserAvatar";
import ListItemContainer from "../../components/ListItemContainer";
import { TopicsFilter } from "../../components/TopicsFilter";
import TopicLikeButtonContainer from "../../containers/TopicLikeButtonContainer";
import ViewsCount from "../../components/ViewsCount";

const GET_TOPICS = gql`
  query getTopics($after: String, $where: UserTopicsSearchTerms) {
    topics(first: 10, after: $after, where: $where) {
      edges {
        node {
          id
          title
          subtitle
          isLiked
          likesCount
          viewsCount
          isFixed
          user {
            id
            name
            avatar {
              id
              url
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export default function MyTopics() {
  const navigate = useNavigate();
  const observerRef = useRef(null);

  const [filters, setFilters] = useState({});

  const { data, fetchMore, loading } = useQuery(GET_TOPICS, {
    variables: { where: { ...filters } },
  });

  const topics = data?.topics?.edges || [];
  const pageInfo = data?.topics?.pageInfo;

  const fetchMoreData = () => {
    !!pageInfo?.hasNextPage &&
      fetchMore({
        variables: { after: pageInfo.endCursor },
      });
  };

  useScrollObserver(observerRef, fetchMoreData);

  function onFilter(values) {
    setFilters(values);
  }

  return (
    <div className="space-y-4 pt-3">
      <TopicsFilter onFilter={onFilter} loading={loading} />
      {topics.map((topic) => (
        <TopicItem
          key={topic.node.id}
          topic={topic.node}
          onClick={() => navigate(`/topics/${topic.node.id}`)}
        />
      ))}

      <div ref={observerRef} />

      {loading && (
        <div className="mt-12">
          <Loader />
        </div>
      )}
    </div>
  );
}

const TopicItem = ({ topic, onClick }) => {
  return (
    <ListItemContainer
      onClick={onClick}
      title={topic.title}
      subtitle={topic.subtitle}
      isFixed={topic.isFixed}
    >
      <div className="flex flex-col gap-1 md:gap-2 py-2 md:flex-row">
        <UserAvatar user={topic.user} className="h-10 w-10" />
        <ViewsCount viewsCount={topic.viewsCount} />
        <TopicLikeButtonContainer topic={topic} />
      </div>
    </ListItemContainer>
  );
};
