import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useSearchParams } from "react-router-dom";
import queryString from "query-string";

import { TopicCategoriesSelectContainer } from "../../containers/TopicCategoriesSelectContainer";

import Button from "../Button";
import Input from "../Input";
import { useEffect } from "react";

const formSchema = z.object({
  searchTopic: z.string().optional(),
  topicCategoryIds: z.string().array().optional(),
});

export function TopicsFilter({ onFilter, loading }) {
  let [searchParams, setSearchParams] = useSearchParams();

  const parsedSearchTopicParam = searchParams.get("searchTopic");
  const parsedTopicCategoryIdsParam = searchParams.getAll("topicCategoryIds");

  const { handleSubmit, register, setValue, watch } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      searchTopic: parsedSearchTopicParam,
      topicCategoryIds: parsedTopicCategoryIdsParam,
    },
  });

  const topicCategoryIds = watch("topicCategoryIds");

  function handleSearchParams(values) {
    setSearchParams(values);
    const params = {
      search: values?.searchTopic,
      topicCategoryIds: values?.topicCategoryIds || [],
    };
    onFilter(params);
  }

  useEffect(() => {
    onFilter({
      search: parsedSearchTopicParam,
      topicCategoryIds: parsedTopicCategoryIdsParam,
    });
  }, []);

  return (
    <div>
      <h3 className="mb-3 text-xl font-semibold">Filtros:</h3>
      <form onSubmit={handleSubmit(handleSearchParams)}>
        <div className="flex gap-4 items-center">
          <div className="flex-1">
            <Input
              placeholder="Buscar por título ou subtítulo"
              {...register("searchTopic")}
            />
          </div>
          <div className="flex-1">
            <TopicCategoriesSelectContainer
              value={topicCategoryIds}
              onChange={(values) => setValue("topicCategoryIds", values)}
            />
          </div>
          <Button variant="secondary" className="mb-4" loading={loading}>
            Buscar
          </Button>
        </div>
      </form>
    </div>
  );
}
