const stagingEnv = {
  url: process.env.REACT_APP_STAGING_URL,
  clientId: process.env.REACT_APP_STAGING_CLIENT_ID,
  clientSecret: process.env.REACT_APP_STAGING_CLIENT_SECRET,
};

const productionEnv = {
  url: process.env.REACT_APP_PRODUCTION_URL,
  clientId: process.env.REACT_APP_PRODUCTION_CLIENT_ID,
  clientSecret: process.env.REACT_APP_PRODUCTION_CLIENT_SECRET,
};

export const Server =
  process.env.NODE_ENV === "development" ? stagingEnv : productionEnv;
