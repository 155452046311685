import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Edit } from "@styled-icons/fa-solid";
import { PushPin } from "@mui/icons-material";

import { gql, useMutation, useQuery } from "../../contexts/ApiContext";
import { useApp } from "../../contexts/AppContext";

import Sidebar from "../../components/Sidebar";
import UserAvatar from "../../components/UserAvatar";
import TopicCommentsContainer from "../../containers/TopicCommentsContainer";
import ModalDeleteTopicContainer from "../../containers/ModalDeleteTopicContainer";
import TopicForm from "../../components/TopicForm";
import TopicLikeButtonContainer from "../../containers/TopicLikeButtonContainer";
import ViewsCount from "../../components/ViewsCount";
import GoBackButton from "../../components/GoBackButton";

const GET_TOPIC = gql`
  query getTopic($id: ID!) {
    topic(id: $id) {
      id
      title
      subtitle
      description
      createdAt
      isLiked
      likesCount
      viewsCount
      isFixed
      topicCategories {
        id
        name
        color
      }
      user {
        id
        name
        avatar {
          id
          url
        }
      }
    }
  }
`;

const UPDATE_TOPIC = gql`
  mutation updateTopic($input: UpdateTopicMutationInput!) {
    updateTopic(input: $input) {
      errors
      topic {
        id
        title
        subtitle
        description
        topicCategories {
          id
          name
          color
        }
      }
    }
  }
`;

export default function Topic() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { currentUserId } = useApp();

  const { data, loading } = useQuery(GET_TOPIC, {
    variables: {
      id,
    },
  });

  const topicCategories = data?.topic?.topicCategories;

  const [updateTopicMutation, { loading: loadingUpdateTopic }] =
    useMutation(UPDATE_TOPIC);

  if (!data?.topic && !loading) {
    return <p className="text-center mt-8">Tópico não encontrado</p>;
  }

  const topic = data?.topic;
  const isOwner = topic?.user.id == currentUserId;

  const goBack = () => navigate(-1);

  const onSubmitUpdateTopic = async (data) => {
    const result = await updateTopicMutation({
      variables: {
        input: { id, ...data },
      },
    });

    const errors = result?.data?.updateTopic?.errors;

    if (errors) {
      toast.error("Não foi possível editar o tópico, tente mais tarde!");
      return;
    }

    toast.success("Tópico editado com sucesso!");
  };

  return (
    <Sidebar>
      {data && (
        <div className="flex-col flex-1 p-6 pt-10">
          <div className="flex justify-between gap-2">
            <GoBackButton />
            {isOwner && (
              <div className="space-x-3">
                <TopicForm
                  title="Editar Tópico"
                  OpenFormButton={(props) => (
                    <Edit
                      className="h-5 w-5 text-shark-200 cursor-pointer hover:text-shark-500"
                      {...props}
                    />
                  )}
                  initialValues={{
                    title: topic.title,
                    subtitle: topic.subtitle,
                    description: topic.description,
                    topicCategoryIds: topic?.topicCategories?.map(
                      (category) => category?.id
                    ),
                  }}
                  onSubmit={onSubmitUpdateTopic}
                  loadingSubmit={loadingUpdateTopic}
                />

                <ModalDeleteTopicContainer id={id} onDelete={goBack} />
              </div>
            )}
          </div>
          <h1 className="text-3xl font-semibold mt-4 text-shark-500">
            {topic.title}
            {topic.isFixed && (
              <PushPin className=" ml-3 rotate-45 text-gray-500" />
            )}
          </h1>
          <h4 className="text-lg mt-2">{topic.subtitle}</h4>
          <div className="flex gap-3 py-1">
            {topicCategories?.map((category) => (
              <div className="flex items-center gap-1" key={category.id}>
                <div
                  className="h-4 w-4 rounded-md"
                  style={{ backgroundColor: category?.color }}
                />
                <p>{category?.name}</p>
              </div>
            ))}
          </div>
          <div className="flex justify-between">
            <p className="text-xs text-gray-500 mt-1">
              <time dateTime={topic.createdAt}>
                {new Date(topic.createdAt).toLocaleString()}
              </time>
            </p>
            <span className="flex flex-col md:flex-row gap-1 md:gap-2.5">
              <ViewsCount viewsCount={topic.viewsCount} />
              <TopicLikeButtonContainer topic={topic} />
            </span>
          </div>

          <div className="border-b border-gray-200 my-4" />
          <div className="flex items-center gap-2">
            <UserAvatar user={topic.user} />
            <p className="text-lg font-semibold text-gray-900">
              {topic.user.name}
            </p>
          </div>
          <div
            className="lg:ml-14 mb-8"
            dangerouslySetInnerHTML={{ __html: topic.description }}
          />

          <TopicCommentsContainer id={id} />
        </div>
      )}
    </Sidebar>
  );
}
