import { gql } from "../contexts/ApiContext";
import { CanvaCardFragment } from "./canvaCardFragment";

export const EnvironmentalResponsibilityFragment = gql`
  fragment EnvironmentalResponsibilityFragment on EnviromentResponsability {
    environmentalImpactCards {
      ...CanvaCardFragment
    }
    mitigatingMeasuresCards {
      ...CanvaCardFragment
    }
  }
  ${CanvaCardFragment}
`;
