import { Link } from "react-router-dom";
import CallToActionWpp from "../../assets/call_to_action_wpp.png";

export function Footer() {
  return (
    <div className="bg-lavender-canva text-sm z-50 px-12 py-3">
      <div className="flex flex-col justify-between items-center lg:flex-row">
        <p>
          <a
            className="underline"
            href="https://www.solucoesgovernamentais.com.br/privacidade"
            target="_blank"
          >
            © Soluções Governamentais
          </a>
          {" "} - 2024 - Todos os direitos reservados.
          <br />
          {/* <a className="block underline" href="#">
            Política de privacidade
          </a> */}
          <a
            className="underline"
            href="https://www.solucoesgovernamentais.com.br/termos-de-uso"
            target="_blank"
          >
            Termos de Uso
          </a>
        </p>
        <a href="https://api.whatsapp.com/send?phone=5511993757015&text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20servi%C3%A7os%20oferecidos%20para%20compras%20e%20projetos%20governamentais.">
          <img
            className="w-40 h-auto"
            alt="Whatsapp icon"
            src={CallToActionWpp}
          />
        </a>
      </div>
    </div>
  );
}
