import { Navigate, Route, Routes } from "react-router-dom";

import { NotificationProvider } from "../contexts/NotificationContext";

import Home from "../screens/Home";
import Canva from "../screens/Canva";
import PrintScreen from "../screens/Canva/PrintScreen";
import Profile from "../screens/Profile";
import Canvas from "../screens/Canvas";
import MyCanvas from "../screens/Canvas/MyCanvas";
import SharedCanvas from "../screens/Canvas/SharedCanvas";
import Community from "../screens/Community";
import Topics from "../screens/Topics";
import MyTopics from "../screens/Topics/MyTopics";
import AllTopics from "../screens/Topics/AllTopics";
import Topic from "../screens/Topic";
import FavoriteCanvas from "../screens/Canvas/FavoriteCanvas";
import FrequentlyAskedQuestions from "../screens/FrequentlyAskedQuestions";
import RegisterLead from "../screens/RegisterLead";
import Notifications from "../screens/Notifications";
import Notification from "../screens/Notification";

export function PrivateRoutes() {
  return (
    <NotificationProvider>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/invite/:uid" Component={Home} />
        <Route path="/canvas" Component={Canvas}>
          <Route index element={<Navigate to="/canvas/my-canvas" />} />
          <Route path="my-canvas" Component={MyCanvas} />
          <Route path="shared-canvas" Component={SharedCanvas} />
          <Route path="favorite-canvas" Component={FavoriteCanvas} />
        </Route>
        <Route path="/topics" Component={Topics}>
          <Route index element={<Navigate to="/topics/my-topics" />} />
          <Route path="my-topics" Component={MyTopics} />
          <Route path="all-topics" Component={AllTopics} />
        </Route>
        <Route path="topics/:id" Component={Topic} />
        <Route path="/canvas/my-canvas/:uid" Component={Canva} />
        <Route path="/canvas/shared-canvas/:uid" Component={Canva} />
        <Route path="/canvas/:id/print" Component={PrintScreen} />
        <Route path="/profile" Component={Profile} />
        <Route path="/community" Component={Community} />
        <Route path="/community/canvas/:uid" Component={Canva} />
        <Route
          path="frequently-asked-questions"
          Component={FrequentlyAskedQuestions}
        />
        <Route path="notifications" Component={Notifications} />
        <Route path="notifications/:id" Component={Notification} />
        <Route path="/*" element={<Navigate to="/" />} />
        <Route path="/licita-canvas-impresso" element={<RegisterLead />} />
      </Routes>
    </NotificationProvider>
  );
}
