import { PushPin } from "@mui/icons-material";

export default function ListItemContainer({
  title,
  subtitle,
  onClick,
  isFixed,
  children,
}) {
  return (
    <div className="rounded-lg border bg-white shadow-md duration-300 hover:border-purple-heart/40">
      {isFixed && (
        <PushPin className="mt-2 ml-3 -mb-4 md:-mb-1 rotate-45 text-gray-500" />
      )}
      <div className="flex items-center justify-between gap-4">
        <div className="pl-4 flex-1 cursor-pointer py-2" onClick={onClick}>
          <h4 className="text-lg font-medium">{title}</h4>
          <p className="text-gray-500">{subtitle}</p>
        </div>
        <div className="flex min-h-[40px] items-center pr-4">{children}</div>
      </div>
    </div>
  );
}
