import { Eye } from "@styled-icons/fa-solid";
import { twMerge } from "tailwind-merge";

import { countNumberMask } from "../../utils/countNumberMask";

export default function ViewsCount({ viewsCount = 0, className }) {
  return (
    <div className={twMerge("flex items-center gap-1.5", className)}>
      <Eye className="h-5 w-5 text-gray-400" />
      <span className="text-sm">{countNumberMask(viewsCount)}</span>
    </div>
  );
}
