import { useEffect, useRef } from "react";
import { toast } from "react-toastify";

import { gql, useMutation, useQuery } from "../contexts/ApiContext";
import { CanvaCommentFragment } from "../fragments/canvaCommentFragment";
import useScrollObserver from "../hooks/useScrollObserver";

import CommentsSection from "../components/CommentsSection";
import Loader from "../components/Loader";
import { useLocation } from "react-router-dom";

const GET_CANVA_COMMENTS = gql`
  query getCanva($uid: String!, $after: String) {
    canva(uid: $uid) {
      id
      user {
        id
      }
      comments(after: $after, first: 5) {
        edges {
          node {
            ...CanvaCommentFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${CanvaCommentFragment}
`;

const CREATE_CANVA_COMMENT = gql`
  mutation createCanvaComment($input: CreateCanvaCommentMutationInput!) {
    createCanvaComment(input: $input) {
      success
      errors
    }
  }
`;

const DELETE_CANVA_COMMENT = gql`
  mutation deleteCanvaComment($input: DeleteCanvaCommentMutationInput!) {
    deleteCanvaComment(input: $input) {
      success
      errors
    }
  }
`;

const LIKE_TOPIC_COMMENT = gql`
  mutation likeComment($input: LikeCommentMutationInput!) {
    likeComment(input: $input) {
      success
      errors
    }
  }
`;

export default function CanvaCommentsContainer({ uid }) {
  const observerRef = useRef(null);

  const location = useLocation();
  const lastHash = useRef("");

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1);
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
        lastHash.current = "";
      }, 500);
    }
  }, [location]);

  const { data, loading, refetch, fetchMore } = useQuery(GET_CANVA_COMMENTS, {
    variables: {
      uid: `${uid}`,
    },
  });

  const [createCommentMutation, { loading: loadingCreateComment }] =
    useMutation(CREATE_CANVA_COMMENT);

  const [deleteCommentMutation, { loading: loadingDeleteComment }] =
    useMutation(DELETE_CANVA_COMMENT);

  const [LikeCommentMutation] = useMutation(LIKE_TOPIC_COMMENT);

  const canva = data?.canva;
  const canvaComments = canva?.comments?.edges || [];
  const pageInfo = canva?.comments?.pageInfo;

  const fetchMoreData = () => {
    !!pageInfo?.hasNextPage &&
      fetchMore({
        variables: { after: pageInfo.endCursor },
      });
  };

  const handleCreateComment = async (comment) => {
    const response = await createCommentMutation({
      variables: { input: { content: comment, canvaId: canva.id } },
    });
    const errors = response?.data?.createCanvaComment?.errors;

    if (errors) {
      toast.error("Não foi possível adicionar o comentário");
      return;
    }

    toast.success("Comentário adicionado com sucesso!");
    refetch();
  };

  const handleDeleteComment = async (id) => {
    const response = await deleteCommentMutation({
      variables: { input: { id } },
    });
    const errors = response?.data?.deleteCanvaComment?.errors;

    if (errors) {
      toast.error("Não foi possível deletar o comentário");
      return;
    }

    toast.success("Comentário deletado com sucesso!");
    refetch();
  };

  const handleLikeComment = async (id) => {
    const response = await LikeCommentMutation({
      variables: {
        input: {
          likeableType: "CanvaComment",
          likeableId: id,
        },
      },
    });

    const errors = response?.data?.deleteTopicComment?.errors;

    if (errors) {
      return;
    }

    refetch();
  };

  useScrollObserver(observerRef, fetchMoreData);

  return (
    <>
      <CommentsSection
        comments={canvaComments}
        onCreate={handleCreateComment}
        onDelete={handleDeleteComment}
        loadingCreate={loadingCreateComment}
        loadingDelete={loadingDeleteComment}
        onLikeComment={handleLikeComment}
      />

      <div ref={observerRef} />

      {loading && <Loader />}
    </>
  );
}
