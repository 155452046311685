import { gql } from "../contexts/ApiContext";

export const TeamFragment = gql`
  fragment TeamFragment on Canva {
    approvers
    assistants
    editor
    estimator
    expenseOrganizer
    experts
    financial
    influencers
    isPublic
    legal
    market
    projectManager
    projectOrigin
    technicalManager
  }
`;
