import React, { forwardRef, useState } from "react";
import ReactInputMask from "react-input-mask";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import styled from "@emotion/styled";
import { twMerge } from "tailwind-merge";

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;

  label {
    color: #000000;
    font-family: "Nunito Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.16px;
    line-height: 24px;
  }

  input {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #cfd3d8;
    border-radius: 4px;
    height: 40px;
    width: 100%;
  }
`;

const Input = forwardRef(
  (
    {
      value,
      label,
      name,
      placeholder,
      type,
      onChange,
      mask,
      required,
      tooltip,
      className,
      errorMsg,
      ...props
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    return mask ? (
      <InputWrapper>
        {label && (
          <label
            data-tooltip-id="help-tooltip"
            data-tooltip-content={tooltip}
            htmlFor="input-field"
            className="px-1"
          >
            {label}
          </label>
        )}
        <ReactInputMask
          name={name}
          mask={mask}
          value={value}
          onChange={onChange}
          className={twMerge(
            "form-control placeholder:text-gray-400",
            className
          )}
          ref={ref}
          {...props}
        />
      </InputWrapper>
    ) : (
      <InputWrapper>
        {label && (
          <label
            data-tooltip-id="help-tooltip"
            data-tooltip-content={tooltip}
            data-tooltip-float="true"
            htmlFor="input-field"
            className="px-1"
          >
            {label}
          </label>
        )}
        <div className="relative">
          <input
            type={showPassword ? "text" : type}
            value={value}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            className={twMerge(
              "pl-2 py-2.5 pr-10 disabled:bg-gray-200 disabled:cursor-not-allowed focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 placeholder:text-gray-400",
              className
            )}
            required={required}
            ref={ref}
            {...props}
          />
          {type === "password" && (
            <button
              type="button"
              className="absolute top-1.5 right-2"
              onClick={() => setShowPassword((old) => !old)}
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </button>
          )}
        </div>
        {errorMsg && (
          <div>
            <p className="text-xs text-red-500">{errorMsg}</p>
          </div>
        )}
      </InputWrapper>
    );
  }
);

export default Input;
