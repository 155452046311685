import { gql } from "../contexts/ApiContext";

export const CanvaCardFragment = gql`
  fragment CanvaCardFragment on CanvaCard {
    id
    content
    bgColor
    kind
    order
  }
`;
