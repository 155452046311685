import { ArrowLeft } from "@styled-icons/fa-solid";
import { useNavigate } from "react-router-dom";

const GoBackButton = () => {
  const navigate = useNavigate();
  return (
    <span
      className="text-shark-200 hover:text-purple-heart transition-colors duration-200 cursor-pointer"
      onClick={() => navigate(-1)}
    >
      <ArrowLeft className="h-5 w-5" /> Voltar
    </span>
  );
};

export default GoBackButton;
