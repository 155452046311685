import { gql } from "../contexts/ApiContext";
import { CanvaCardFragment } from "./canvaCardFragment";

export const SolutionFragment = gql`
  fragment SolutionFragment on Solution {
    intendedResultsCards {
      ...CanvaCardFragment
    }
    interdependentHiringCards {
      ...CanvaCardFragment
    }
    justificationForInstallmentsCards {
      ...CanvaCardFragment
    }
    solutionDescriptionCards {
      ...CanvaCardFragment
    }
    valueEstimatesCards {
      ...CanvaCardFragment
    }
    measuresToBeAdoptedCards {
      ...CanvaCardFragment
    }
  }
  ${CanvaCardFragment}
`;
