import React from "react";
import { Tooltip } from "react-tooltip";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import Button from "../Button";
import SortablePostIt from "./SortablePostIt";

export default function PostItContainer({
  id,
  column,
  items,
  onButtonClick,
  onEditPostIt,
  onDeletePostIt,
  disabled = false,
  buttonTitle = "Adicionar post-it",
}) {
  const { setNodeRef } = useDroppable({
    id: id,
  });

  return (
    <div className="flex flex-col bg-white px-3 py-4 rounded-lg h-fit min-h-[173px]">
      <SortableContext
        id={id}
        items={items}
        disabled={disabled}
        strategy={verticalListSortingStrategy}
      >
        <Tooltip id={column.key} className="z-50 max-w-xs" />
        <p
          className="flex items-center justify-between text-base font-bold"
          data-tooltip-id={column.key}
          data-tooltip-content={column.tooltip}
          data-tooltip-float="true"
        >
          {column?.name}
          <HelpOutlineIcon className="h-4 w-4 ml-1" />
        </p>
        <div ref={setNodeRef} className="flex flex-1 flex-col gap-2.5 mt-1">
          {items.map((item) => (
            <SortablePostIt
              key={item.id}
              item={item}
              onEditPostIt={() => onEditPostIt(item)}
              onDeletePostIt={onDeletePostIt}
              disabled={disabled}
            />
          ))}
        </div>
      </SortableContext>
      {!disabled && (
        <Button
          type="button"
          variant="outline"
          className="mt-2.5"
          onClick={() => onButtonClick(column.kind)}
        >
          {buttonTitle}
        </Button>
      )}
    </div>
  );
}
