import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { styled } from "styled-components";
import { Alert } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

import Button from "../Button";
import Input from "../Input";

import { useApp } from "../../contexts/AppContext";

import { tryUserLogin } from "../../constants/Auth";

const Form = styled.form`
  width: 100%;
`;

const formSchema = z.object({
  email: z.string().email({ message: "Email é obrigatorio" }),
  password: z.string().min(1, { message: "Senha é obrigatorio" }),
});

const AuthContainer = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { signIn } = useApp();

  const handleSingIn = async (values) => {
    setLoading(true);
    const { email, password } = values;

    try {
      const token = await tryUserLogin(email, password);
      await signIn(token, true);
    } catch (e) {
      toast.error("Email ou senha incorretos!");
    } finally {
      setLoading(false);
    }
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    resolver: zodResolver(formSchema),
  });

  const messageInfo = Object.keys(errors)?.map((key) => errors?.[key]?.message);

  return (
    <>
      {!!messageInfo?.length && (
        <Alert severity="error">{messageInfo?.[0]}</Alert>
      )}
      <Form onSubmit={handleSubmit(handleSingIn)} className="mt-3">
        <Input
          id="email"
          name="email"
          label="E-mail"
          className="rounded-3xl px-4"
          {...register("email")}
        />
        <Input
          id="password"
          name="password"
          type="password"
          label="Senha"
          className="rounded-3xl px-4"
          {...register("password")}
        />
        <div className="flex flex-col items-center mt-7">
          <Button
            variant="secondary"
            type="submit"
            disabled={loading}
            loading={loading}
          >
            Entrar
          </Button>
          <button
            onClick={() => navigate("/forget-password")}
            className="block text-purple-heart mt-1.5"
          >
            Esqueci minha senha
          </button>
        </div>
      </Form>
    </>
  );
};

export default AuthContainer;
