import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { gql, useQuery } from "../../contexts/ApiContext";

import Sidebar from "../../components/Sidebar";
import Loader from "../../components/Loader";
import GoBackButton from "../../components/GoBackButton";

const GET_NOTIFICATION = gql`
  query notification($id: ID!) {
    me {
      id
      unreadMessages
    }
    notification(id: $id) {
      id
      title
      description
      readAt
      notificationTargetId
      notificationTargetType
    }
  }
`;

const Notification = () => {
  const { id } = useParams();
  const { data, loading, refetch } = useQuery(GET_NOTIFICATION, {
    variables: { id },
  });

  const notification = data?.notification;

  const targetNameByType = {
    UserTopic: "Tópico",
    Canva: "Canva",
  };

  const targetUrlByType = {
    UserTopic: `/topics/${notification?.notificationTargetId}#comments`,
    Canva: `/canvas/my-canvas/${notification?.notificationTargetId}#comments`,
  };

  useEffect(() => {
    return () => {
      refetch();
    };
  }, []);

  return (
    <Sidebar>
      <div className="p-6">
        <GoBackButton />
        {loading ? (
          <Loader />
        ) : (
          <>
            <h2 className="text-lg my-3 font-semibold">{notification.title}</h2>
            <p className="text-sm mb-3">{notification.description}</p>
            {notification.notificationTargetType && (
              <a
                href={targetUrlByType[notification.notificationTargetType]}
                className="text-purple-heart hover:underline"
              >
                Ver em {targetNameByType[notification.notificationTargetType]}
              </a>
            )}
          </>
        )}
      </div>
    </Sidebar>
  );
};

export default Notification;
