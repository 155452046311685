import { Footer } from "../../components/Footer";
import { Navbar } from "../../components/Navbar";

import HomeImage from "../../assets/home_image.png";

export function Root() {
  return (
    <div className="flex flex-col h-screen">
      <Navbar />
      <div className="flex flex-col-reverse items-center flex-1 px-6 py-12 lg:px-32 lg:flex-row lg:py-0">
        <div className="flex-1 shrink-0">
          <div className="mx-auto max-w-xs">
            <h1 className="text-[35px] leading-[45px] lg:text-[45px] lg:leading-[55px]">
              Crie o seu ETP de graça com o Licita Canvas
            </h1>
            <a
              href="/login"
              className="block w-fit bg-lavender-canva px-12 py-3 rounded-full font-bold mt-16"
            >
              Comece agora
            </a>
          </div>
        </div>
        <div className="flex-1 shrink-0 max-w-96 lg:max-w-none">
          <img
            alt="postits-colorful"
            className="object-contain"
            src={HomeImage}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
