import { centerPointsCols } from "./StepProblems";
import { marketingResearchesCols } from "./StepMarketResearch";
import { feasibilityAnalysisCols } from "./StepForecastAnalysis";
import { solutionsCols } from "./StepSolution";
import { environmentalResponsibilityCols } from "./StepResponsibility";
import { ODsImages } from "./contants";
import { riskAnalysisCols } from "./StepRiskAnalysis";

import image1 from "../../assets/step_01.png";
import image2 from "../../assets/step_02.png";
import image3 from "../../assets/step_03.png";
import image4 from "../../assets/step_04.png";
import image5 from "../../assets/step_05.png";
import image6 from "../../assets/step_06.png";
import image7 from "../../assets/step_07.png";
import image8 from "../../assets/step_08.png";
import { twMerge } from "tailwind-merge";
import { conclusionCols } from "./StepConclusion";

const PrintScreen = ({ canva }) => {
  const title = `Licita Canvas - ${canva?.name}`;

  const canvaTeam = {
    projectOrigin: canva?.projectOrigin,
    projectManager: canva?.projectManager,
    technicalManager: canva?.technicalManager,
    estimator: canva?.estimator,
    editor: canva?.editor,
    legal: canva?.legal,
    assistants: canva?.assistants,
    expenseOrganizer: canva?.expenseOrganizer,
    approvers: canva?.approvers,
    experts: canva?.experts,
    market: canva?.market,
    influencers: canva?.influencers,
    financial: canva?.financial,
  };
  const centerPoints = canva?.centerPoints;
  const marketingResearches = canva?.marketingResearches;
  const feasibilityAnalysis = canva?.feasibilityAnalysis;
  const solutions = canva?.solution;
  const environmentalResponsibility = canva?.environmentalResponsibility;
  const sustainableGoals = canva?.sustainableGoals;
  const riskAnalysis = canva?.riskAnalysis;
  const conclusion = canva?.conclusion;

  return (
    <div className="flex-col w-[3300px] p-14 tracking-wide">
      <h1 className="text-center text-xl mb-4 mt-10">{title}</h1>
      <div className="grid grid-cols-3 gap-6">
        <div>
          <CanvaTitleItem
            title="Pessoas"
            src={image1}
            className="bg-yellow-canva border-yellow-canva"
          />
          <div className="w-fit min-w-[540px]">
            <CanvaListItem title="Origem" className="bg-yellow-canva">
              <p>{canvaTeam?.projectOrigin}</p>
            </CanvaListItem>
          </div>

          <div className="grid grid-cols-2 gap-2">
            <div>
              <CanvaTitleItem
                title="Equipe de planejamento"
                className="border-yellow-canva"
              />
              <div className="grid grid-cols-2 gap-2">
                <CanvaListItem
                  title="Gerente de Projeto"
                  className="bg-yellow-canva"
                >
                  <p>{canvaTeam?.projectManager}</p>
                </CanvaListItem>
                <CanvaListItem
                  title="Responsável Técnico (demandante)"
                  className="bg-yellow-canva"
                >
                  <p>{canvaTeam?.technicalManager}</p>
                </CanvaListItem>
                <CanvaListItem title="Orçamentista" className="bg-yellow-canva">
                  <p>{canvaTeam?.estimator}</p>
                </CanvaListItem>
                <CanvaListItem title="Redator" className="bg-yellow-canva">
                  <p>{canvaTeam?.editor}</p>
                </CanvaListItem>
                <CanvaListItem title="Jurídico" className="bg-yellow-canva">
                  <p>{canvaTeam?.legal}</p>
                </CanvaListItem>
                <CanvaListItem title="Auxiliares" className="bg-yellow-canva">
                  <p>{canvaTeam?.assistants}</p>
                </CanvaListItem>
              </div>
            </div>
            <div>
              <CanvaTitleItem
                title="Stakeholders"
                className="border-orange-canva"
              />
              <div className="grid grid-cols-2 gap-2">
                <CanvaListItem
                  title="Ordenador de Despesas"
                  className="bg-orange-canva"
                >
                  <p>{canvaTeam?.expenseOrganizer}</p>
                </CanvaListItem>
                <CanvaListItem title="Aprovadores" className="bg-orange-canva">
                  <p>{canvaTeam?.approvers}</p>
                </CanvaListItem>
                <CanvaListItem
                  title="Especialistas"
                  className="bg-orange-canva"
                >
                  <p>{canvaTeam?.experts}</p>
                </CanvaListItem>
                <CanvaListItem title="Mercado" className="bg-orange-canva">
                  <p>{canvaTeam?.market}</p>
                </CanvaListItem>
                <CanvaListItem
                  title="Influenciadores"
                  className="bg-orange-canva"
                >
                  <p>{canvaTeam?.influencers}</p>
                </CanvaListItem>
                <CanvaListItem title="Financeiros" className="bg-orange-canva">
                  <p>{canvaTeam?.financial}</p>
                </CanvaListItem>
              </div>
            </div>
          </div>
          <CanvaTitleItem
            title="Pontos Centrais"
            src={image2}
            className="bg-blue-canva border-blue-canva"
          />
          <div className="grid grid-cols-4 gap-2">
            {centerPointsCols.slice(0, 4)?.map((col) => (
              <CanvaPostItWrapper key={col.key} title={col?.name}>
                {centerPoints?.[col?.key]?.map((item) => (
                  <CanvaPostItItem key={item.id} item={item} />
                ))}
              </CanvaPostItWrapper>
            ))}
          </div>
          <CanvaTitleItem
            title="Requisitos da aquisição/contratação"
            className="mt-2 border-blue-canva"
          />
          <div className="grid grid-cols-4 gap-2">
            {centerPointsCols.slice(4, 8)?.map((col) => (
              <CanvaPostItWrapper key={col.key} title={col?.name}>
                {centerPoints?.[col?.key]?.map((item) => (
                  <CanvaPostItItem key={item.id} item={item} />
                ))}
              </CanvaPostItWrapper>
            ))}
          </div>
        </div>
        <div>
          <div>
            <CanvaTitleItem
              title="Pesquisa Mercadológica"
              src={image3}
              className="bg-violet-canva border-violet-canva"
            />
            <div>
              {marketingResearches?.map((marketingResearch, index) => {
                return (
                  <div className="grid grid-cols-4 gap-y-4 [&:not(:first-child)]:mt-8">
                    {marketingResearchesCols?.map((col) => {
                      return (
                        <div key={col.key}>
                          <CanvaPostItWrapper
                            title={`${col?.name} ${index + 1}`}
                          >
                            {marketingResearch?.[col?.key]?.map((item) => (
                              <CanvaPostItItem key={item.id} item={item} />
                            ))}
                          </CanvaPostItWrapper>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>

            <CanvaTitleItem
              title="Análise de Viabilidade"
              src={image4}
              className="bg-red-canva border-red-canva"
            />
            <div className="grid grid-cols-3 gap-2">
              {feasibilityAnalysisCols.map((col) => {
                return (
                  <CanvaPostItWrapper key={col.key} title={col?.name}>
                    {feasibilityAnalysis?.[col?.key]?.map((item) => (
                      <CanvaPostItItem
                        key={item.id}
                        item={item}
                        className={"w-full"}
                      />
                    ))}
                  </CanvaPostItWrapper>
                );
              })}
            </div>
          </div>
        </div>
        <div>
          <CanvaTitleItem
            title="Solução"
            src={image5}
            className="bg-green-canva border-green-canva"
          />
          <div className="grid grid-cols-3 gap-2">
            {solutionsCols.map((col) => {
              return (
                <CanvaPostItWrapper key={col.key} title={col?.name}>
                  {solutions?.[col?.key].map((item) => (
                    <CanvaPostItItem
                      key={item.id}
                      item={item}
                      className={"w-full"}
                    />
                  ))}
                </CanvaPostItWrapper>
              );
            })}
          </div>
          <CanvaTitleItem
            title="Análise de riscos"
            src={image7}
            className="bg-pink-canva border-pink-canva"
          />
          <div className="grid grid-cols-2 gap-2">
            {riskAnalysisCols.map((col) => (
              <CanvaPostItWrapper key={col.key} title={col?.name}>
                {riskAnalysis?.[col?.key]?.map((item) => (
                  <CanvaPostItItem
                    key={item.id}
                    item={item}
                    className={"w-full"}
                  />
                ))}
              </CanvaPostItWrapper>
            ))}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-2">
          <CanvaTitleItem
            title="Responsabilidade ambiental"
            src={image6}
            className="bg-dark-green-canva border-dark-green-canva"
          />
          <div className="grid grid-cols-3 gap-2">
            <CanvaPostItWrapper title="ODS alcançados pelo projeto">
              <div className="flex gap-2 flex-wrap">
                {sustainableGoals?.map((sustainableGoal) => (
                  <img
                    key={sustainableGoal.id}
                    className="mt-2 w-20"
                    src={ODsImages?.[sustainableGoal?.fileName]}
                    alt={sustainableGoal?.title}
                  />
                ))}
              </div>
            </CanvaPostItWrapper>
            {environmentalResponsibilityCols?.map((col) => (
              <CanvaPostItWrapper
                key={col.key}
                title={col?.name}
                className="w-full"
              >
                {environmentalResponsibility?.[col?.key]?.map((item) => (
                  <CanvaPostItItem key={item.id} item={item} />
                ))}
              </CanvaPostItWrapper>
            ))}
          </div>
        </div>
        <div>
          <CanvaTitleItem
            title="Conclusão"
            src={image8}
            className="bg-magic-mint border-magic-mint"
          />
          <div className="grid grid-cols-2 gap-2">
            {conclusionCols.map((col) => {
              return (
                <CanvaPostItWrapper key={col.key} title={col?.name}>
                  {conclusion?.[col?.key]?.map((item) => (
                    <CanvaPostItItem
                      key={item.id}
                      item={item}
                      className={"flex-1"}
                    />
                  ))}
                </CanvaPostItWrapper>
              );
            })}
            <CanvaListItem
              title="Posicionamento conclusivo"
              className="bg-yellow-canva"
            >
              <p>
                {canvaTeam?.conclusivePositioning ||
                  `${"Após todo o estudo realizado neste projeto, concluiu-se que a solução indicada no tópico 5 atende às necessidades da Administração Pública previstas no tópico 2, conforme análises de viabilidade contidas no tópico 4 e demais justificativas contidas no presente documento. \nO Licita Canvas elaborado pela Equipe de Planejamento é parte integrante deste ETP, bem como todos os demais documentos que o dão suporte, como pesquisas mercadológicas, memórias de cálculo, estimativas, previsões orçamentárias e demais documentos anexos."}`}
              </p>
            </CanvaListItem>
          </div>
        </div>
      </div>
    </div>
  );
};

function CanvaPostItItem({ item, className }) {
  return (
    <div
      className={twMerge(
        "text-sm px-3 h-fit rounded-md rounded-tl-none pb-4 w-full min-h-[140px]",
        className
      )}
      style={{ backgroundColor: item?.bgColor }}
    >
      <p>{item?.content}</p>
    </div>
  );
}

function CanvaPostItWrapper({ className, title, children }) {
  return (
    <div className={twMerge("pl-1", className)}>
      <h6>{title}</h6>
      <div className="flex flex-wrap gap-2 mt-2.5">{children}</div>
    </div>
  );
}

function CanvaListItem({ className, title, children }) {
  return (
    <div className="rounded-md px-2">
      <h6>{title}</h6>
      <div
        className={twMerge(
          "mt-3 text-sm px-3 rounded-md min-h-[32px] pb-4",
          className
        )}
      >
        {children}
      </div>
    </div>
  );
}

function CanvaTitleItem({ title, className, src }) {
  return (
    <div className="flex mt-6 mb-1 gap-2">
      {src && (
        <div className="h-10 w-10">
          <img src={src} alt="" />
        </div>
      )}
      <div
        className={twMerge(
          "flex-1 rounded-md mr-2 px-2 border-l-8 pb-4",
          className
        )}
      >
        <h2 className="text-base font-bold">{title}</h2>
      </div>
    </div>
  );
}

export default PrintScreen;
