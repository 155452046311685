import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { styled } from "styled-components";
import { ChevronDown, ChevronUp } from "@styled-icons/fa-solid";

const CollapseBase = styled.div`
  background-color: ${(props) => (props.isOpen ? "#F6F8F9" : "#fff")};
  border-radius: 12px;
  box-shadow: 0px 1px 4px #00000040;
  padding: 16px 10px;
  position: relative;

  .collapse-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    cursor: pointer;
  }

  .collapse-body {
    display: ${(props) => (props.isOpen ? "block" : "none")};
  }
`;

const CollapsedIcon = styled(ChevronDown)`
  color: #636e7c;
  width: 20px;
  height: 20px;
`;

const CollapseIcon = styled(ChevronUp)`
  color: #636e7c;
  width: 20px;
  height: 20px;
`;

const CollapseTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 10px;

  img {
    /* width: 24px; */
    height: 28px;
    border-radius: 50%;
    padding: 2px;
    border: 1px solid #c4dbef;
  }
`;

const Collapse = ({ children, onClick = () => {}, ...props }) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const handleOnClick = () => {
    onClick();
    toggle();
  };

  return (
    <CollapseBase
      bgColor={props.bgColor}
      isOpen={isOpen}
      id={props.id || props.title}
    >
      <div className="collapse-header" onClick={handleOnClick}>
        <CollapseTitle>
          <img src={props.image} alt={props.index} />
          <div className="collapse-title">{props.title}</div>
        </CollapseTitle>
        <div className="collapse-icon">
          {isOpen ? <CollapseIcon /> : <CollapsedIcon />}
        </div>
      </div>
      <div className="collapse-body">{children}</div>
    </CollapseBase>
  );
};

Collapse.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  image: PropTypes.string,
  index: PropTypes.number,
};

export default Collapse;
