import { useEffect } from "react";

const useScrollObserver = (observerRef, fetchMoreData) => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          fetchMoreData();
        }
      });
    });

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [observerRef.current, fetchMoreData]);

  return observerRef;
};

export default useScrollObserver;
