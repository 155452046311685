import * as React from "react";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

import Sidebar from "../../components/Sidebar";
import { Footer } from "../../components/Footer";
import { ModalInviteContainer } from "../../containers/ModalInviteContainer";

import HomeBg1 from "../../assets/home/home_01.png";
import HomeBg2 from "../../assets/home/home_02.png";
import HomeBg3 from "../../assets/home/home_03.png";
import HomeBg4 from "../../assets/home/home_04.png";
import HomeBg5 from "../../assets/home/home_05.png";
import HomeBg6 from "../../assets/home/home_06.png";
import HomeBg7 from "../../assets/home/home_07.png";
import HomeBg8 from "../../assets/home/home_08.png";
import HomeBg9 from "../../assets/home/home_09.png";
import HomeBg10 from "../../assets/home/home_10.png";
import HomeBg11 from "../../assets/home/home_11.png";
import HomeBg12 from "../../assets/home/home_12.png";

const backgroundImages = [
  HomeBg1,
  HomeBg2,
  HomeBg3,
  HomeBg4,
  HomeBg5,
  HomeBg6,
  HomeBg7,
  HomeBg8,
  HomeBg9,
  HomeBg10,
  HomeBg11,
  HomeBg12,
];

const Home = () => {
  const [currentBgIndex, setCurrentBgIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * backgroundImages.length);
      setCurrentBgIndex(randomIndex);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isMobile) {
      navigate("/canvas/my-canvas");
    }
  }, [isMobile]);

  return (
    <div className="flex flex-col min-h-screen">
      <div
        className="absolute overflow-hidden right-0 h-full w-2/3 bg-no-repeat bg-cover rounded-tl-full transition-background duration-1000"
        style={{ backgroundImage: `url(${backgroundImages[currentBgIndex]})` }}
      />
      <Sidebar headerStyle="bg-transparent" />
      <Footer />
      <ModalInviteContainer />
    </div>
  );
};

export default Home;
