import { createContext, useContext } from "react";

import { gql, useQuery } from "./ApiContext";

const GET_NOTIFICATIONS = gql`
  query notifications {
    me {
      id
      unreadMessages
    }
  }
`;

const NotificationContext = createContext();

const useNotification = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }

  return context;
};

const NotificationProvider = ({ children }) => {
  const { data } = useQuery(GET_NOTIFICATIONS, {
    pollInterval: 20000,
  });

  const unreadMessages = data?.me?.unreadMessages || 0;

  return (
    <NotificationContext.Provider value={{ unreadMessages }}>
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationProvider, useNotification };
