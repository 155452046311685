import { useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

import { gql, useMutation } from "../../contexts/ApiContext";
import { ConclusionFragment } from "../../fragments/conclusionFragment";

import { Flex } from "../../components/Flex";
import { ButtonConfetti } from "../../components/ButtonConfetti";
import DragAndDropPostIt from "../../components/DragAndDropPostIt";

export const conclusionCols = [
  {
    name: "Informações Adicionais",
    key: "additionalInformationCards",
    kind: "additional_information",
    tooltip:
      "Descreva a solução encontrada durante o estudo. Detalhe as características e funcionalidades da solução, incluindo as exigências de manutenção e assistência técnica, quando aplicável. Caso existam contratações correlatas ou interdependentes, mencione-as aqui e justifique adequadamente no tópico específico.",
  },
];

const CREATE_CANVA_CARD = gql`
  mutation createCanvaCard($input: CreateCanvaCardMutationInput!) {
    createCanvaCard(input: $input) {
      errors
      success
      canvaCard {
        canva {
          id
          name
          conclusion {
            ...ConclusionFragment
          }
        }
      }
    }
  }
  ${ConclusionFragment}
`;

const UPDATE_CANVA = gql`
  mutation updateCanva($input: UpdateCanvaMutationInput!) {
    updateCanva(input: $input) {
      errors
      canva {
        id
        name
        conclusivePositioning
      }
    }
  }
`;

const UPDATE_CANVA_CARD = gql`
  mutation updateCard($input: UpdateCardMutationInput!) {
    updateCard(input: $input) {
      errors
      success
      canvaCard {
        canva {
          id
          name
          conclusion {
            ...ConclusionFragment
          }
        }
      }
    }
  }
  ${ConclusionFragment}
`;

const DELETE_CANVA_CARD = gql`
  mutation deleteCanvaCard($input: DeleteCardMutationInput!) {
    deleteCard(input: $input) {
      errors
      success
      canva {
        id
        name
        conclusion {
          ...ConclusionFragment
        }
      }
    }
  }
  ${ConclusionFragment}
`;

const StepConclusion = ({ canva, canEdit }) => {
  const [updateCanvaCard, { loading: loadingUpdate }] =
    useMutation(UPDATE_CANVA_CARD);
  const [updateCanva, { loading: loadingUpdateCanva }] =
    useMutation(UPDATE_CANVA);
  const [createCanvaCard, { loading: loadingCreate }] =
    useMutation(CREATE_CANVA_CARD);
  const [deleteCanvaCard, { loading: loadingDelete }] =
    useMutation(DELETE_CANVA_CARD);

  const { handleSubmit, register } = useForm({
    defaultValues: {
      conclusivePositioning:
        canva?.conclusivePositioning ||
        "Após todo o estudo realizado neste projeto, concluiu-se que a solução indicada no tópico 5 atende às necessidades da Administração Pública previstas no tópico 2, conforme análises de viabilidade contidas no tópico 4 e demais justificativas contidas no presente documento. \nO Licita Canvas elaborado pela Equipe de Planejamento é parte integrante deste ETP, bem como todos os demais documentos que o dão suporte, como pesquisas mercadológicas, memórias de cálculo, estimativas, previsões orçamentárias e demais documentos anexos.",
    },
  });

  const onSubmit = async (values) => {
    const response = await updateCanva({
      variables: { input: { ...values, id: canva?.id } },
    });

    const errors = response?.data?.updateCanva?.errors;

    if (errors) {
      toast.error("Não foi possível alterar o canva");
      return;
    }

    toast.success("Licita Canvas alterado com sucesso!");
  };

  return (
    <div>
      <div className="bg-magic-mint grid grid-cols-1 lg:grid-cols-3 gap-4 rounded-lg mt-2">
        <DragAndDropPostIt
          canvaId={canva?.id}
          colsCards={canva?.conclusion}
          cols={conclusionCols}
          createCanvaCard={createCanvaCard}
          updateCanvaCard={updateCanvaCard}
          deleteCanvaCard={deleteCanvaCard}
          disabled={!canEdit}
          loading={loadingUpdate || loadingCreate || loadingDelete}
          containerStyle="mt-0"
        />
        <div className="flex flex-col col-span-2 py-2 pr-2">
          <p className="flex items-center justify-between text-base font-bold">
            Posicionamento Conclusivo
          </p>
          <textarea
            className="w-full flex-1 mt-2 rounded-md py-2 px-3 disabled:bg-gray-200 disabled:opacity-50"
            disabled={!canEdit}
            {...register("conclusivePositioning")}
          />
        </div>
      </div>

      {canEdit && (
        <Flex justifyContent="end" className="mt-4">
          <ButtonConfetti
            loading={loadingUpdateCanva}
            title={"Salvar"}
            onClick={handleSubmit(onSubmit)}
          />
        </Flex>
      )}
    </div>
  );
};

export default StepConclusion;
