import { Tooltip } from "react-tooltip";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { z } from "zod";

import { gql, useMutation } from "../../contexts/ApiContext";
import { TeamFragment } from "../../fragments/teamFragment";

import { Flex } from "../../components/Flex";
import Input from "../../components/Input";
import { ButtonConfetti } from "../../components/ButtonConfetti";

const Container = styled(Flex)`
  margin: 16px 0;
  border-radius: 10px;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin: 16px 0;
`;

const ContentTeam = styled.div`
  background-color: #fff2b3;
  padding: 0 10px;
  border-radius: 10px;
  margin-bottom: 10px;
`;

const ContentStake = styled.div`
  background-color: #ffdecb;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
`;

const FlexInput = styled(Flex)`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const UPDATE_TEAM = gql`
  mutation updateTeam($input: UpdateCanvaMutationInput!) {
    updateCanva(input: $input) {
      errors
      canva {
        id
        name
        ...TeamFragment
      }
    }
  }
  ${TeamFragment}
`;

const formSchema = z.object({
  approvers: z.string().nullable(),
  assistants: z.string().nullable(),
  editor: z.string().nullable(),
  estimator: z.string().nullable(),
  expenseOrganizer: z.string().nullable(),
  experts: z.string().nullable(),
  financial: z.string().nullable(),
  influencers: z.string().nullable(),
  legal: z.string().nullable(),
  market: z.string().nullable(),
  projectManager: z.string().nullable(),
  projectOrigin: z.string().nullable(),
  technicalManager: z.string().nullable(),
});

const StepTeam = ({ canva, setStep, canEdit }) => {
  const [updateTeam, { loading }] = useMutation(UPDATE_TEAM);

  const { handleSubmit, register, reset } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: canva,
  });

  const onSubmit = async (values) => {
    const response = await updateTeam({
      variables: {
        input: { id: canva?.id, ...values },
      },
    });

    const errors = response?.data?.updateCanva?.errors;
    if (errors) {
      toast.error("Não foi possível atualizar, tente novamente mais tarde!");
      return;
    }

    toast.success("Pessoas atualizada com sucesso!");
    setStep(2);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container flexDirection="column">
        <Tooltip id="help-tooltip" className="z-50 max-w-xs" />
        <ContentTeam>
          <Title>Origem</Title>
          <Flex alignItems="flex-end" gap="10px">
            <Input
              id="projectOrigin"
              name="projectOrigin"
              label="Origem do Projeto"
              tooltip="Informe qual é o órgão ou a entidade que está demandando, bem como número de Documento de Oficialização da Demanda (ou equivalente, como C.I, etc.), se houver."
              {...register("projectOrigin")}
              disabled={!canEdit}
            />
          </Flex>
          <Title
            data-tooltip-id="help-tooltip"
            data-tooltip-content="Preencha quem são os responsáveis pelo projeto."
            data-tooltip-float="true"
          >
            Equipe de Planejamento
          </Title>
          <FlexInput alignItems="flex-end" gap="10px">
            <Input
              id="projectManager"
              name="projectManager"
              label="Gerente do Projeto"
              tooltip="Quem vai conduzir o projeto, acompanhando entregas e cumprimento de prazos."
              {...register("projectManager")}
              disabled={!canEdit}
            />
            <Input
              id="technicalManager"
              name="technicalManager"
              label="Responsável Técnico(demandante)"
              tooltip="Servidor do órgão ou entidade demandante, responsável por trazer todas as informações da origem."
              {...register("technicalManager")}
              disabled={!canEdit}
            />
            <Input
              id="estimator"
              name="estimator"
              label="Orçamentista"
              tooltip="Quem será responsável pela estimativa de valor com preços unitários referenciais."
              {...register("estimator")}
              disabled={!canEdit}
            />
          </FlexInput>
          <FlexInput alignItems="flex-end" gap="10px">
            <Input
              id="editor"
              name="editor"
              label="Redator"
              tooltip="Quem vai redigir o ETP após as deliberações da equipe de planejamento."
              {...register("editor")}
              disabled={!canEdit}
            />
            <Input
              id="legal"
              name="legal"
              label="Jurídico"
              tooltip="Especialista jurídico que acompanhará e revisará o ETP."
              {...register("legal")}
              disabled={!canEdit}
            />
            <Input
              id="assistants"
              name="assistants"
              label="Auxiliares"
              tooltip="Demais membros da equipe de planejamento."
              {...register("assistants")}
              disabled={!canEdit}
            />
          </FlexInput>
        </ContentTeam>
        <ContentStake>
          <Title
            data-tooltip-id="help-tooltip"
            data-tooltip-content="Preencha aqueles que, além da equipe de planejamento, serão impactados pelo projeto, direta ou indiretamente."
            data-tooltip-float="true"
          >
            Stakeholders
          </Title>
          <FlexInput gap="10px">
            <Input
              id="expenseOrganizer"
              name="expenseOrganizer"
              label="Ordenador de Despesas"
              tooltip="Quem vai ordenar a despesa caso o ETP siga para as próximas fases."
              {...register("expenseOrganizer")}
              disabled={!canEdit}
            />
            <Input
              id="approvers"
              name="approvers"
              label="Aprovadores"
              tooltip="Todos aqueles envolvidos na aprovação do ETP."
              {...register("approvers")}
              disabled={!canEdit}
            />
            <Input
              id="experts"
              name="experts"
              label="Especialistas"
              tooltip="Especialistas técnicos na necessidade apresentada que poderão ser consultados durante a elaboração do ETP."
              {...register("experts")}
              disabled={!canEdit}
            />
          </FlexInput>
          <FlexInput gap="10px">
            <Input
              id="market"
              name="market"
              label="Mercado"
              tooltip="Aqueles que estão aptos a fornecer informações sobre produtos e serviços, para auxiliar na elaboração do projeto, como empresas que fornecem soluções e cotações para a pesquisa de mercado."
              {...register("market")}
              disabled={!canEdit}
            />
            <Input
              id="influencers"
              name="influencers"
              label="Influenciadores"
              tooltip="Aqueles que possuem alto poder de influência perante os outros, ainda que não estejam diretamente relacionados ao projeto."
              {...register("influencers")}
              disabled={!canEdit}
            />
            <Input
              id="financial"
              name="financial"
              label="Financeiros"
              tooltip="Aqueles que possuem responsabilidades financeiras com o projeto."
              {...register("financial")}
              disabled={!canEdit}
            />
          </FlexInput>
        </ContentStake>
        {canEdit && (
          <Flex justifyContent="end">
            <ButtonConfetti loading={loading} title="Salvar" />
          </Flex>
        )}
      </Container>
    </form>
  );
};

export default StepTeam;
