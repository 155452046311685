import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronRight, Trash } from "@styled-icons/fa-solid";
import { twMerge } from "tailwind-merge";

import { gql, useMutation, useQuery } from "../../contexts/ApiContext";
import useScrollObserver from "../../hooks/useScrollObserver";

import Sidebar from "../../components/Sidebar";
import Loader from "../../components/Loader";
import { ModalDelete } from "../../components/ModalDelete";
import { toast } from "react-toastify";

const GET_NOTIFICATION = gql`
  query getNotifications($after: String) {
    myNotifications(first: 10, after: $after) {
      edges {
        node {
          id
          title
          readAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

const DELTE_NOTIFICATION = gql`
  mutation deleteUserNotification(
    $input: DeleteUserNotificationMutationInput!
  ) {
    deleteUserNotification(input: $input) {
      success
      errors
    }
  }
`;

const Notifications = () => {
  const [notificationToDelete, setNotificationToDelete] = useState(false);
  const observerRef = useRef(null);
  const navigate = useNavigate();
  const { data, fetchMore, loading, refetch } = useQuery(GET_NOTIFICATION);

  const [deleteNotification, { loading: loadingDeleteNotification }] =
    useMutation(DELTE_NOTIFICATION);

  const notifications = data?.myNotifications?.edges || [];
  const pageInfo = data?.myNotifications?.pageInfo;

  const fetchMoreData = () => {
    !!pageInfo?.hasNextPage &&
      fetchMore({
        variables: { after: pageInfo.endCursor },
      });
  };

  useScrollObserver(observerRef, fetchMoreData);

  const handleDeleteNotification = async () => {
    const response = await deleteNotification({
      variables: { input: { id: notificationToDelete?.id } },
    });

    const errors = response?.data?.deleteUserNotification?.errors;

    if (errors) {
      toast.error("Não foi possível excluir a notificação.");
      return;
    }
    setNotificationToDelete(null);
    refetch();
  };

  return (
    <Sidebar>
      <div className="p-6">
        <h1 className="mt-2.5 text-3xl font-semibold">Notificações</h1>
        <h2 className="mt-5 text-2xl font-semibold">
          Bem-vindo(a) às suas notificações.
        </h2>
        <h3 className="mt-5 mb-6 text-xl">
          Aqui você receberá alertas sobre comentários recebidos nos Licita
          Canvas que você criou, bem como nos tópicos do Fórum de discussão que
          você está participando. Recomendamos apagar suas notificações depois
          de lidas, para não perder nenhuma notificação nova.
        </h3>
        {notifications.map((notification) => (
          <NotificationItem
            key={notification.node.id}
            notification={notification.node}
            onClick={() => navigate(`/notifications/${notification.node.id}`)}
            onDelete={setNotificationToDelete}
          />
        ))}
        <div ref={observerRef} />
        {loading && (
          <div className="mt-12">
            <Loader />
          </div>
        )}
      </div>
      <ModalDelete
        open={!!notificationToDelete}
        onClose={() => setNotificationToDelete(false)}
        onConfirm={handleDeleteNotification}
        loading={loadingDeleteNotification}
        title="Deseja excluir a notificação?"
      />
    </Sidebar>
  );
};

const NotificationItem = ({ notification, onClick, onDelete }) => {
  const { title, readAt } = notification;

  return (
    <div
      className={
        "flex justify-between border-b border-gray-300 items-center cursor-pointer py-3 hover:opacity-70 transition-opacity duration-100"
      }
    >
      <button
        className={twMerge("w-full", !!readAt ? "opacity-50" : "opacity-100")}
        onClick={onClick}
      >
        <h2 className="text-left text-md font-semibold">{title}</h2>
      </button>
      <div className="flex items-center">
        <button type="button" onClick={() => onDelete(notification)}>
          <Trash className="h-4 w-4 text-red-500" />
        </button>
        <ChevronRight
          className={twMerge(
            "h-5 w-5 mx-3",
            !!readAt ? "opacity-50" : "opacity-100"
          )}
        />
      </div>
    </div>
  );
};

export default Notifications;
