import React, {
  useMemo,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react";

import {
  reset,
  getValidPublicApiToken,
  revokeToken,
  ensureValidPublicApiToken,
} from "../constants/Auth";
import { getKeepSession, saveKeepSession } from "../constants/Storage";
import { CircularProgress } from "@mui/material";

const AppContext = createContext();

const InitialState = {
  currentApiToken: null,
  currentUserId: null,
  isReady: false,
};

export const ACTIONS = {
  INITIAL_STATE: "INITIAL_STATE",
  SIGNIN: "SIGNIN",
  SIGNOUT: "SIGNOUT",
  SIGNOUT_START: "SIGNOUT_START",
  UPDATE_ACCESS_TOKEN: "UPDATE_ACCESS_TOKEN",
};

async function recoverAppState() {
  let token = null;

  const keepSession = await getKeepSession();

  if (keepSession) {
    token = await ensureValidPublicApiToken();
  } else {
    token = await getValidPublicApiToken();
  }

  return {
    currentApiToken: token,
    currentUserId: token.user,
    isReady: true,
  };
}

function appReducer(state, action) {
  switch (action.type) {
    case ACTIONS.INITIAL_STATE: {
      return { ...state, ...action.payload };
    }

    case ACTIONS.SIGNIN: {
      const token = action.payload.token;

      return {
        ...state,
        currentApiToken: token,
        currentUserId: token.user,
      };
    }

    case ACTIONS.SIGNOUT: {
      const token = action.payload.token;
      return {
        ...state,
        currentApiToken: token,
        currentUserId: null,
      };
    }

    case ACTIONS.SIGNOUT_START: {
      return {
        ...state,
      };
    }

    case ACTIONS.UPDATE_ACCESS_TOKEN: {
      const token = action.payload.token;

      return {
        ...state,
        currentApiToken: token,
        currentUserId: token.user,
      };
    }
    default: {
      throw new Error(`Unsupported action type: ${action.type}`);
    }
  }
}

function useApp() {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error(`useApp must be used within a AppProvider`);
  }

  const [state, dispatch] = context;

  async function signIn(token, keepSession = false) {
    saveKeepSession(keepSession);

    dispatch({
      type: "SIGNIN",
      payload: { token },
    });
  }

  async function signOut() {
    dispatch({ type: "SIGNOUT_START" });
    await revokeToken(state.currentApiToken);

    const token = reset();
    dispatch({ type: "SIGNOUT", payload: { token } });
  }

  function updateToken(token) {
    dispatch({
      type: "UPDATE_ACCESS_TOKEN",
      payload: { token },
    });
  }

  return {
    ...state,
    signIn,
    signOut,
    updateToken,
  };
}

function AppProvider(props) {
  const [state, dispatch] = useReducer(appReducer, InitialState);
  const contextValues = useMemo(() => [state, dispatch], [state]);

  useEffect(() => {
    async function loadApp() {
      const payload = await recoverAppState();
      dispatch({ type: ACTIONS.INITIAL_STATE, payload });
    }

    loadApp();
  }, []);

  if (!state.isReady) {
    return (
      <div className="flex flex-1 h-screen items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <AppContext.Provider value={contextValues}>
      {props.children}
    </AppContext.Provider>
  );
}

export { AppProvider, useApp };
