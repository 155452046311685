import {
  useQuery as apolloQuery,
  useMutation as apolloMutation,
  useLazyQuery as apolloLazyQuery,
} from "@apollo/client";

export { gql } from "@apollo/client";

export function useQuery(query, options) {
  return apolloQuery(query, {
    ...options,
    fetchPolicy: "cache-and-network",
  });
}

export function useMutation(mutation, options) {
  return apolloMutation(mutation, options);
}

export function useLazyQuery(query, options) {
  return apolloLazyQuery(query, {
    ...options,
    fetchPolicy: "cache-and-network",
  });
}
