import UserAvatar from "../UserAvatar";

export function AvatarList({ users = [], itemsToShow = 4 }) {
  return (
    <div className="flex">
      {users.slice(0, itemsToShow).map((user) => (
        <UserAvatar key={user.id} user={user} className="-mr-4" />
      ))}
      {users?.length > itemsToShow && (
        <div
          className={
            "flex shrink-0 items-center justify-center border border-white bg-gray-100 w-12 h-12 rounded-full -mr-4"
          }
        >
          <p className="uppercase leading-none font-bold">
            + {users?.length - itemsToShow}
          </p>
        </div>
      )}
    </div>
  );
}
