import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "@mui/material";

import Button from "../components/Button";

import { gql, useMutation, useQuery } from "../contexts/ApiContext";
import { toast } from "react-toastify";
import { useApp } from "../contexts/AppContext";

const GET_CANVA = gql`
  query getCanva($uid: String!) {
    canva(uid: $uid) {
      id
      name
      user {
        id
        name
      }
      users {
        id
        name
      }
    }
  }
`;

const JOIN_CANVA = gql`
  mutation getCanva($input: JoinCanvaMutationInput!) {
    joinCanva(input: $input) {
      success
      canva {
        id
      }
    }
  }
`;

export function ModalInviteContainer() {
  const { currentUserId } = useApp();
  const { uid } = useParams();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(!!uid);

  const { data, loading: loadingCanva } = useQuery(GET_CANVA, {
    variables: {
      uid: `${uid}`,
    },
  });

  const [joinCanvaMutation, { loading }] = useMutation(JOIN_CANVA);

  const canva = data?.canva;
  const owner = canva?.user;
  const users = canva?.users;

  const handleCloseInvite = () => {
    setOpenModal(false);
    navigate("/");
  };

  const handleJoinCanva = async () => {
    const response = await joinCanvaMutation({
      variables: {
        input: {
          canvaUid: uid,
        },
      },
    });

    const success = response?.data?.joinCanva?.success;

    if (!success) {
      toast.error("Não foi possível ingressar ao canvas");
      return;
    }

    navigate(`/canvas/shared-canvas/${uid}`);
    setOpenModal(false);
  };

  useEffect(() => {
    if (users) {
      const alreadyJoined = users?.find((user) => user?.id == currentUserId);
      const isOwner = owner?.id == currentUserId;

      if (alreadyJoined || isOwner) {
        toast.success("Você já está participando deste canvas!");
        setOpenModal(false);
        navigate("/");
        return;
      }
    }
  }, [users, owner, currentUserId]);

  if (loadingCanva) {
    return null;
  }

  return (
    <Modal
      className="modal flex items-center justify-center"
      open={openModal && uid}
      onClose={() => handleCloseInvite()}
      aria-labelledby="modal-modal-title-canva"
      aria-describedby="modal-modal-description-canva"
    >
      <div className="bg-white max-w-sm w-full px-4 py-6 m-2 rounded-md">
        <div>
          <h1 className="text-xl font-semibold text-shark">
            {owner?.name} convidou você para editar o seguinte Licita Canvas:{" "}
            {canva?.name}
          </h1>
          <h3 className="text-shark mt-1 text-lg">Deseja aceitar?</h3>
        </div>
        <div className="flex justify-end gap-2 mt-8">
          <Button type="button" variant="outline" onClick={handleCloseInvite}>
            Cancelar
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={handleJoinCanva}
            loading={loading}
          >
            Aceitar
          </Button>
        </div>
      </div>
    </Modal>
  );
}
