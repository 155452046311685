import { useState } from "react";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { ODsImages } from "./contants";
import { gql, useMutation, useQuery } from "../../contexts/ApiContext";
import { EnvironmentalResponsibilityFragment } from "../../fragments/environmentalResponsibilityFragment";

import ModalSustainableGoals from "../../components/ModalSustainableGoals";
import Button from "../../components/Button";
import { Flex } from "../../components/Flex";
import { ButtonConfetti } from "../../components/ButtonConfetti";
import DragAndDropPostIt from "../../components/DragAndDropPostIt";

export const environmentalResponsibilityCols = [
  {
    name: "Impactos ambientais",
    key: "environmentalImpactCards",
    kind: "environmental_impact",
    tooltip:
      "Realize uma análise dos possíveis impactos ambientais resultantes do projeto. Identifique e descreva os principais impactos, como emissões de gases de efeito estufa, consumo de recursos naturais, geração de resíduos, degradação ambiental, entre outros. É importante ser abrangente e identificar tanto os impactos diretos quanto os indiretos que possam ocorrer ao longo do ciclo de vida do projeto, desde a fase de implantação até a sua desativação.",
  },
  {
    name: "Medidas mitigatórias",
    key: "mitigatingMeasuresCards",
    kind: "mitigating_measures",
    tooltip:
      "Apresente as medidas mitigatórias que serão adotadas para minimizar ou compensar os impactos ambientais identificados. Essas medidas devem ser descritas de forma clara e objetiva, indicando como serão implementadas e quais serão os benefícios esperados. Inclua requisitos de baixo consumo de energia e outros recursos, promovendo a eficiência e a sustentabilidade do projeto. Caso seja aplicável, mencione a logística reversa para o desfazimento e reciclagem de bens e refugos, destacando como será realizada.",
  },
];

const GET_ODSs = gql`
  query getSustainableGoals {
    sustainableGoals {
      id
      title
      fileName
    }
  }
`;

const CREATE_CANVA_CARD = gql`
  mutation createCanvaCard($input: CreateCanvaCardMutationInput!) {
    createCanvaCard(input: $input) {
      errors
      success
      canvaCard {
        canva {
          id
          name
          environmentalResponsibility {
            ...EnvironmentalResponsibilityFragment
          }
        }
      }
    }
  }
  ${EnvironmentalResponsibilityFragment}
`;

const UPDATE_CANVA_CARD = gql`
  mutation updateCard($input: UpdateCardMutationInput!) {
    updateCard(input: $input) {
      errors
      success
      canvaCard {
        canva {
          id
          name
          environmentalResponsibility {
            ...EnvironmentalResponsibilityFragment
          }
        }
      }
    }
  }
  ${EnvironmentalResponsibilityFragment}
`;

const UPDATE_ODDs = gql`
  mutation updateCanva($input: UpdateCanvaMutationInput!) {
    updateCanva(input: $input) {
      errors
      canva {
        id
        sustainableGoals {
          id
          title
          fileName
        }
      }
    }
  }
`;

const DELETE_CANVA_CARD = gql`
  mutation deleteCanvaCard($input: DeleteCardMutationInput!) {
    deleteCard(input: $input) {
      errors
      success
      canva {
        id
        name
        environmentalResponsibility {
          ...EnvironmentalResponsibilityFragment
        }
      }
    }
  }
  ${EnvironmentalResponsibilityFragment}
`;

const StepResponsability = ({ setStep, canva, canEdit }) => {
  const [openModalOdds, setOpenModalOdds] = useState(false);

  const [updateCanvaCard, { loading: loadingUpdate }] =
    useMutation(UPDATE_CANVA_CARD);
  const [createCanvaCard, { loading: loadingCreate }] =
    useMutation(CREATE_CANVA_CARD);
  const [deleteCanvaCard, { loading: loadingDelete }] =
    useMutation(DELETE_CANVA_CARD);
  const [updateOods, { loading: loadingUpdateODss }] = useMutation(UPDATE_ODDs);
  const { data } = useQuery(GET_ODSs);

  const sustainableGoals = data?.sustainableGoals;
  const selectedOdds = canva?.sustainableGoals;

  const handleNextStep = () => {
    setStep(7);
  };

  const confirmSelectOdd = async (data) => {
    const selectedOodIds = data?.map((data) => data?.id);

    const response = await updateOods({
      variables: {
        input: {
          id: canva?.id,
          sustainableGoalIds: selectedOodIds,
        },
      },
    });
    const errors = response?.data?.updateCanva?.erros;
    if (errors) {
      toast.error("Não foi possível adicionar os ODSs");
      return;
    }

    toast.success("ODSs adicionados com sucesso!");
    setOpenModalOdds(false);
  };

  return (
    <>
      <div>
        <div className="bg-dark-green-canva rounded-lg">
          <div className="mt-3 rounded-lg p-2">
            <div className="rounded-lg bg-white px-2 py-3">
              <div className="min-h-[100px]">
                <Tooltip id="help-odds" className="z-50 max-w-xs" />
                <p
                  className="flex items-center justify-between text-base font-bold"
                  data-tooltip-id="help-odds"
                  data-tooltip-content="Selecione quais Objetivos de Desenvolvimento Sustentável (ODS) serão alcançados com a implementação do projeto. Os ODS são metas estabelecidas pela Organização das Nações Unidas (ONU) para promover o desenvolvimento sustentável em diferentes áreas, como pobreza, saúde, igualdade de gênero, energia limpa, entre outros."
                  data-tooltip-float="true"
                >
                  ODSs alcançados pelo projeto
                  <HelpOutlineIcon className="h-4 w-4" />
                </p>
                <div className="mt-4 flex flex-wrap gap-4">
                  {selectedOdds?.map((selectedOod) => {
                    return (
                      <div
                        className="flex flex-col max-w-[120px]"
                        key={selectedOod?.id}
                      >
                        <p className="flex-1 text-center leading-4">
                          {selectedOod?.title}
                        </p>
                        <img
                          className="mt-2 w-full object-contain"
                          src={ODsImages?.[selectedOod?.fileName]}
                          alt={selectedOod?.title}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              {canEdit && (
                <div className="flex mt-3">
                  <Button
                    className="mx-auto"
                    variant="outline"
                    onClick={() => setOpenModalOdds(true)}
                  >
                    Selecionar ODSs
                  </Button>
                </div>
              )}
            </div>
          </div>
          <DragAndDropPostIt
            canvaId={canva?.id}
            colsCards={canva?.environmentalResponsibility}
            cols={environmentalResponsibilityCols}
            createCanvaCard={createCanvaCard}
            updateCanvaCard={updateCanvaCard}
            deleteCanvaCard={deleteCanvaCard}
            disabled={!canEdit}
            loading={loadingUpdate || loadingCreate || loadingDelete}
            containerStyle="grid grid-cols-1 lg:grid-cols-2 mt-0"
          />
        </div>
        {canEdit && (
          <Flex justifyContent="end" className="mt-4">
            <ButtonConfetti title={"Salvar"} onClick={() => handleNextStep()} />
          </Flex>
        )}
      </div>
      <ModalSustainableGoals
        options={sustainableGoals}
        open={openModalOdds}
        onClose={() => setOpenModalOdds(false)}
        onConfirm={confirmSelectOdd}
        loading={loadingUpdateODss}
        initialValues={selectedOdds}
      />
    </>
  );
};

export default StepResponsability;
