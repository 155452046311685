export const saveAccessToken = (token) => {
  localStorage.setItem("accessToken", JSON.stringify(token));
};

export const getAccessToken = () => {
  const token = localStorage.getItem("accessToken");
  return JSON.parse(token);
};

export const clearAccesToken = () => {
  localStorage.removeItem("accessToken");
};

export const savePushToken = (token) => {
  localStorage.setItem("pushToken", token);
};

export const getKeepSession = () => {
  const keepSession = localStorage.getItem("keepSession");
  return JSON.parse(keepSession);
};

export const clearKeepSession = () => {
  localStorage.removeItem("keepSession");
};

export const saveKeepSession = (keepSession) => {
  localStorage.setItem("keepSession", JSON.stringify(keepSession));
};

export const getPushToken = () => {
  const token = localStorage.getItem("pushToken");
  return token;
};

export const clearPushToken = () => {
  localStorage.removeItem("pushToken");
};

export const clearCurrentUser = () => {
  localStorage.removeItem("currentUser");
};

export const clear = localStorage.clear;
