import styled from "styled-components";
import { Trash, UserPlus } from "@styled-icons/fa-solid";

import { useNavigate, useOutletContext } from "react-router-dom";

import { FavoriteIconContainer } from "../../containers/FavoriteIconContainer";
import { ModalExportContainer } from "../../containers/ModalExportContainer";
import Loader from "../../components/Loader";

const UserPlusIcon = styled(UserPlus)`
  cursor: pointer;
  color: #636e7c;
  width: 20px;
  height: 20px;
`;

const TrashIcon = styled(Trash)`
  cursor: pointer;
  color: #e34263;
  width: 20px;
  height: 20px;
`;

export default function MyCanvas() {
  const {
    myCanvas,
    loadingCanvas,
    showOpenDialog: onDelete,
    showOpenShareDialog: onShare,
  } = useOutletContext();

  return (
    <div className="space-y-3 pt-2">
      {myCanvas.map((canva) => (
        <CanvaItem
          key={canva.id}
          canva={canva}
          onDelete={onDelete}
          onShare={onShare}
        />
      ))}
      {loadingCanvas && (
        <div className="mt-12">
          <Loader />
        </div>
      )}
      {!loadingCanvas && !myCanvas?.length && (
        <div>
          <h3 className="text-lg">Você não possui nenhum canva criado.</h3>
        </div>
      )}
    </div>
  );
}

function CanvaItem({ canva, onDelete, onShare }) {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-between rounded-lg border bg-white px-4 py-2 shadow-lg">
      <div
        className="cursor-pointer flex-1"
        onClick={() => navigate(`/canvas/my-canvas/${canva?.uid}`)}
      >
        <p>{canva?.name}</p>
      </div>
      <div className="space-x-2">
        <ModalExportContainer uid={canva?.uid} />
        <FavoriteIconContainer
          isFavorite={canva?.isFavorite}
          uid={canva?.uid}
        />
        <TrashIcon
          className="w-4"
          onClick={(event) => onDelete(event, canva.id)}
        />
        <UserPlusIcon
          onClick={(event) => onShare(event, canva.uid, canva.name)}
        />
      </div>
    </div>
  );
}
