import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import { Alert, Modal } from "@mui/material";

import Button from "../components/Button";
import PrintScreen from "../screens/Canva/PrintScreen";
import PrintScreenList from "../screens/Canva/PrintScreenList";
import ExportCanvaDocButton from "../components/ExportCanvaDocButton";

import { TeamFragment } from "../fragments/teamFragment";
import { CenterPointFragment } from "../fragments/centerPointFragment";
import { MarketingResearchesFragment } from "../fragments/marketingResearchesFragment";
import { FeasibilityAnalysisFragment } from "../fragments/feasibilityAnalysisFragment";
import { SolutionFragment } from "../fragments/solutionFragment";
import { RiskAnalysisFragment } from "../fragments/riskAnalysisFragment";
import { EnvironmentalResponsibilityFragment } from "../fragments/environmentalResponsibilityFragment";
import { ConclusionFragment } from "../fragments/conclusionFragment";

import { gql, useLazyQuery } from "../contexts/ApiContext";
import { Download } from "@styled-icons/fa-solid";

const GET_CANVA = gql`
  query getCanva($uid: String!) {
    canva(uid: $uid) {
      id
      name
      isFavorite
      isPublic
      conclusivePositioning
      ...TeamFragment
      centerPoints {
        ...CenterPointFieldsFragment
      }
      marketingResearches {
        ...MarketingResearchesFragment
      }
      feasibilityAnalysis {
        ...FeasibilityAnalysisFragment
      }
      solution {
        ...SolutionFragment
      }
      riskAnalysis {
        ...RiskAnalysisFragment
      }
      environmentalResponsibility {
        ...EnvironmentalResponsibilityFragment
      }
      sustainableGoals {
        id
        title
        fileName
      }
      conclusion {
        ...ConclusionFragment
      }
    }
  }
  ${TeamFragment}
  ${CenterPointFragment}
  ${MarketingResearchesFragment}
  ${FeasibilityAnalysisFragment}
  ${SolutionFragment}
  ${RiskAnalysisFragment}
  ${EnvironmentalResponsibilityFragment}
  ${ConclusionFragment}
`;

export function ModalExportContainer({ uid }) {
  const [openModal, setOpenModal] = useState(false);
  const [generatingDoc, setGeneratingDoc] = useState(false);

  const [getCanva, { data }] = useLazyQuery(GET_CANVA);

  useEffect(() => {
    if (uid)
      getCanva({
        variables: {
          uid: `${uid}`,
        },
      });
  }, [uid]);

  const canva = data?.canva;

  const handlePrint = async () => {
    setGeneratingDoc(true);
    const data = document.querySelector(`#report`);
    const opt = {
      margin: [20, 20, 20, 20],
    };
    const report = new jsPDF("l", "pt", "a0", true, true);
    report
      .html(data, opt)
      .then(() => {
        report.save(`licita-canvas-${canva.name}.pdf`);
        setGeneratingDoc(false);
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });

    setGeneratingDoc(false);
    setOpenModal(false);
  };

  const handlePrintList = async () => {
    setGeneratingDoc(true);
    const data = document.querySelector(`#report-list`);
    const doc = new jsPDF("p", "pt", "a4", true, true);
    const opt = {
      margin: [20, 20, 20, 20],
      autoPaging: "text",
      html2canvas: {
        allowTaint: true,
        letterRendering: true,
        logging: false,
        scale: 1,
      },
    };

    doc
      .html(data, opt)
      .get("pdf")
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          addPageNumberToPdf(pdf, i, totalPages);
        }
      })
      .save(`licita-canvas-lista-${canva.name}.pdf`);

    setGeneratingDoc(false);
    setOpenModal(false);
  };

  const handleExportDoc = async (exportDoc) => {
    setGeneratingDoc(true);
    await exportDoc();

    setGeneratingDoc(false);
    setOpenModal(false);
  };

  const addPageNumberToPdf = (pdf, currentPage, totalPages) => {
    pdf.setPage(currentPage);
    pdf.setFontSize(10);
    pdf.text(
      `${currentPage}/${totalPages}`,
      pdf.internal.pageSize.getWidth() - 30,
      pdf.internal.pageSize.getHeight() - 20
    );
  };

  return (
    <>
      <Download
        className="w-5 h-5 text-[#636e7c] cursor-pointer"
        onClick={() => setOpenModal(true)}
      />
      <Modal
        className="modal flex items-center justify-center"
        open={openModal && uid}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title-canva"
        aria-describedby="modal-modal-description-canva"
      >
        <div className="bg-white max-w-md w-full px-4 py-6 m-2 rounded-md">
          <h1 className="text-xl font-semibold text-shark">
            Deseja fazer o download?
          </h1>
          <h3 className="text-shark mt-1 text-lg">
            Lembre-se de preencher todos os dados do canvas corretamente
          </h3>

          {generatingDoc && (
            <div className="my-5 flex justify-center gap-2">
              <Alert severity="info">
                Gerando arquivo, aguarde alguns segundos...
              </Alert>
            </div>
          )}
          <div className="flex justify-center gap-2 mt-4">
            <Button
              type="submit"
              variant="secondary"
              onClick={() => handlePrint()}
            >
              CANVAS
            </Button>
            <Button
              type="submit"
              variant="secondary"
              onClick={() => handlePrintList()}
            >
              LISTA
            </Button>
            <ExportCanvaDocButton canva={canva} onClick={handleExportDoc} />
          </div>
          <div className="flex justify-end mt-6">
            <Button
              type="submit"
              variant="outline"
              onClick={() => setOpenModal(false)}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
      <div className="hidden">
        <div id="report">
          <PrintScreen canva={canva} />
        </div>
      </div>
      <div className="hidden">
        <div id="report-list">
          <PrintScreenList canva={canva} />
        </div>
      </div>
    </>
  );
}
