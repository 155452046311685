import { useEffect, useState } from "react";
import { Palette } from "@styled-icons/fa-solid";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Modal } from "@mui/material";

import Button from "../Button";

export const BG_COLORS = [
  "#FDF3BB",
  "#DBEFFD",
  "#EAD3F4",
  "#F7CECD",
  "#EFF6D3",
  "#D0E4D7",
  "#FAE0F2",
];

const formSchema = z.object({
  id: z.string().optional().nullable(),
  content: z.string(),
  bgColor: z.string().optional(),
});

export default function ModalPostIt({
  open,
  onClose,
  handleSubmitPostIt,
  handleUpdatePostIt,
  initialValues,
  loading,
}) {
  const [showSelectColors, setShowSelectColors] = useState(false);
  const prefixedColors = BG_COLORS;

  const defaultValues = {
    bgColor: "#FDF3BB",
    content: null,
  };

  useEffect(() => {
    reset(initialValues || {});
    return () => {
      reset(defaultValues);
    };
  }, [initialValues, open]);

  const { handleSubmit, register, setValue, watch, reset } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const onSubmit = (values) => {
    initialValues ? handleUpdatePostIt(values) : handleSubmitPostIt(values);
  };

  const bgColor = watch("bgColor") || "#FDF3BB";

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal flex items-center justify-center"
    >
      <div
        className={"rounded-xl flex flex-col p-3 max-w-[400px] w-full m-4"}
        style={{ backgroundColor: bgColor }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <textarea
            id="content"
            name="content"
            placeholder="Escreva o conteúdo aqui."
            required
            className="w-full h-[300px] bg-transparent p-1"
            {...register("content")}
          />
          <input
            id="bgColor"
            name="bgColor"
            type="hidden"
            {...register("bgColor")}
          />
          <div className="flex justify-between items-center">
            <Palette
              className="w-6 h-6 shrink-0 text-primary"
              onClick={() => setShowSelectColors(!showSelectColors)}
            />
            {showSelectColors && (
              <div className="flex flex-wrap gap-1 px-2">
                {prefixedColors.map((color) => (
                  <div
                    key={color}
                    className="w-6 h-6 rounded-full"
                    style={{ backgroundColor: color, border: "2px solid" }}
                    onClick={() => {
                      setShowSelectColors(false);
                      setValue("bgColor", color);
                    }}
                  />
                ))}
              </div>
            )}
            <div className="flex gap-1">
              <Button type="button" variant="outline" onClick={onClose}>
                Cancelar
              </Button>
              <Button type="submit" variant="secondary" loading={loading}>
                {initialValues ? "Editar" : "Adicionar"}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}
