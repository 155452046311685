import { Modal } from "@mui/material";
import Button from "../Button";

export default function ModalDeletePostIt({
  open,
  onClose,
  handleConfirmDeletePostIt,
  loading,
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal flex items-center justify-center"
    >
      <div className="bg-white max-w-sm w-full px-4 py-6 m-2 rounded-md">
        <h1 className="text-xl font-semibold text-shark">
          Tem certeza que deseja excluir?
        </h1>
        <div className="flex gap-2 justify-end mt-6">
          <Button type="button" variant="outline" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={handleConfirmDeletePostIt}
            loading={loading}
          >
            Excluir
          </Button>
        </div>
      </div>
    </Modal>
  );
}
