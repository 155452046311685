import { toast } from "react-toastify";

import { gql, useMutation } from "../../contexts/ApiContext";
import { MarketingResearchesFragment } from "../../fragments/marketingResearchesFragment";

import Button from "../../components/Button";
import { Flex } from "../../components/Flex";
import { ButtonConfetti } from "../../components/ButtonConfetti";
import DragAndDropPostIt from "../../components/DragAndDropPostIt";

export const marketingResearchesCols = [
  {
    name: "Solução",
    key: "solutionCards",
    kind: "solution",
    tooltip:
      "Descreva a solução que está sendo pesquisada no mercado. Detalhe as características e funcionalidades do produto ou serviço que atendem às necessidades da administração pública para este caso específico.",
  },
  {
    name: "Referência",
    key: "referenceCards",
    kind: "reference",
    tooltip:
      "Inclua informações sobre referências de mercado, como outros órgãos ou entidades da Administração Pública, ou empresas que contrataram ou que oferecem soluções semelhantes à que está sendo pesquisada. Essas referências ajudam a entender o contexto e as alternativas disponíveis.",
  },
  {
    name: "Fonte",
    key: "sourceCards",
    kind: "source",
    tooltip:
      "Indique as fontes de pesquisa utilizadas para obter informações sobre a solução. Isso pode incluir outras pesquisas de mercado, consultorias especializadas, sites, publicações científicas ou outras fontes confiáveis.",
  },
  {
    name: "Prós",
    key: "prosCards",
    kind: "pros",
    tooltip:
      "Liste as vantagens ou benefícios da solução pesquisada. Esses aspectos positivos podem incluir recursos inovadores, eficiência, qualidade, durabilidade, redução de custos, melhoria de processos, sustentabilidade ou qualquer outra característica que se destaque positivamente.",
  },
  {
    name: "Contras",
    key: "consCards",
    kind: "cons",
    tooltip:
      "Aponte as desvantagens ou limitações da solução identificada na pesquisa de mercado. Isso pode envolver restrições de uso, custos elevados, dependência de tecnologias específicas, questões de compatibilidade, requisitos de manutenção ou outros aspectos negativos relevantes.",
  },
  {
    name: "Riscos",
    key: "risksCards",
    kind: "risks",
    tooltip:
      "Avalie e descreva os riscos associados à adoção da solução em questão. Isso pode incluir riscos operacionais, riscos financeiros, riscos de segurança, riscos de dependência de fornecedores, riscos de integração com sistemas existentes ou quaisquer outros riscos potenciais que precisam ser considerados.",
  },
  {
    name: "Custos",
    key: "costsCards",
    kind: "costs",
    tooltip:
      " Discorra sobre os custos envolvidos na implementação da solução, tanto os custos diretos (como aquisição, implantação e treinamento) quanto os custos indiretos (como manutenção, atualização e suporte). Essa informação é essencial para avaliar a viabilidade econômica da solução pesquisada.",
  },
  {
    name: "Viabilidade",
    key: "viabilityCards",
    kind: "viability",
    tooltip: "Avalie previamente a viabilidade ou não desta solução.",
  },
];

const CREATE_MARKETING_RESEARCH = gql`
  mutation createMarketingResearch(
    $input: CreateMarketingResearchMutationInput!
  ) {
    createMarketingResearch(input: $input) {
      errors
      success
      marketingResearch {
        canva {
          id
          marketingResearches {
            ...MarketingResearchesFragment
          }
        }
      }
    }
  }
  ${MarketingResearchesFragment}
`;

const DELETE_MARKETING_RESEARCH = gql`
  mutation deleteMarketingResearch(
    $input: DeleteMarketingResearchMutationInput!
  ) {
    deleteMarketingResearch(input: $input) {
      errors
      success
      canva {
        id
        marketingResearches {
          ...MarketingResearchesFragment
        }
      }
    }
  }
  ${MarketingResearchesFragment}
`;

const CREATE_CANVA_CARD = gql`
  mutation createCanvaCard($input: CreateCanvaCardMutationInput!) {
    createCanvaCard(input: $input) {
      errors
      success
      canvaCard {
        canva {
          id
          name
          marketingResearches {
            ...MarketingResearchesFragment
          }
        }
      }
    }
  }
  ${MarketingResearchesFragment}
`;

const UPDATE_CANVA_CARD = gql`
  mutation updateCard($input: UpdateCardMutationInput!) {
    updateCard(input: $input) {
      errors
      success
      canvaCard {
        canva {
          id
          name
          marketingResearches {
            ...MarketingResearchesFragment
          }
        }
      }
    }
  }
  ${MarketingResearchesFragment}
`;

const DELETE_CANVA_CARD = gql`
  mutation deleteCanvaCard($input: DeleteCardMutationInput!) {
    deleteCard(input: $input) {
      errors
      success
      canva {
        id
        name
        marketingResearches {
          ...MarketingResearchesFragment
        }
      }
    }
  }
  ${MarketingResearchesFragment}
`;

const StepMarketResearch = ({ setStep, canva, canEdit }) => {
  const [createCanvaCard, { loading: loadingCreate }] =
    useMutation(CREATE_CANVA_CARD);
  const [deleteCanvaCard, { loading: loadingUpdate }] =
    useMutation(DELETE_CANVA_CARD);
  const [updateCanvaCard, { loading: loadingDelete }] =
    useMutation(UPDATE_CANVA_CARD);
  const [createMarketingResearch, { loading }] = useMutation(
    CREATE_MARKETING_RESEARCH
  );
  const [deleteMarketingResearch, { loading: loadingDeleteMarketingResearch }] =
    useMutation(DELETE_MARKETING_RESEARCH);

  const marketingResearches = canva?.marketingResearches;

  const handleAddMarketingResearch = async () => {
    const response = await createMarketingResearch({
      variables: { input: { canvaId: canva?.id } },
    });
    const errors = response?.data?.updateCanvaCard?.erros;

    if (errors) {
      toast.error("Não foi possível adicionar o post-it!");
      return;
    }
    toast.success("Pesquisa Mercadológica adicionada com sucesso!");
  };

  const handleDeleteMarketingResearch = async () => {
    const lastMarketingResearch =
      marketingResearches?.[marketingResearches.length - 1];

    const response = await deleteMarketingResearch({
      variables: { input: { id: lastMarketingResearch?.id } },
    });
    const errors = response?.data?.deleteMarketingResearch?.erros;

    if (errors) {
      toast.error("Não foi possível deletar a pesquisa mercadológica");
      return;
    }
    toast.success("Pesquisa mercadológica deletada com sucesso!");
  };

  return (
    <div>
      <div className="bg-violet-canva mt-3 rounded-lg">
        {!marketingResearches?.length && canEdit && (
          <Button
            type="button"
            variant="secondary"
            onClick={handleAddMarketingResearch}
            loading={loading}
          >
            Adicionar Solução
          </Button>
        )}
        {marketingResearches?.map(
          (marketingResearch, indexMarketingResearch) => {
            return (
              <DragAndDropPostIt
                key={marketingResearch?.id}
                canvaId={canva?.id}
                colsCards={marketingResearch}
                marketingResearchId={marketingResearch?.id}
                cols={marketingResearchesCols.map((col) => ({
                  ...col,
                  name: `${col?.name} ${indexMarketingResearch + 1}`,
                }))}
                createCanvaCard={createCanvaCard}
                updateCanvaCard={updateCanvaCard}
                deleteCanvaCard={deleteCanvaCard}
                disabled={!canEdit}
                loading={loadingUpdate || loadingCreate || loadingDelete}
                containerStyle="grid grid-cols-1 lg:grid-cols-4 mt-0"
              />
            );
          }
        )}
        <div className="p-2 flex place-content-end">
          {canEdit && (
            <div className="space-x-2">
              <Button
                type="button"
                variant="outline"
                onClick={handleDeleteMarketingResearch}
                loading={loadingDeleteMarketingResearch}
              >
                Remover
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={handleAddMarketingResearch}
                loading={loading}
              >
                Adicionar
              </Button>
            </div>
          )}
        </div>
      </div>
      {canEdit && (
        <Flex justifyContent="end" className="mt-4">
          <ButtonConfetti title={"Salvar"} onClick={() => setStep(4)} />
        </Flex>
      )}
    </div>
  );
};

export default StepMarketResearch;
