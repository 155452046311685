import { gql } from "../contexts/ApiContext";

export const CanvaCommentFragment = gql`
  fragment CanvaCommentFragment on CanvaComment {
    id
    user {
      id
      name
      avatar {
        id
        url
      }
    }
    isLiked
    likesCount
    content
    createdAt
  }
`;
