import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { gql, useQuery } from "../../contexts/ApiContext";
import useScrollObserver from "../../hooks/useScrollObserver";

import Loader from "../../components/Loader";
import ModalDeleteTopicContainer from "../../containers/ModalDeleteTopicContainer";
import ListItemContainer from "../../components/ListItemContainer";
import TopicLikeButtonContainer from "../../containers/TopicLikeButtonContainer";
import ViewsCount from "../../components/ViewsCount";

const GET_TOPICS = gql`
  query getTopics($after: String) {
    myTopics(first: 10, after: $after) {
      edges {
        node {
          id
          title
          subtitle
          isLiked
          likesCount
          viewsCount
          user {
            id
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export default function MyTopics() {
  const navigate = useNavigate();
  const observerRef = useRef(null);

  const { data, refetch, fetchMore, loading } = useQuery(GET_TOPICS);

  const myTopics = data?.myTopics?.edges || [];
  const pageInfo = data?.myTopics?.pageInfo;

  const fetchMoreData = () => {
    !!pageInfo?.hasNextPage &&
      fetchMore({
        variables: { after: pageInfo.endCursor },
      });
  };

  useScrollObserver(observerRef, fetchMoreData);

  return (
    <div className="space-y-4 pt-3">
      {myTopics.map((topic) => (
        <TopicItem
          key={topic.node.id}
          topic={topic.node}
          onClick={() => navigate(`/topics/${topic.node.id}`)}
          onDelete={refetch}
        />
      ))}
      <div ref={observerRef} />
      {loading && (
        <div className="mt-12">
          <Loader />
        </div>
      )}
      {!loading && !myTopics?.length && (
        <div>
          <h3 className="text-lg">Você não possui nenhum tópico criado.</h3>
        </div>
      )}
    </div>
  );
}

const TopicItem = ({ topic, onClick, onDelete }) => {
  return (
    <ListItemContainer
      onClick={onClick}
      title={topic.title}
      subtitle={topic.subtitle}
    >
      <div className="flex flex-col md:items-center gap-1 md:gap-2 py-2 md:flex-row">
        <ModalDeleteTopicContainer
          id={topic.id}
          onDelete={onDelete}
          className="flex justify-center mb-1 md:mb-0"
        />
        <ViewsCount viewsCount={topic.viewsCount} />
        <TopicLikeButtonContainer topic={topic} />
      </div>
    </ListItemContainer>
  );
};
