import { useEffect } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { Alert, Modal } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";

import Input from "../Input";
import Button from "../Button";

const formSchema = z.object({
  name: z.string().min(1, { message: "Nome é obrigatorio" }),
});

export function ModalEditCanvaName({
  open,
  onClose,
  onSubmit,
  loading,
  initialValues,
}) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
    },
  });

  useEffect(() => {
    clearErrors();
    reset(initialValues);
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title-canva"
      aria-describedby="modal-modal-description-canva"
      className="modal flex items-center justify-center"
    >
      <div className="bg-white max-w-sm w-full px-4 py-6 m-2 rounded-md">
        {errors?.name?.message && (
          <Alert severity="error">{errors?.name?.message}</Alert>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className="text-shark font-semibold text-2xl">
            Digite o nome do canvas
          </p>
          <div>
            <Input
              name="name"
              placeholder="Ex.: Meu Licita Canvas"
              {...register("name")}
            />
          </div>
          <div className="flex justify-end gap-2">
            <Button type="button" variant="outline" onClick={onClose}>
              Cancelar
            </Button>
            <Button type="submit" variant="secondary" loading={loading}>
              Editar
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
