import { gql, useMutation } from "../../contexts/ApiContext";
import { SolutionFragment } from "../../fragments/solutionFragment";

import { Flex } from "../../components/Flex";
import { ButtonConfetti } from "../../components/ButtonConfetti";
import DragAndDropPostIt from "../../components/DragAndDropPostIt";

export const solutionsCols = [
  {
    name: "Descrição da solução",
    key: "solutionDescriptionCards",
    kind: "solution_description",
    tooltip:
      "Descreva a solução encontrada durante o estudo. Detalhe as características e funcionalidades da solução, incluindo as exigências de manutenção e assistência técnica, quando aplicável. Caso existam contratações correlatas ou interdependentes, mencione-as aqui e justifique adequadamente no tópico específico.",
  },
  {
    name: "Estimativas de valor (com preços unitários referenciais)",
    key: "valueEstimatesCards",
    kind: "value_estimates",
    tooltip:
      "Apresente as estimativas de valor para a solução encontrada e suas respectivas fontes (sugere-se no mínimo 3), incluindo preços unitários referenciais. Apoie essas estimativas com memórias de cálculo e documentos que as sustentem. De acordo com a estratégia da Administração Pública, se necessário, informe no ETP se essas informações são sigilosas.",
  },
  {
    name: "Resultados pretendidos",
    key: "intendedResultsCards",
    kind: "intended_results",
    tooltip:
      "Demonstre os resultados pretendidos com a implementação da solução em termos de economicidade e melhor aproveitamento de recursos humanos, materiais e financeiros. Explique de forma clara os benefícios esperados e como eles contribuirão para o projeto como um todo.",
  },
  {
    name: "Justificativas para parcelamento",
    key: "justificationForInstallmentsCards",
    kind: "justification_for_installments",
    tooltip:
      "Se houver a necessidade de parcelamento da contratação, forneça justificativas claras e objetivas para essa decisão. Explique os motivos que levaram à escolha de parcelar ou não, considerando aspectos como a complexidade da contratação, o volume de serviços ou bens envolvidos e a capacidade de gestão da administração.",
  },
  {
    name: "Providências a serem adotadas",
    key: "measuresToBeAdoptedCards",
    kind: "measures_to_be_adopted",
    tooltip:
      "Descreva as providências que devem ser adotadas pela administração antes da celebração do contrato. Isso pode incluir a capacitação de servidores ou empregados para a fiscalização e gestão contratual, bem como quaisquer outras medidas necessárias para garantir uma execução adequada do contrato.",
  },
  {
    name: "Contratações correlatas/interdependentes",
    key: "interdependentHiringCards",
    kind: "interdependent_hiring",
    tooltip:
      "Caso existam contratações correlatas ou interdependentes com a solução encontrada, mencione-as nesse tópico. Explique como essas contratações se relacionam com o projeto e justifique a sua necessidade.",
  },
];

const CREATE_CANVA_CARD = gql`
  mutation createCanvaCard($input: CreateCanvaCardMutationInput!) {
    createCanvaCard(input: $input) {
      errors
      success
      canvaCard {
        canva {
          id
          name
          solution {
            ...SolutionFragment
          }
        }
      }
    }
  }
  ${SolutionFragment}
`;

const UPDATE_CANVA_CARD = gql`
  mutation updateCard($input: UpdateCardMutationInput!) {
    updateCard(input: $input) {
      errors
      success
      canvaCard {
        canva {
          id
          name
          solution {
            ...SolutionFragment
          }
        }
      }
    }
  }
  ${SolutionFragment}
`;

const DELETE_CANVA_CARD = gql`
  mutation deleteCanvaCard($input: DeleteCardMutationInput!) {
    deleteCard(input: $input) {
      errors
      success
      canva {
        id
        name
        solution {
          ...SolutionFragment
        }
      }
    }
  }
  ${SolutionFragment}
`;

const StepSolution = ({ setStep, canva, canEdit }) => {
  const [updateCanvaCard, { loading: loadingUpdate }] =
    useMutation(UPDATE_CANVA_CARD);
  const [createCanvaCard, { loading: loadingCreate }] =
    useMutation(CREATE_CANVA_CARD);
  const [deleteCanvaCard, { loading: loadingDelete }] =
    useMutation(DELETE_CANVA_CARD);

  const handleNextStep = () => {
    setStep(6);
  };

  return (
    <div>
      <DragAndDropPostIt
        canvaId={canva?.id}
        colsCards={canva?.solution}
        cols={solutionsCols}
        createCanvaCard={createCanvaCard}
        updateCanvaCard={updateCanvaCard}
        deleteCanvaCard={deleteCanvaCard}
        disabled={!canEdit}
        loading={loadingUpdate || loadingCreate || loadingDelete}
        containerStyle="bg-green-canva grid grid-cols-1 lg:grid-cols-3"
      />
      {canEdit && (
        <Flex justifyContent="end" className="mt-4">
          <ButtonConfetti title={"Salvar"} onClick={() => handleNextStep()} />
        </Flex>
      )}
    </div>
  );
};

export default StepSolution;
