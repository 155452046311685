import { Box } from "@mui/material";
import styled from "styled-components";
import { Navbar } from "../../components/Navbar";
import Text from "../../components/Text";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "@styled-icons/fa-solid";

const Wrapper = styled.div`
  background-color: #f1f3f5;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10%;
  ${({ gap }) => {
    if (gap) {
      return `gap: ${gap}px;`;
    }
  }}
  ${({ justifyContent }) => {
    if (justifyContent) {
      return `justify-content: ${justifyContent};`;
    }
  }}
`;

const Main = styled.main`
  background-color: white;
  padding: 20px;
  p.MsoNormal,
  li.MsoNormal,
  div.MsoNormal {
    margin-top: 0in;
    margin-right: 0in;
    margin-bottom: 8pt;
    margin-left: 0in;
    line-height: 107%;
    font-size: 11pt;
    font-family: "Calibri", sans-serif;
  }
  a:link,
  span.MsoHyperlink {
    color: blue;
    text-decoration: underline;
  }
  .MsoChpDefault {
    font-family: "Calibri", sans-serif;
  }
  .MsoPapDefault {
    margin-bottom: 8pt;
    line-height: 107%;
  }
  @page WordSection1 {
    size: 595.3pt 841.9pt;
    margin: 70.85pt 85.05pt 70.85pt 85.05pt;
  }
  div.WordSection1 {
    page: WordSection1;
  }
  /* List Definitions */
  ol {
    margin-bottom: 0in;
  }
  ul {
    margin-bottom: 0in;
  }
`;

const BackwardIcon = styled(ArrowLeft)`
  cursor: pointer;
  color: #636e7c;
  width: 20px;
  height: 20px;
`;

const BackToList = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 40px;
`;

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Navbar />
      <Flex>
        <BackToList onClick={() => navigate(-1)}>
          <BackwardIcon /> Voltar
        </BackToList>
      </Flex>
      <Flex
        style={{ marginTop: "2%", textAlign: "center" }}
        gap="12"
        justifyContent="center"
      >
        <Main>
          <div className="WordSection1">
            <p
              className="MsoNormal"
              style={{
                marginBottom: "0in",
                textAlign: "center",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <b>
                <span
                  lang="PT-BR"
                  style={{
                    fontSize: "31.5pt",
                    fontFamily: "Montserrat",
                    color: "#303133",
                  }}
                >
                  TERMOS DE USO
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "13.5pt",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "18pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10.5pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                Obrigado por dedicar tempo para ler nossos&nbsp;
              </span>
              <b>
                <span
                  lang="PT-BR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: "Montserrat",
                    color: "#303133",
                  }}
                >
                  Termos de Uso
                </span>
              </b>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10.5pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                , ele é muito importante para que você conheça e utilize o que o
                site da Soluções Governamentais Ltda. tem para lhe oferecer.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "13.5pt",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "18pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10.5pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                Este documento e suas condições aplicam-se às interações
                realizadas por você no site (Portal) da Soluções Governamentais
                Ltda., pessoa jurídica devidamente inscrita no CNPJ/MF sob o n°
                49.980.928/0001-79, situada na Rua Bernardino de Campos, 70,
                sala 32, São Paulo – SP, titular da propriedade intelectual
                sobre software, website, aplicativos, conteúdos e demais ativos
                relacionados ao portal.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "13.5pt",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "18pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10.5pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                O usuário deverá ler, certificar-se de haver entendido e aceitar
                todas as condições estabelecidas no termo, assim como nos demais
                documentos a eles incorporados por referência, antes de seu
                cadastro como usuário do site.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "49.5pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                1.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="PT-BR"
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Montserrat",
                    color: "#2e3241",
                  }}
                >
                  DEFINIÇÕES
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                1.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="PT-BR"
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Montserrat",
                    color: "#2e3241",
                  }}
                >
                  Usuário
                </span>
              </b>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                : pessoa física ou jurídica, cadastrada ou não, que faz uso do
                Portal, ferramentas e links ali disponibilizados;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                2.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="PT-BR"
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Montserrat",
                    color: "#2e3241",
                  }}
                >
                  Portal
                </span>
              </b>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                : designa o endereço:&nbsp;
              </span>
              <span lang="PT-BR" style={{ color: "black" }}>
                <a href="http://www.solucoesgovernamentais.com.br">
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Montserrat",
                      border: "none windowtext 1pt",
                      padding: "0in",
                    }}
                  >
                    www.solucoesgovernamentais.com.br
                  </span>
                </a>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                ;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                3.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="PT-BR"
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Montserrat",
                    color: "#2e3241",
                  }}
                >
                  Ferramentas
                </span>
              </b>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                : são produtos/serviços disponibilizados pela Soluções
                Governamentais Ltda. em seu portal, tais como o Licita Canvas (
              </span>
              <span lang="PT-BR" style={{ color: "black" }}>
                <a href="http://www.solucoesgovernamentais.com.br/licitacanvas">
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Montserrat",
                      border: "none windowtext 1pt",
                      padding: "0in",
                    }}
                  >
                    www.solucoesgovernamentais.com.br/licitacanvas
                  </span>
                </a>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                ).
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "49.5pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                2.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="PT-BR"
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Montserrat",
                    color: "#2e3241",
                  }}
                >
                  DO ACEITE
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                1.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                Ao acessar e/ou utilizar os serviços e produtos disponibilizados
                pela Soluções Governamentais Ltda., sejam eles gratuitos ou não,
                você concorda com os Termos de Uso, seus itens e condições.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                2.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                Você apenas estará autorizado a utilizar os produtos e serviços
                do Soluções Governamentais Ltda. se você concorda com todas
                regras e condições estabelecidas nestes Termos de Uso.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                3.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                Se você não concorda com estes Termos de Uso, você não está
                autorizado a acessar ou utilizar os serviços, produtos e
                ferramentas oferecidos em nosso Portal.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "49.5pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                3.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="PT-BR"
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Montserrat",
                    color: "#2e3241",
                  }}
                >
                  DO CADASTRO
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                1.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                Para usufruir das ferramentas, informações, produtos e serviços
                disponibilizados no portal, o usuário precisa realizar um
                cadastro inicial que, após finalizado, permitirá seu acesso
                mediante login (e-mail) e senha
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "135pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                1.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                A ferramenta Licita Canvas (
              </span>
              <span lang="PT-BR" style={{ color: "black" }}>
                <a href="http://www.solucoesgovernamentais.com.br/licitacanvas">
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "Montserrat",
                      border: "none windowtext 1pt",
                      padding: "0in",
                    }}
                  >
                    www.solucoesgovernamentais.com.br/licitacanvas
                  </span>
                </a>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                ) exigirá a realização de um cadastro específico para sua
                utilização.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                2.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                O usuário acessará sua conta através de login (e-mail) e senha e
                compromete-se a não informar a terceiros esses dados,
                responsabilizando-se integralmente pelo uso que deles seja
                feito.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                3.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                A Soluções Governamentais Ltda. não se responsabiliza pela
                correção dos dados pessoais inseridos. Os usuários garantem e
                respondem, em qualquer caso, civil e criminalmente pela
                veracidade, exatidão e autenticidade, dos dados cadastrados.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                4.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                A Soluções Governamentais Ltda. se reserva ao direito de recusar
                qualquer solicitação de cadastro e de suspender um cadastro
                previamente aceito, que esteja em desacordo com as políticas e
                regras do presente termo e suas condições gerais.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                5.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                O usuário se compromete a notificar a Soluções Governamentais
                Ltda. imediatamente, a respeito de qualquer uso não autorizado
                de sua conta, bem como seu acesso não autorizado por terceiros.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "49.5pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                4.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="PT-BR"
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Montserrat",
                    color: "#2e3241",
                  }}
                >
                  DAS FERRAMENTAS
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "135pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                1.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="PT-BR"
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Montserrat",
                    color: "#2e3241",
                  }}
                >
                  Loja SG
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "177.75pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                1.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                É uma ferramenta online que permite a utilização, pelo usuário,
                de produtos e serviços da Soluções Governamentais Ltda.,
                gratuitos ou não, tais como eventos e conteúdos variados.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "177.75pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                2.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                Para os cursos não gratuitos, a Soluções Governamentais Ltda.
                permite o pagamento por meio de cartão de crédito, débito, pix e
                boleto bancário. A escolha pela forma de pagamento é exclusiva
                do usuário.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "177.75pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                3.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                O usuário declara e reconhece que o processamento de pagamentos
                depende do cumprimento de alguns procedimentos, como análise de
                risco e compensação bancária, a depender do meio utilizado. Em
                regra, os pagamentos por cartão de crédito são processados de
                forma mais rápida do que pagamentos realizados por boleto
                bancário.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "135pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                2.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="PT-BR"
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Montserrat",
                    color: "#2e3241",
                  }}
                >
                  Blog SG
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "177.75pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                1.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                É uma ferramenta online com conteúdo em texto e vídeo com temas
                direcionados para usuários que tenham interesse em assuntos
                relacionados à gestão, projetos e compras governamentais.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "177.75pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                2.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                O acesso à ferramenta acontece por meio do login (e-mail) e
                senha previamente cadastrados.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "135pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                3.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="PT-BR"
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Montserrat",
                    color: "#2e3241",
                  }}
                >
                  Licita Canvas
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "177.75pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                1.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                É uma ferramenta online que permite o gerenciamento estratégico,
                desenvolvimento e esboço de modelos de Estudos Técnicos
                Preliminares por meio da interação (informações) do usuário.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "177.75pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                2.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                Ao preencher as informações e criar o seu Licita Canvas, o
                usuário deve definir sobre a sua publicidade, podendo escolher o
                perfil público ou privado.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "220.5pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                1.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                Ao deixar o perfil público, o usuário permitirá o acesso e
                compartilhamento das informações com todos os demais usuários da
                ferramenta, que poderão interagir, coletar informações e
                comentar sobre o seu Licita Canvas.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "220.5pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                2.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                Ao deixar o perfil privado, apenas o usuário terá acesso às
                informações do Licita Canvas criado, não havendo interações,
                trocas de informações ou compartilhamentos.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "177.75pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                3.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                Para acesso à ferramenta, o usuário deverá realizar cadastro
                específico. Eventual cadastro realizado no portal não permite
                acesso ao Licita Canvas.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "49.5pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                5.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="PT-BR"
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Montserrat",
                    color: "#2e3241",
                  }}
                >
                  RESPONSABILIDADES
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                1.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                A Soluções Governamentais Ltda. não se responsabiliza pelas
                interações realizadas entre usuários ou por possíveis danos ou
                prejuízos oriundos dessas interações.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                2.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                Não é permitida a utilização de nenhum dispositivo, software ou
                outro recurso que venha a interferir nas atividades e operações
                do Portal e/ou ferramentas, bem como nos anúncios, descrições,
                contas ou seus bancos de dados. Qualquer intromissão, tentativa
                ou atividade que viole ou contrarie as leis de direito de
                propriedade intelectual e/ou as proibições estipuladas neste
                termo tornarão o responsável passível das ações legais
                pertinentes, bem como das sanções aqui previstas, sendo ainda
                responsável pelas indenizações por eventuais danos causados.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                3.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                A Soluções Governamentais Ltda. não se responsabiliza, em
                nenhuma hipótese, pelo uso de seu Portal ou pelo acesso a links
                nele indicados, bem como por atos praticados por usuário que
                tenham por base informações obtidas no Portal e em links. A
                Soluções Governamentais Ltda. não se responsabiliza nem pelo
                conteúdo nem pelas políticas/práticas de privacidade dos sites
                que apontam para o nosso Portal e daqueles para os quais
                apontamos.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                4.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                A Soluções Governamentais Ltda. se exime de qualquer
                responsabilidade pelas perdas e danos de toda natureza que
                possam advir da falta de disponibilidade ou de continuidade do
                funcionamento do portal e dos serviços; da falibilidade do
                portal e dos serviços, e, em particular, ainda que não de modo
                exclusivo, se exime pelas falhas no acesso às distintas páginas
                web do portal ou àquelas a que se prestam os serviços.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                5.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                A Soluções Governamentais Ltda. se exime de qualquer
                responsabilidade pelas perdas e danos, de toda natureza, que se
                possam atribuir à presença de vírus ou de outros elementos nos
                conteúdos que possam produzir alterações no sistema de
                informática, documentos eletrônicos ou cadastros de usuários.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                6.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                O usuário responderá pelos danos e prejuízos de qualquer
                natureza que a Soluções Governamentais Ltda. possa sofrer em
                consequência do descumprimento de qualquer uma das obrigações a
                que fica submetido por este Termo de Uso.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "49.5pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                6.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="PT-BR"
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Montserrat",
                    color: "#2e3241",
                  }}
                >
                  DIREITOS AUTORAIS E PROPRIEDADE INTELECTUAL
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                1.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                O Portal contém textos, fotografias, imagens e som, que se
                encontram protegidos por direitos autorais ou outros direitos de
                propriedade intelectual. Estes direitos pertencem à Soluções
                Governamentais Ltda. ou foram outorgados sob licença por seus
                titulares, de tal forma que a Soluções Governamentais Ltda. pode
                utilizar este material como parte deste Portal.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                2.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                Ao acessar o Portal da Soluções Governamentais Ltda., o usuário
                declara que irá respeitar todos os direitos de propriedade
                intelectual e industrial, os decorrentes da proteção de marcas
                registradas, bem como de todos os direitos referentes a
                terceiros que porventura estejam, ou estiveram, de alguma forma,
                disponíveis no Portal. O simples acesso ao Portal não confere ao
                usuário qualquer direito de uso dos nomes, títulos, palavras,
                frases, marcas, patentes, obras literárias, artísticas,
                lítero-musicais, entre outras, que nele estejam, ou estiveram,
                disponíveis.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                3.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                Todas as marcas comerciais incluídas neste Portal são de
                propriedade da Soluções Governamentais Ltda. ou lhe foram
                outorgadas sob licença ou autorização por seus titulares para
                sua utilização neste Portal.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                4.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                A reprodução dos conteúdos descritos anteriormente está
                proibida, salvo prévia autorização por escrito da Soluções
                Governamentais Ltda. ou caso se destinem a uso exclusivamente
                pessoal e sem que em nenhuma circunstância o usuário adquira
                qualquer direito sobre os mesmos.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                5.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                É permitido fazer somente o arquivo temporário deste Portal,
                sendo vedada sua utilização para finalidades comerciais,
                publicitárias ou qualquer outra que contrarie a realidade para o
                qual foi concebido, conforme definido neste Termo. Restam
                igualmente proibidas a reprodução, distribuição e divulgação,
                total ou parcial, dos textos, figuras, gráficos que compõem o
                presente Portal, sem prévia e expressa autorização da Soluções
                Governamentais Ltda., sendo permitida somente a impressão de
                cópias para uso e arquivo pessoal, sem que sejam separadas as
                partes que permitam dar o fiel e real entendimento de seu
                conteúdo e objetivo.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                6.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                O usuário assume toda e qualquer responsabilidade, de caráter
                civil e/ou criminal, pela utilização indevida das informações,
                textos, gráficos, marcas, obras, enfim, de todo e qualquer
                direito de propriedade intelectual ou industrial deste Portal.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "49.5pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                7.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  lang="PT-BR"
                  style={{
                    fontSize: "10pt",
                    fontFamily: "Montserrat",
                    color: "#2e3241",
                  }}
                >
                  DISPOSIÇÕES GERAIS
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                1.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                As informações contidas neste Portal e neste termo de uso
                poderão ser atualizadas ou modificadas periodicamente, sem
                qualquer aviso prévio.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                2.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                A Soluções Governamentais Ltda. buscará manter os conteúdos
                contidos no Portal e ferramentas atualizados e completos tanto
                quanto possível. No entanto, não se responsabiliza pela
                utilização, aplicação ou processamento que os usuários possam
                dar às informações aqui obtidas. O usuário é inteiramente
                responsável pela finalidade de uso do dado coletado no Portal.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                3.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                A Soluções Governamentais Ltda. se esforçará para garantir a
                exatidão das informações fornecidas no Portal e nas ferramentas,
                bem como que o seu uso não tenha interrupções nem erros. A
                utilização dos conteúdos disponibilizados, bem como qualquer
                interpretação sobre eles ou inserção em outros contextos são de
                responsabilidade exclusiva do usuário.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                4.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                A Soluções Governamentais Ltda. não pode garantir a
                disponibilidade e continuidade do funcionamento do Portal e dos
                Serviços. Quando isto for razoavelmente possível, a Soluções
                Governamentais Ltda. advertirá previamente as interrupções no
                funcionamento do Portal e dos Serviços. A Soluções
                Governamentais Ltda. tampouco garante a utilidade do Portal e
                dos Serviços para a realização de alguma atividade em
                particular, nem sua infalibilidade e, em particular, ainda que
                não de modo exclusivo, não garante que os usuários possam
                efetivamente utilizar o Portal e os Serviços, acessar as
                distintas páginas web que formam o Portal ou aquelas desde as
                quais se prestam os serviços.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                5.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                A Soluções Governamentais Ltda. não controla nem garante a
                ausência de vírus nem de outros elementos nos conteúdos que
                possam produzir alterações em seu sistema de informática
                (software e hardware) ou nos documentos eletrônicos e arquivos
                armazenados em seu sistema de informática.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                6.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                A Soluções Governamentais Ltda., em razão de violação à
                legislação em vigor ou aos Termos e condições gerais de uso do
                Portal, conforme a situação, poderá, sem prejuízo de outras
                medidas, recusar qualquer solicitação de cadastro, advertir,
                suspender, temporária ou definitivamente, a conta de um usuário.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                7.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                A Soluções Governamentais Ltda. não realiza o monitoramento dos
                comentários e opiniões e tampouco assume obrigação de verificar
                a exatidão ou veracidade dos comentários, não se
                responsabilizando pelo seu conteúdo por qualquer outro meio,
                incluindo e-mail.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: "92.25pt",
                textAlign: "justify",
                textIndent: "-0.25in",
                lineHeight: "22.5pt",
                background: "white",
              }}
            >
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                8.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="PT-BR"
                style={{
                  fontSize: "10pt",
                  fontFamily: "Montserrat",
                  color: "#2e3241",
                }}
              >
                Todos os itens deste termo são regidos pelas leis vigentes na
                República Federativa do Brasil. Para todos os assuntos
                referentes à interpretação, ao cumprimento ou qualquer outro
                questionamento relacionado a este termo, as partes concordam em
                se submeter ao Foro da cidade de São Paulo / SP.
              </span>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
              <span lang="PT-BR">&nbsp;</span>
            </p>
          </div>
        </Main>
      </Flex>
    </Wrapper>
  );
};
export default PrivacyPolicy;
