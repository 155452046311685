import styled, { css } from "styled-components";
import { flexbox, layout, space, system } from "styled-system";

const gap = system({ gap: { property: "gap", scale: "space" } });

const Centered = css`
  align-items: center;
  justify-content: center;
`;

export const Flex = styled.div`
  display: flex;
  ${(props) => (props.centered ? Centered : css``)}
  ${flexbox}
  ${layout}
  ${space}
  ${gap}
`;
