import React, { useEffect } from "react";
import { getAllStates, getStateCities } from "easy-location-br";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Checkbox } from "@mui/material";
import { z } from "zod";

import { gql, useMutation } from "../../contexts/ApiContext";

import { Navbar } from "../../components/Navbar";
import { Footer } from "../../components/Footer";
import Button from "../../components/Button";
import Select from "../../components/Select";
import Input from "../../components/Input";
import licitaCanvaPDF from "../../assets/licita_canvas.pdf";

import RegisterImagem from "../../assets/register_lead_image.png";

const CREATE_LEAD_MUTATION = gql`
  mutation createLead($input: CreateLeadMutationInput!) {
    createLead(input: $input) {
      errors
    }
  }
`;

const formSchema = z.object({
  name: z.string().min(3, { message: "Nome é obrigatório" }),
  email: z.string().email({ message: "Email é obrigatório" }),
  phone: z.string().min(1, { message: "Telefone é obrigatório" }),
  governmentEntity: z
    .string()
    .min(2, { message: "Órgão/Entidade Governamental é obrigatório" }),
  state: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  policy: z.boolean().refine(
    (value) => value,
    (_value) => ({
      message:
        "Aceite nos termos de uso e política de privacidade é obrigatório",
    })
  ),
});

const RegisterLead = () => {
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);

  const [createLead, { loading }] = useMutation(CREATE_LEAD_MUTATION);

  function downloadFile(url, filename) {
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const onSubmit = async (values) => {
    delete values.policy;

    const result = await createLead({
      variables: {
        input: values,
      },
    });

    const { errors } = result?.data?.createLead || {};

    if (errors) {
      toast.error(errors[0]);
      return;
    }

    toast.success("Cadastro realizado com sucesso!");
    downloadFile(licitaCanvaPDF, "licita_canvas.pdf");

    reset();
  };

  const getCitiesByState = (state) => {
    setCities(getStateCities(state));
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    reset,
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      governmentEntity: "",
      state: "",
      city: "",
      policy: false,
    },
  });

  const stateField = watch("state");

  const messageInfo = Object.keys(errors)?.map((key) => errors?.[key]?.message);

  useEffect(() => {
    if (messageInfo.length) {
      // eslint-disable-next-line no-restricted-globals
      scrollTo(0, 0);
    }
  }, [messageInfo]);

  useEffect(() => {
    getCitiesByState(stateField);
  }, [stateField]);

  useEffect(() => {
    setStates(getAllStates());
    reset();
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="flex-1 flex flex-col px-6 py-6 lg:flex-row lg:items-center">
        <div className="flex-1 flex justify-center">
          <div className="flex-1 bg-lavender-canva rounded-3xl px-5 py-6 max-w-md">
            <h3 className="text-xl font-extrabold">Cadastro</h3>
            <div className="my-4">
              {!!messageInfo?.length && (
                <Alert severity="error">{messageInfo?.[0]}</Alert>
              )}
            </div>
            <div className="mt-4">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  name="name"
                  label="Nome"
                  className="rounded-3xl px-4"
                  {...register("name")}
                />
                <Input
                  name="email"
                  label="E-mail"
                  type="email"
                  className="rounded-3xl px-4"
                  {...register("email")}
                />
                <Input
                  label="Telefone"
                  name="phone"
                  mask="(99) 99999-9999"
                  className="rounded-3xl px-4"
                  {...register("phone")}
                />
                <Input
                  name="governmentEntity"
                  label="Órgão/Entidade Governamental"
                  className="rounded-3xl px-4"
                  {...register("governmentEntity")}
                />
                <div className="flex gap-6">
                  <Select
                    label="Estado"
                    name="state"
                    className="rounded-3xl px-4"
                    option={states.map((state) => ({
                      value: state.id,
                      label: state.name,
                    }))}
                    {...register("state")}
                  />
                  <Select
                    label="Cidade"
                    name="city"
                    className="rounded-3xl px-4"
                    option={cities.map((city) => ({
                      value: city.name,
                      label: city.name,
                    }))}
                    {...register("city")}
                  />
                </div>
                <div className="flex mb-6">
                  <Checkbox
                    className="mt-2 text-purple-heart"
                    {...register("policy")}
                  ></Checkbox>
                  <p className="text-xs text-center font-medium mt-4">
                    Ao cadastrar, estou de acordo com os{" "}
                    <a
                      className="underline text-purple-heart"
                      href="https://www.solucoesgovernamentais.com.br/termos-de-uso"
                      target="_blank"
                      rel="noreferrer"
                    >
                      termos de uso
                    </a>{" "}
                    do software e{" "}
                    <a
                      className="underline text-purple-heart"
                      href="https://www.solucoesgovernamentais.com.br/privacidade"
                      target="_blank"
                      rel="noreferrer"
                    >
                      política de privacidade
                    </a>{" "}
                    .
                  </p>
                </div>
                <div className="w-fit mx-auto">
                  <Button
                    variant="secondary"
                    type="submit"
                    disabled={loading}
                    loading={loading}
                  >
                    Salvar
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="flex-1 self-center shrink-0">
          <img alt="postits" src={RegisterImagem} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RegisterLead;
