import { useNavigate, useOutletContext } from "react-router-dom";

import { FavoriteIconContainer } from "../../containers/FavoriteIconContainer";
import { ModalExportContainer } from "../../containers/ModalExportContainer";
import Loader from "../../components/Loader";

export default function FavoriteCanvas() {
  const { favoriteCanvas, loadingCanvas } = useOutletContext();
  return (
    <div className="space-y-3 pt-2">
      {favoriteCanvas.map((canva) => (
        <CanvaItem canva={canva} />
      ))}
      {loadingCanvas && (
        <div className="mt-12">
          <Loader />
        </div>
      )}
      {!loadingCanvas && !favoriteCanvas?.length && (
        <div>
          <h3 className="text-lg">Você não possui nenhum canva favoritado.</h3>
        </div>
      )}
    </div>
  );
}

function CanvaItem({ canva }) {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-between rounded-lg border bg-white px-4 py-2 shadow-lg">
      <div
        className="cursor-pointer flex-1"
        onClick={() => navigate(`/canvas/shared-canvas/${canva?.uid}`)}
      >
        <p>{canva?.name}</p>
      </div>
      <div className="space-x-2">
        <ModalExportContainer uid={canva?.uid} />
        <FavoriteIconContainer
          isFavorite={canva?.isFavorite}
          uid={canva?.uid}
        />
      </div>
    </div>
  );
}
