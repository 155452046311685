import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { gql, useMutation } from "../../contexts/ApiContext";

import Sidebar from "../../components/Sidebar";
import OutletTabs from "../../components/OutletTabs";
import Button from "../../components/Button";
import TopicForm from "../../components/TopicForm";

const tabs = [
  { name: "Meus Tópicos", path: "/topics/my-topics" },
  {
    name: "Todos os Tópicos",
    path: "/topics/all-topics",
  },
];

const CREATE_TOPIC = gql`
  mutation createTopic($input: CreateTopicMutationInput!) {
    createTopic(input: $input) {
      success
      errors
      topic {
        id
      }
    }
  }
`;

export default function Topics() {
  const navigate = useNavigate();

  const [createTopicMutation, { loading: loadingCreateTopic }] =
    useMutation(CREATE_TOPIC);

  const onSubmit = async (data) => {
    const result = await createTopicMutation({
      variables: {
        input: data,
      },
    });
    const topicId = result?.data?.createTopic?.topic?.id;
    const errors = result?.data?.createTopic?.errors;

    if (errors) {
      toast.error("Não foi possivel criar o tópico, tente mais tarde!");
      return;
    }

    navigate(`/topics/${topicId}`);
  };

  return (
    <>
      <Sidebar>
        <div className="flex-1 p-6">
          <div className="flex sm:items-center flex-col sm:flex-row justify-between mb-6">
            <h2 className="text-3xl mb-4 sm:mb-0 font-semibold mt-2.5 text-shark-500">
              Tópicos
            </h2>
            <TopicForm
              title="Criar Novo Tópico"
              OpenFormButton={(props) => (
                <Button variant="secondary" {...props}>
                  Criar Novo Tópico
                </Button>
              )}
              onSubmit={onSubmit}
              loadingSubmit={loadingCreateTopic}
            />
          </div>
          <OutletTabs tabs={tabs} />
        </div>
      </Sidebar>
    </>
  );
}
