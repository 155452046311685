import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../screens/Login";
import Register from "../screens/Register";
import ForgetPassword from "../screens/ForgetPassword";
import RecoveryPassword from "../screens/RecoveryPassword";
import PrivacyPolicy from "../screens/PrivacyPolicy";
import { Root } from "../screens/Root";
import RegisterLead from "../screens/RegisterLead";

export function PublicRoutes() {
  return (
    <Routes>
      <Route path="/*" element={<Navigate to="/login" />} />
      <Route path="/" element={<Root />} />
      <Route path="/login" element={<Login />} />
      <Route path="/invite/:uuid" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/licita-canvas-impresso" element={<RegisterLead />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/recovery-password" element={<RecoveryPassword />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  );
}
