import React from "react";
import { useNavigate } from "react-router-dom";

import AuthContainer from "../../components/auth/AuthContainer";
import { Navbar } from "../../components/Navbar";
import { Footer } from "../../components/Footer";

import LoginImagem from "../../assets/login_image.png";
import Button from "../../components/Button";

const Login = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/register");
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="flex-1 flex flex-col items-center max-w-6xl w-full mx-auto px-6 py-6 lg:flex-row">
        <div className="flex-1 shrink-0">
          <div className="max-w-sm">
            <div className="flex items-center justify-center">
              <h1 className="text-xl">Ainda não tem uma conta?</h1>
              <Button variant="tertiary" onClick={handleClick}>
                Criar conta
              </Button>
            </div>
            <div className="bg-lavender-canva rounded-3xl px-5 py-6  mt-6">
              <h3 className="text-xl font-extrabold">Olá, acesse sua conta</h3>
              <div className="mt-4">
                <AuthContainer />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 shrink-0">
          <div className="mx-auto">
            <img alt="postits" src={LoginImagem} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
