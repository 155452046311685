import styled from "@emotion/styled";
import { Alert, Modal } from "@mui/material";
import { getAllStates, getStateCities } from "easy-location-br";
import { useMutation } from "@apollo/client";
import { z } from "zod";
import { useForm } from "react-hook-form";

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import { Flex } from "../../components/Flex";
import Input from "../../components/Input";
import { Navbar } from "../../components/Navbar";
import Select from "../../components/Select";
import { ArrowLeft } from "@styled-icons/fa-solid";
import { gql, useQuery } from "../../contexts/ApiContext";

import { zodResolver } from "@hookform/resolvers/zod";
import { Avatar } from "../../components/Avatar";
import { useApp } from "../../contexts/AppContext";

const Form = styled.form`
  width: 100%;
`;

const TitleInfo = styled.h2`
  color: #212429;
  font-family: "Nunito Sans-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 0;
  letter-spacing: -0.2px;
  line-height: 30px;
`;

const BackwardIcon = styled(ArrowLeft)`
  cursor: pointer;
  color: #636e7c;
  width: 20px;
  height: 20px;
`;

const BackToList = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  display: flex;
  margin: auto;
  margin-left: 35%;
  margin-top: 40px;
`;

const GET_ME = gql`
  query getMe {
    me {
      name
      city
      email
      phone
      state
      position
      specialty
      governmentEntity
      avatar {
        id
        url
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserMutationInput!) {
    updateUser(input: $input) {
      errors
      me {
        id
        avatar {
          id
          url
        }
      }
    }
  }
`;

const DELETE_ACCOUNT = gql`
  mutation deleteMyAccount($input: DeleteMyAccountMutationInput!) {
    deleteMyAccount(input: $input) {
      errors
    }
  }
`;

const formSchema = z
  .object({
    name: z.string().min(3, { message: "Nome é obrigatório" }),
    phone: z.string().min(1, { message: "Telefone é obrigatorio" }),
    governmentEntity: z
      .string()
      .min(2, { message: "Órgão/Entidade Governamental é obrigatório" }),
    position: z.string().optional().nullable(),
    specialty: z.string().optional().nullable(),
    state: z.string().optional().nullable(),
    city: z.string().optional().nullable(),
    currentPassword: z.string().optional().nullable(),
    password: z.string().optional().nullable(),
    passwordConfirmation: z.string().optional().nullable(),
    avatar: z.any(),
  })
  .refine(
    (data) =>
      !data.currentPassword || !!(data.currentPassword && data.password),
    {
      message: "Nova senha é obrigatoria.",
    }
  )
  .refine((data) => data.password === data.passwordConfirmation, {
    message: "Senha e confirmação de senha estão diferentes.",
    path: ["confirm"], // path of error
  });

const Profile = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const navigate = useNavigate();
  const { signOut } = useApp();

  const { data: dataUser } = useQuery(GET_ME);
  const [updateUser, { loading }] = useMutation(UPDATE_USER);
  const [deleteAccount, { loading: loadingDelete }] =
    useMutation(DELETE_ACCOUNT);

  const user = dataUser?.me;
  const userAvatar = user?.avatar;

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      currentPassword: null,
      password: null,
      passwordConfirmation: null,
      ...user,
    },
  });

  const openDeleteModal = () => setIsDeleteModalOpen(true);

  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  const handleUpdate = async (values) => {
    const body = Object.fromEntries(
      Object.entries(values).filter(([_, v]) => v != null && v !== "")
    );

    const result = await updateUser({
      variables: { input: { ...body } },
    });
    const errors = result?.data?.updateUser?.errors;

    if (errors) {
      toast.error("Não foi possivel atualizar o perfil!");
      return;
    }

    toast.success("Perfil atualizado!");
  };

  const handleConfirmDelete = async (password) => {
    const result = await deleteAccount({
      variables: { input: { password } },
    });
    const errors = result?.data?.deleteMyAccount?.errors;

    if (errors) {
      toast.error(errors[0]);
      return;
    }

    await signOut();
    closeDeleteModal();
  };

  const getCitiesByState = (state) => {
    setCities(getStateCities(state));
  };

  const phoneValue = watch("phone");
  const avatar = watch("avatar");
  const stateValue = watch("state");

  useEffect(() => {
    getCitiesByState(stateValue);
  }, [stateValue]);

  useEffect(() => {
    if (user) {
      reset({
        ...user,
        currentPassword: null,
        password: null,
        passwordConfirmation: null,
        avatar: null,
      });
      getCitiesByState(user.state);
    }
  }, [user]);

  useEffect(() => {
    setStates(getAllStates());
  }, []);

  const messageInfo = Object.keys(errors)?.map((key) => errors?.[key]?.message);

  useEffect(() => {
    if (messageInfo.length) {
      // eslint-disable-next-line no-restricted-globals
      scrollTo(0, 0);
    }
  }, [messageInfo]);

  return (
    <div className="bg-white">
      <Navbar />
      <Flex flexDirection="column">
        <BackToList onClick={() => navigate("/")}>
          <BackwardIcon /> Voltar para lista
        </BackToList>
        <div className="bg-lavender-canva rounded-3xl px-5 py-6 mt-6 mx-4 lg:mx-auto lg:max-w-lg lg:w-full">
          <TitleInfo>Perfil</TitleInfo>
          <>
            <div className="my-4">
              {!!messageInfo?.length && (
                <Alert severity="error">{messageInfo?.[0]}</Alert>
              )}
            </div>
            <Form onSubmit={handleSubmit(handleUpdate)}>
              <div className="w-fit mx-auto">
                <Avatar
                  source={avatar || userAvatar}
                  onChange={(field) => {
                    setValue("avatar", field);
                  }}
                />
              </div>
              <Input
                id="name"
                name="name"
                label="Nome"
                className="rounded-3xl px-4"
                {...register("name")}
              />
              <Input
                name="email"
                label="E-mail"
                disabled
                className="rounded-3xl px-4"
                {...register("email")}
              />
              <Input
                label="Telefone"
                name="phone"
                mask="(99) 99999-9999"
                value={phoneValue}
                className="rounded-3xl px-4"
                onChange={(e) => setValue("phone", e.target.value)}
              />
              <Input
                name="position"
                label="Cargo"
                className="rounded-3xl px-4"
                {...register("position")}
              />
              <Input
                id="specialty"
                name="specialty"
                label="Especialidade"
                className="rounded-3xl px-4"
                {...register("specialty")}
              />
              <Input
                id="governmentEntity"
                name="governmentEntity"
                label="Órgão/ Entidade Governamental"
                className="rounded-3xl px-4"
                {...register("governmentEntity")}
              />
              <Flex gap={12}>
                <Select
                  label="Estado"
                  id="state"
                  name="state"
                  className="rounded-3xl px-4"
                  option={states.map((state) => ({
                    value: state.id,
                    label: state.name,
                  }))}
                  {...register("state")}
                />
                <Select
                  label="Cidade"
                  id="city"
                  name="city"
                  className="rounded-3xl px-4"
                  option={cities.map((city) => ({
                    value: city.name,
                    label: city.name,
                  }))}
                  {...register("city")}
                />
              </Flex>
              <Input
                id="password"
                name="password"
                label="Senha atual"
                type="password"
                className="rounded-3xl px-4"
                {...register("currentPassword")}
              />
              <Input
                id="password"
                name="password"
                label="Nova senha"
                type="password"
                className="rounded-3xl px-4"
                {...register("password")}
              />
              <Input
                id="password"
                name="password"
                label="Confirmação da nova senha"
                type="password"
                className="rounded-3xl px-4"
                aria-invalid={errors?.confirm ? "true" : "false"}
                {...register("passwordConfirmation")}
              />
              <div className="w-fit mx-auto">
                <Button variant="secondary" type="submit" loading={loading}>
                  Atualizar dados
                </Button>
              </div>
            </Form>
          </>
          <div className="w-fit mx-auto mt-4">
            <Button variant="danger" onClick={openDeleteModal}>
              Deletar conta
            </Button>
          </div>
        </div>
      </Flex>
      <ModalDeleteAccount
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleConfirmDelete}
        loading={loadingDelete}
      />
    </div>
  );
};

const ModalDeleteAccount = ({ open, onClose, onConfirm, loading }) => {
  const handleConfirm = (event) => {
    event.preventDefault();
    const password = event.target.password.value;
    onConfirm(password);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="modal flex items-center justify-center"
    >
      <div className="bg-white max-w-sm w-full px-4 py-6 m-2 rounded-md">
        <p className="text-shark font-semibold text-2xl">
          Deseja excluir sua conta?
        </p>
        <p className="text-shark text-sm font-normal mb-5 mt-2">
          Se optar por excluir, lembre-se de que não será possível recuperar
          suas informações
        </p>
        <form onSubmit={handleConfirm}>
          <Input
            id="password"
            name="password"
            label="Insira sua senha"
            type="password"
            className="rounded-3xl px-4 mb-2"
            required
          />
          <div className="flex justify-end gap-2">
            <Button type="reset" variant="outline" onClick={onClose}>
              Cancelar
            </Button>
            <Button type="submit" variant="danger" loading={loading}>
              Excluir
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default Profile;
