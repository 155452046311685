import { Alert, Box } from "@mui/material";
import { confirmPasswordReset } from "firebase/auth";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { Navbar } from "../../components/Navbar";
import Text from "../../components/Text";
import Center from "../../components/utils/Center";
import { auth } from "../../config/firebase";

const Wrapper = styled.div`
  background-color: #f1f3f5;

  .wapper {
    margin-top: 2%;
    text-align: center;

    @media (max-width: 768px) {
      margin-top: 10%;
      margin-bottom: -10%;
      flex-direction: column;
    }
  }
`;

const Container = styled(Box)`
  background-color: white;
  margin-top: -100px;
  padding: 2%;
  width: 30%;
  ${({ marginTop }) => {
    if (marginTop) {
      return `margin: ${marginTop}px;`;
    }
  }}
  ${({ padding }) => {
    if (padding) {
      return `padding: ${padding};`;
    }
  }}
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const Title = styled.h1`
  font-family: "Nunito Sans-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const TitleInfo = styled.h2`
  color: #212429;
  font-family: "Nunito Sans-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 0;
  letter-spacing: -0.2px;
  line-height: 30px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10%;
  ${({ gap }) => {
    if (gap) {
      return `gap: ${gap}px;`;
    }
  }}
  ${({ justifyContent }) => {
    if (justifyContent) {
      return `justify-content: ${justifyContent};`;
    }
  }}
`;

const Form = styled.form`
  width: 100%;
`;

const LinkText = styled(Text)`
  text-align: center;
  margin: 10px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const RecoveryPassword = (props) => {
  let query = useQuery();

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    if (!query.get("oobCode")) {
      navigate("/login");
    }
  }, [query]);

  const recoveryPassword = (event) => {
    event.preventDefault();
    const { password } = data;

    confirmPasswordReset(auth, query.get("oobCode"), password)
      .then(() => {
        setSuccessMessage("Senha alterada com sucesso!");
        setTimeout(() => {
          setDisabled(false);
          navigate("/login");
        }, 3000);
      })
      .catch((error) => {
        setErrorMessage(error.code + ": " + error.message);
        setDisabled(false);
      });
  };

  const handleChange = (event) => {
    setErrorMessage("");
    setData({ ...data, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Wrapper>
        <Navbar />
        <Flex className="wapper" gap="12" justifyContent="center">
          <Title>Recuperação de senha</Title>
        </Flex>
        <Center>
          <Container
            display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
            boxShadow={2}
            borderRadius={1}
          >
            <TitleInfo>Olá, digite sua nova senha</TitleInfo>
            {errorMessage && (
              <Alert severity="error">
                Houve um erro ao tentar alterar a senha, favor verificar os
                dados!
              </Alert>
            )}

            {successMessage && (
              <Alert severity="success">{successMessage}</Alert>
            )}

            <Form onSubmit={recoveryPassword}>
              <Input
                type="password"
                name="password"
                label="Senha"
                onChange={handleChange}
              />
              <Button type="submit" disabled={disabled}>
                Redefinir senha
              </Button>
              <LinkText variant="outline" onClick={() => navigate("/login")}>
                Ir para login
              </LinkText>
            </Form>
          </Container>
        </Center>
      </Wrapper>
    </>
  );
};

export default RecoveryPassword;
