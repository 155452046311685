import { gql, useMutation } from "../../contexts/ApiContext";
import { RiskAnalysisFragment } from "../../fragments/riskAnalysisFragment";

import { ButtonConfetti } from "../../components/ButtonConfetti";
import DragAndDropPostIt from "../../components/DragAndDropPostIt";

export const riskAnalysisCols = [
  {
    name: "Riscos envolvidos",
    key: "risksInvolvedCards",
    kind: "risks_involved",
    tooltip:
      "Realize uma análise abrangente para identificar e descrever os principais riscos que podem afetar a solução. Utilize uma abordagem sistemática e considere diferentes áreas, como técnica, operacional, financeira e jurídica. Liste de forma clara e objetiva os riscos, priorizando aqueles com maior probabilidade de ocorrência e impacto.",
  },
  {
    name: "Medidas mitigatórias",
    key: "riskMitigatingMeasuresCards",
    kind: "risk_mitigating_measures",
    tooltip:
      "Apresente as medidas mitigatórias que serão adotadas para minimizar ou eliminar os riscos identificados. Descreva detalhadamente as ações preventivas e os planos de contingência que serão implementados. Defina também os controles e monitoramentos que serão realizados. Certifique-se de que as medidas sejam realistas, viáveis e proporcionais à gravidade dos riscos.",
  },
];

const CREATE_CANVA_CARD = gql`
  mutation createCanvaCard($input: CreateCanvaCardMutationInput!) {
    createCanvaCard(input: $input) {
      errors
      success
      canvaCard {
        canva {
          id
          name
          riskAnalysis {
            ...RiskAnalysisFragment
          }
        }
      }
    }
  }
  ${RiskAnalysisFragment}
`;

const UPDATE_CANVA_CARD = gql`
  mutation updateCard($input: UpdateCardMutationInput!) {
    updateCard(input: $input) {
      errors
      success
      canvaCard {
        canva {
          id
          name
          riskAnalysis {
            ...RiskAnalysisFragment
          }
        }
      }
    }
  }
  ${RiskAnalysisFragment}
`;

const DELETE_CANVA_CARD = gql`
  mutation deleteCanvaCard($input: DeleteCardMutationInput!) {
    deleteCard(input: $input) {
      errors
      success
      canva {
        id
        name
        riskAnalysis {
          ...RiskAnalysisFragment
        }
      }
    }
  }
  ${RiskAnalysisFragment}
`;

const StepProblems = ({ setStep, canva, canEdit }) => {
  const [updateCanvaCard, { loading: loadingUpdate }] =
    useMutation(UPDATE_CANVA_CARD);
  const [createCanvaCard, { loading: loadingCreate }] =
    useMutation(CREATE_CANVA_CARD);
  const [deleteCanvaCard, { loading: loadingDelete }] =
    useMutation(DELETE_CANVA_CARD);

  const handleNextStep = () => {
    setStep(7);
  };

  return (
    <div>
      <DragAndDropPostIt
        canvaId={canva?.id}
        colsCards={canva?.riskAnalysis}
        cols={riskAnalysisCols}
        createCanvaCard={createCanvaCard}
        updateCanvaCard={updateCanvaCard}
        deleteCanvaCard={deleteCanvaCard}
        disabled={!canEdit}
        loading={loadingUpdate || loadingCreate || loadingDelete}
        containerStyle="bg-pink-canva grid grid-cols-1 lg:grid-cols-2"
      />

      {canEdit && (
        <div className="flex justify-end mt-4">
          <ButtonConfetti title={"Salvar"} onClick={() => handleNextStep()} />
        </div>
      )}
    </div>
  );
};

export default StepProblems;
