import React from "react";
import { twMerge } from "tailwind-merge";
import { CircularProgress } from "@mui/material";

const Button = ({
  size,
  disabled,
  variant = "primary",
  children,
  onClick,
  loading = false,
  className,
  ...rest
}) => {
  const styles = {
    primary:
      "bg-primary text-white px-8 py-2 rounded-md font-extrabold w-fit disabled:opacity-50 disabled:pointer-events-none",
    secondary:
      "border text-white bg-purple-heart px-8 py-2 rounded-3xl font-extrabold w-fit disabled:bg-purple-heart/40 disabled:pointer-events-none",
    tertiary: "ml-2 bg-pink-canva px-6 py-2 rounded-full font-bold",
    outline:
      "border text-purple-heart px-8 py-2 rounded-3xl border-purple-heart font-extrabold w-fit",
    danger: "bg-red-500 text-white px-8 py-2 rounded-3xl font-extrabold w-fit",
  };

  return (
    <button
      size={size}
      disabled={disabled}
      onClick={onClick}
      className={twMerge(styles?.[variant], className)}
      {...rest}
    >
      {loading ? <CircularProgress size={15} color="inherit" /> : children}
    </button>
  );
};

export default Button;
