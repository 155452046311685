import { gql } from "../contexts/ApiContext";
import { CanvaCardFragment } from "./canvaCardFragment";

export const MarketingResearchesFragment = gql`
  fragment MarketingResearchesFragment on MarketingResearch {
    id
    order
    consCards {
      ...CanvaCardFragment
    }
    costsCards {
      ...CanvaCardFragment
    }
    prosCards {
      ...CanvaCardFragment
    }
    referenceCards {
      ...CanvaCardFragment
    }
    risksCards {
      ...CanvaCardFragment
    }
    solutionCards {
      ...CanvaCardFragment
    }
    sourceCards {
      ...CanvaCardFragment
    }
    viabilityCards {
      ...CanvaCardFragment
    }
  }
  ${CanvaCardFragment}
`;
