import { useEffect, useState } from "react";
import styled from "styled-components";

import { gql, useMutation } from "../../contexts/ApiContext";
import { CenterPointFragment } from "../../fragments/centerPointFragment";

import { Flex } from "../../components/Flex";
import { ButtonConfetti } from "../../components/ButtonConfetti";
import DragAndDropPostIt from "../../components/DragAndDropPostIt";

const Title = styled.h1`
  font-size: 16px;
  padding: 10px;
  font-weight: 600;
  margin-top: 8px;
`;

export const centerPointsCols = [
  {
    name: "Problema a ser resolvido",
    key: "problemToBeSolvedCards",
    kind: "problem_to_be_solved",
    tooltip: "Descreva o problema que este projeto visa solucionar.",
  },
  {
    name: "Por quê?",
    key: "whyCards",
    kind: "why",
    tooltip:
      "Descreva qual é o interesse público na solução deste problema e qual é o resultado pretendido.",
  },
  {
    name: "Para quem?",
    key: "forWhoCards",
    kind: "for_who",
    tooltip:
      "Descreva quem será beneficiado com a solução deste problema, quem será o usuário final da solução buscada.",
  },
  {
    name: "Previsão (PAC,PPA, etc.)",
    key: "previsionCards",
    kind: "prevision",
    tooltip:
      "Informe se este projeto estava previsto no Plano de Contratação Anual (ou equivalente, como Planejamento de Compras, etc.)",
  },
  {
    name: "Técnicos",
    key: "technicalCards",
    kind: "technical",
    tooltip:
      "Descreva as especificações técnicas do objeto da contratação, como suas dimensões, capacidade, materiais, tecnologias envolvidas, entre outros aspectos relevantes para sua correta identificação e avaliação",
  },
  {
    name: "Funcionais",
    key: "functionalCards",
    kind: "functional",
    tooltip:
      "Descreva funcionalidades e serviços que devem ser atendidos pelo objeto da contratação, considerando as necessidades da administração pública e dos usuários.",
  },
  {
    name: "Operacionais",
    key: "operationalCards",
    kind: "operational",
    tooltip:
      "Descreva os procedimentos, processos e rotinas que devem ser seguidos para que o objeto da contratação possa ser operado adequadamente.",
  },
  {
    name: "Estimativas de quantidade",
    key: "quantityEstimatesCards",
    kind: "quantity_estimates",
    tooltip:
      "Informe as quantidades necessárias para aquisição ou contratação, por meio de uma análise das necessidades, consulta a áreas técnicas, levantamento de dados históricos, projeção de demanda futura, revisão e validação das estimativas. Deve: conter Memórias de cálculo; conter documentos que lhe dão suporte; considerar intermediação com outras contratações e possibilitar economia de escala.",
  },
];

const CREATE_CANVA_CARD = gql`
  mutation createCanvaCard($input: CreateCanvaCardMutationInput!) {
    createCanvaCard(input: $input) {
      errors
      success
      canvaCard {
        canva {
          id
          name
          centerPoints {
            ...CenterPointFieldsFragment
          }
        }
      }
    }
  }
  ${CenterPointFragment}
`;

const UPDATE_CANVA_CARD = gql`
  mutation updateCard($input: UpdateCardMutationInput!) {
    updateCard(input: $input) {
      errors
      success
      canvaCard {
        canva {
          id
          name
          centerPoints {
            ...CenterPointFieldsFragment
          }
        }
      }
    }
  }
  ${CenterPointFragment}
`;

const DELETE_CANVA_CARD = gql`
  mutation deleteCanvaCard($input: DeleteCardMutationInput!) {
    deleteCard(input: $input) {
      errors
      success
      canva {
        id
        name
        centerPoints {
          ...CenterPointFieldsFragment
        }
      }
    }
  }
  ${CenterPointFragment}
`;

const StepProblems = ({ setStep, canva, canEdit }) => {
  const [updateCanvaCard, { loading: loadingUpdate }] =
    useMutation(UPDATE_CANVA_CARD);
  const [createCanvaCard, { loading: loadingCreate }] =
    useMutation(CREATE_CANVA_CARD);
  const [deleteCanvaCard, { loading: loadingDelete }] =
    useMutation(DELETE_CANVA_CARD);
  const [centerPointsFirst, setCenterPointsFirst] = useState({});
  const [centerPointsSecond, setCenterPointsSecond] = useState({});

  const centerPoints = canva?.centerPoints;
  const centerPointsColsFirst = centerPointsCols.slice(0, 4);
  const centerPointsColsSecond = centerPointsCols.slice(4, 8);

  useEffect(() => {
    const centerPointsFirst = centerPointsColsFirst.reduce(
      (acc, col) => ({
        ...acc,
        [col.key]: centerPoints?.[col.key] || [],
      }),
      {}
    );
    const centerPointsSecond = centerPointsColsSecond.reduce(
      (acc, col) => ({
        ...acc,
        [col.key]: centerPoints?.[col.key] || [],
      }),
      {}
    );
    setCenterPointsFirst(centerPointsFirst);
    setCenterPointsSecond(centerPointsSecond);
  }, [centerPoints]);

  return (
    <div>
      <DragAndDropPostIt
        canvaId={canva?.id}
        colsCards={centerPointsFirst}
        cols={centerPointsColsFirst}
        createCanvaCard={createCanvaCard}
        updateCanvaCard={updateCanvaCard}
        deleteCanvaCard={deleteCanvaCard}
        disabled={!canEdit}
        loading={loadingUpdate || loadingCreate || loadingDelete}
        containerStyle="bg-blue-canva grid grid-cols-1 lg:grid-cols-4"
      />
      <Title>Requisitos da aquisição/contratação</Title>
      <DragAndDropPostIt
        canvaId={canva?.id}
        colsCards={centerPointsSecond}
        cols={centerPointsColsSecond}
        createCanvaCard={createCanvaCard}
        updateCanvaCard={updateCanvaCard}
        deleteCanvaCard={deleteCanvaCard}
        disabled={!canEdit}
        loading={loadingUpdate || loadingCreate || loadingDelete}
        containerStyle="bg-blue-canva grid grid-cols-1 lg:grid-cols-4 mt-0"
      />
      {canEdit && (
        <Flex justifyContent="end" className="mt-4">
          <ButtonConfetti
            title={"Salvar"}
            onClick={() => {
              setStep(3);
            }}
          />
        </Flex>
      )}
    </div>
  );
};

export default StepProblems;
