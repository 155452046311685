import { useRef, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import queryString from "query-string";
import { ChevronRight } from "@styled-icons/fa-solid";

import { gql, useQuery } from "../../contexts/ApiContext";
import useScrollObserver from "../../hooks/useScrollObserver";

import Sidebar from "../../components/Sidebar";
import Loader from "../../components/Loader";
import { AvatarList } from "../../components/AvatarList";
import CanvaLikeButtonContainer from "../../containers/CanvaLikeButtonContainer";
import ViewsCount from "../../components/ViewsCount";

const GET_CANVA = gql`
  query getPublicCanvas($where: PublicCanvasSearchTerms, $after: String) {
    publicCanvas(where: $where, after: $after, first: 10) {
      edges {
        node {
          id
          name
          uid
          isLiked
          likesCount
          viewsCount
          updatedAt
          centerPoints {
            problemToBeSolvedCards {
              id
              content
            }
          }
          solution {
            solutionDescriptionCards {
              id
              content
            }
          }
          user {
            id
            name
            avatar {
              id
              url
            }
          }
          users {
            id
            name
            avatar {
              id
              url
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export default function Community() {
  const [searchParams, setSearchParams] = useSearchParams();
  const observerRef = useRef(null);

  const parsedParams = queryString.parse(searchParams.toString());

  const { data, loading, fetchMore } = useQuery(GET_CANVA, {
    variables: { where: parsedParams },
  });

  const publicCanvas = data?.publicCanvas?.edges || [];
  const pageInfo = data?.publicCanvas?.pageInfo;

  const fetchMoreData = () => {
    !!pageInfo?.hasNextPage &&
      fetchMore({
        variables: { after: pageInfo.endCursor },
      });
  };

  useScrollObserver(observerRef, fetchMoreData);

  return (
    <Sidebar>
      <div className="p-6">
        <h1 className="mt-2.5 text-3xl font-semibold">Comunidade</h1>
        <h2 className="mt-5 text-2xl font-semibold">
          Bem-vindo(a)s à Comunidade Licita Canvas!
        </h2>
        <CommunityInfos />
        {parsedParams?.search && (
          <div className="flex items-center gap-4">
            <p>Buscando por: "{parsedParams?.search}"</p>
            <span
              className="text-xs underline text-purple-heart cursor-pointer"
              onClick={() => setSearchParams("")}
            >
              Remover busca
            </span>
          </div>
        )}
        <div className="mt-6 grid grid-cols-1 gap-4 ">
          {publicCanvas.map((canva) => (
            <PublicCanvaItem key={canva.node.id} canva={canva.node} />
          ))}
        </div>
        {!loading && !publicCanvas?.length && (
          <h3 className="text-lg">
            Não foram encontrados canvas para sua busca.
          </h3>
        )}
        <div ref={observerRef} />
        {loading && <Loader />}
      </div>
    </Sidebar>
  );
}

const CommunityInfos = () => {
  const data = [
    {
      id: 1,
      title: "Nosso objetivo",
      description:
        "Esta comunidade foi criada para aproximar elaboradores de Estudos Técnicos Preliminares e estudiosos de compras públicas de todo o Brasil, reunindo um acervo de informações que podem auxiliá-los, além de possibilitar a comunicação entre órgãos e entidades da Administração Pública que estão buscando soluções para problemas similares. A ideia é criarmos uma grande rede de ajuda mútua entre usuários, clientes, parceiros e colaboradores da Soluções Governamentais para compartilhar seus conhecimentos e colaborar no uso das melhores práticas de compras públicas.",
    },
    {
      id: 2,
      title: "O que você vai encontrar aqui",
      description: `A nossa comunidade contempla a possibilidade de visualização, impressão e inclusão de comentário em todo e qualquer Licita Canvas elaborado ou em elaboração, que esteja cadastrado no modo "público". A visualização dos Licita Canvas no nosso "feed" está ordenada da atualização mais recente para a mais antiga, e também existe a possibilidade de utilizar a barra de pesquisa situada no menu superior para pesquisar palavras-chave, para encontrar os Licita Canvas que contém a palavra do seu interesse. Importante dizer que a quantidade de visualizações dos Licita Canvas, representada pelo símbolo de um olho, considera quantos usuários visualizaram o documento. Assim que um membro da comunidade comentar no seu Licita Canvas, você será notificado na sua área de usuário.`,
    },
    {
      id: 3,
      title: "Curta, colabore, converse, compartilhe!",
      description:
        "Fique à vontade para curtir os Licita Canvas que visitar e também para registrar o seu comentário, seja para deixar um elogio, uma dúvida ou contribuir com alguma informação que você tem conhecimento.",
    },
    {
      id: 4,
      title: "Seja gentil",
      description:
        "Todos merecem e devem ser tratados com respeito e cortesia. Nossa comunidade não tolerará ofensas, desrespeito, crimes de ódio e congêneres. Saibam que nossa moderação tem total liberdade para editar e até mesmo excluir comentários considerados ofensivos.",
    },
    {
      id: 5,
      title:
        "Fiquem à vontade! Essa comunidade é feita por vocês e para vocês.",
      description: `Se vocês tiverem alguma dúvida sobre a comunidade, por favor, comentem no fórum de discussão utilizando a tag "comunidade", que nossa equipe fará o possível para ajudá-los!`,
    },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      contentRef.current.style.maxHeight = `${
        contentRef.current.scrollHeight + 500
      }px`;
    } else {
      contentRef.current.style.maxHeight = "0px";
    }
  }, [isOpen]);

  return (
    <div>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between items-center cursor-pointer py-3 hover:opacity-70 transition-opacity duration-100"
      >
        <h3 className="text-xl">
          Primeira vez aqui? Saiba por onde começar clicando aqui.
        </h3>
        <ChevronRight
          className={`h-5 w-5 mx-3 transform transition-transform ${
            isOpen ? "rotate-90" : ""
          }`}
        />
      </div>
      <div
        ref={contentRef}
        className={`overflow-hidden transition-all duration-500`}
      >
        {data.map((info) => (
          <CollapsableItem title={info.title} description={info.description} />
        ))}
      </div>
    </div>
  );
};

const CollapsableItem = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
    } else {
      contentRef.current.style.maxHeight = "0px";
    }
  }, [isOpen]);

  return (
    <div className="border-b border-gray-300">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between items-center cursor-pointer py-3 hover:opacity-70 transition-opacity duration-100"
      >
        <h2 className="text-md font-semibold">{title}</h2>
        <ChevronRight
          className={`h-5 w-5 mx-3 transform transition-transform ${
            isOpen ? "rotate-90" : ""
          }`}
        />
      </div>
      <div
        ref={contentRef}
        className="overflow-hidden transition-all duration-300"
      >
        <p className="text-sm mb-5">{description}</p>
      </div>
    </div>
  );
};

const PublicCanvaItem = ({ canva }) => {
  const users = [canva.user, ...canva.users];

  const problemsToBeSolved = canva?.centerPoints?.problemToBeSolvedCards
    .slice(0, 1)
    .map((problems) => problems?.content)[0];

  const solutionDescription = canva?.solution?.solutionDescriptionCards
    .slice(0, 1)
    .map((solution) => solution?.content)[0];

  return (
    <div className="flex flex-col md:flex-row md:items-center justify-between gap-2 pr-4 rounded-lg border bg-white shadow-md duration-300 hover:border-purple-heart/40">
      <Link to={`/community/canvas/${canva.uid}`} className="p-4 flex-1">
        <div className="flex items-center gap-4">
          <div>
            <p className="text-xl line-clamp-1 font-medium text-gray-900">
              {canva.name}
            </p>
            <p className="text-xs text-gray-500">Canvas</p>
          </div>
          <AvatarList users={users} />
        </div>
        <div className="mt-4 space-y-2">
          {problemsToBeSolved && (
            <p>
              <span className="font-semibold">Problema: </span>{" "}
              {problemsToBeSolved}
            </p>
          )}
          {solutionDescription && (
            <p>
              <span className="font-semibold">Solução: </span>
              {solutionDescription}
            </p>
          )}
        </div>
      </Link>
      <div className="flex flex-col h-full pt-4 pb-2 justify-between items-end">
        <div className="flex gap-2">
          <ViewsCount viewsCount={canva.viewsCount} />
          <CanvaLikeButtonContainer canva={canva} />
        </div>
        <span className="text-xs text-gray-500 mt-1">
          Atualizado em {new Date(canva?.updatedAt).toLocaleString()}
        </span>
      </div>
    </div>
  );
};
