import { Modal } from "@mui/material";
import Button from "../Button";

export function ModalDelete({
  open,
  onClose,
  onConfirm,
  loading,
  title,
  description,
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal flex items-center justify-center"
    >
      <div className="bg-white max-w-sm w-full px-4 py-6 m-2 rounded-md">
        <p className="text-shark font-semibold text-2xl">{title}</p>
        <p className="text-shark text-sm font-normal mb-5 mt-2">
          {description ||
            "Se optar por excluir, lembre-se de que não será possível recuperar a informação"}
        </p>
        <div className="flex justify-end gap-2">
          <Button type="submit" variant="outline" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="danger"
            onClick={onConfirm}
            loading={loading}
          >
            Excluir
          </Button>
        </div>
      </div>
    </Modal>
  );
}
