import { AppProvider } from "./contexts/AppContext";
import MainRoutes from "./routes";

import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <AppProvider>
      <MainRoutes />
    </AppProvider>
  );
}

export default App;
