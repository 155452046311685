import { useEffect, useRef, useState } from "react";
import { ChevronRight } from "@styled-icons/fa-solid";

import { gql, useQuery } from "../../contexts/ApiContext";

import Sidebar from "../../components/Sidebar";

const GET_FREQUENTLY_ASKED_QUESTIONS = gql`
  query frequentlyAskedQuestions {
    frequentlyAskedQuestions {
      id
      title
      description
    }
  }
`;

const FrequentlyAskedQuestions = () => {
  const { data } = useQuery(GET_FREQUENTLY_ASKED_QUESTIONS);

  const frequentlyAskedQuestions = data?.frequentlyAskedQuestions || [];

  return (
    <Sidebar>
      <div className="p-6">
        <h1 className="mt-2.5 text-3xl font-semibold">Dúvidas Frequentes</h1>
        <div className="mt-6">
          {frequentlyAskedQuestions.map((faq) => (
            <CollapsableItem
              key={faq.id}
              title={faq.title}
              description={faq.description}
            />
          ))}
        </div>
      </div>
    </Sidebar>
  );
};

const CollapsableItem = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
    } else {
      contentRef.current.style.maxHeight = "0px";
    }
  }, [isOpen]);

  return (
    <div className="border-b border-gray-300">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between items-center cursor-pointer py-3 hover:opacity-70 transition-opacity duration-100"
      >
        <h2 className="text-lg font-semibold">{title}</h2>
        <ChevronRight
          className={`h-5 w-5 mx-3 transform transition-transform ${
            isOpen ? "rotate-90" : ""
          }`}
        />
      </div>
      <div
        ref={contentRef}
        className="overflow-hidden transition-all duration-300"
      >
        <p className="text-sm mb-5">{description}</p>
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
