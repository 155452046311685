import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { BubbleMenu, EditorContent, useEditor } from "@tiptap/react";
import { Modal } from "@mui/material";
import ListItem from "@tiptap/extension-list-item";
import Placeholder from "@tiptap/extension-placeholder";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import cn from "classnames";
import {
  Bold,
  Italic,
  LinkIcon,
  List,
  Quote,
  Underline as UnderlineIcon,
} from "lucide-react";

import Button from "../Button";
import Input from "../Input";

import "./style.css";

const MenuBar = ({ editor }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState("");

  const openModal = useCallback(() => {
    setUrl(editor.getAttributes("link").href);
    setIsOpen(true);
  }, [editor]);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setUrl("");
  }, []);

  const saveLink = useCallback(() => {
    if (url) {
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url, target: "_blank" })
        .run();
    } else {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
    }
    closeModal();
  }, [editor, url, closeModal]);

  const removeLink = useCallback(() => {
    editor.chain().focus().extendMarkRange("link").unsetLink().run();
    closeModal();
  }, [editor, closeModal]);

  if (!editor) {
    return null;
  }

  return (
    <div className="flex items-center gap-1 rounded-md border-0 p-2 mb-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300">
      <ToolbarItem
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        isActive={editor.isActive("bold")}
        ariaLabel="Negrito"
      >
        <Bold className="h-4 w-4" />
      </ToolbarItem>
      <ToolbarItem
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        isActive={editor.isActive("italic")}
        ariaLabel="Itálico"
      >
        <Italic className="w-4 h-4" />
      </ToolbarItem>
      <ToolbarItem
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        isActive={editor.isActive("bulletList")}
        ariaLabel="Lista com Marcadores"
      >
        <List className="w-4 h-4" />
      </ToolbarItem>
      <ToolbarItem
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        isActive={editor.isActive("blockquote")}
        ariaLabel="Bloco de Citação"
      >
        <Quote className="w-4 h-4" />
      </ToolbarItem>
      <ToolbarItem
        onClick={openModal}
        isActive={editor.isActive("link")}
        ariaLabel="Link"
      >
        <LinkIcon className="w-4 h-4" />
      </ToolbarItem>
      <LinkBubbleMenu
        editor={editor}
        openModal={openModal}
        removeLink={removeLink}
      />
      <LinkModal
        open={modalIsOpen}
        url={url}
        setUrl={setUrl}
        saveLink={saveLink}
        closeModal={closeModal}
      />
    </div>
  );
};

const ToolbarItem = ({ onClick, children, isActive, ariaLabel }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      aria-label={ariaLabel}
      className={cn(
        "border border-gray-200 p-1.5 rounded-md focus:ring-2 focus:ring-purple-heart",
        {
          "bg-gray-200": isActive,
        }
      )}
    >
      {children}
    </button>
  );
};

const LinkBubbleMenu = ({ editor, openModal, removeLink }) => {
  return (
    <BubbleMenu
      className="flex gap-2 bg-white border border-gray-200 rounded-md p-2"
      tippyOptions={{ duration: 150 }}
      editor={editor}
      shouldShow={({ editor, from, to }) => {
        return from === to && editor.isActive("link");
      }}
    >
      <button
        className="text-sm text-gray-900"
        type="button"
        onClick={openModal}
      >
        Editar
      </button>
      <button
        className="text-sm text-red-500"
        type="button"
        onClick={removeLink}
      >
        Remover
      </button>
    </BubbleMenu>
  );
};

const LinkModal = ({ open, url, setUrl, saveLink, closeModal }) => {
  return (
    <Modal
      open={open}
      onClose={closeModal}
      className="modal flex items-center justify-center"
    >
      <div className="bg-white max-w-sm w-full px-4 py-6 m-2 rounded-md">
        <Input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="URL"
        />
        <div className="flex gap-2 mt-2 justify-end">
          <Button type="button" onClick={closeModal} variant="outline">
            Cancelar
          </Button>
          <Button type="button" onClick={saveLink} variant="secondary">
            Salvar
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const RichTextInput = forwardRef(
  ({ onChange, value, placeholder, disableEditing }, ref) => {
    const extensions = [
      TextStyle.configure({ types: [ListItem.name] }),
      Underline.configure(),
      StarterKit.configure({
        bulletList: {
          HTMLAttributes: {
            class: "list-disc px-4",
          },
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
        blockquote: {
          HTMLAttributes: {
            class: "blockquote-custom",
          },
        },
      }),
      Placeholder.configure({
        considerAnyAsEmpty: true,
        placeholder: placeholder,
      }),
      Link.configure({
        openOnClick: false,
        HTMLAttributes: {
          class: "text-blue-500 underline",
          target: "_blank",
        },
      }),
    ];

    const editor = useEditor({
      extensions,
      content: value,
      editorProps: {
        attributes: {
          class:
            "w-full overflow-y-auto rounded-md border-0 text-gray-900 h-40 p-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-visible:outline-none focus:ring-purple-heart cursor-text",
        },
      },
      onUpdate: ({ editor }) => {
        if (editor.isEmpty) {
          onChange("");
          return;
        }
        onChange(editor.getHTML());
      },
    });

    if (disableEditing & !value) {
      editor?.commands?.clearContent();
    }

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (editor?.view) {
          editor.view.dom.focus();
        }
      },
    }));

    return (
      <>
        <MenuBar editor={editor} />
        <EditorContent editor={editor} className="text-sm" />
      </>
    );
  }
);

RichTextInput.displayName = "RichTextInput";
