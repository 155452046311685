import { useEffect, useState } from "react";
import { Alert, Modal } from "@mui/material";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import Input from "../Input";
import Button from "../Button";
import { TopicCategoriesSelectContainer } from "../../containers/TopicCategoriesSelectContainer";
import { RichTextInput } from "../RichTextInput";

const formSchema = z.object({
  title: z.string().min(1, { message: "Título é obrigatorio" }),
  subtitle: z.string().min(1, { message: "Subtítulo é obrigatorio" }),
  topicCategoryIds: z
    .string()
    .array()
    .min(1, { message: "Selecione ao menos uma categoria" })
    .max(3, { message: "Selecione no máximo 3 categorias" }),
  description: z.string().min(1, { message: "Descrição é obrigatorio" }),
});

export default function TopicForm({
  title,
  onSubmit,
  loadingSubmit,
  OpenFormButton,
  initialValues = {
    title: "",
    subtitle: "",
    description: "",
    topicCategoryIds: [],
  },
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    getValues,
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
  });

  const topicCategoryIds = watch("topicCategoryIds");
  const messageInfo = Object.keys(errors)?.map((key) => errors?.[key]?.message);

  const onSubmitForm = async (data) => {
    await onSubmit(data);
    closeModal();
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    reset(initialValues);
  }, [isModalOpen]);

  return (
    <>
      {OpenFormButton && (
        <OpenFormButton onClick={() => setIsModalOpen(true)} />
      )}
      <Modal
        className="modal flex items-center justify-center"
        open={isModalOpen}
        onClose={closeModal}
      >
        <div className="bg-white max-w-lg w-full mx-2 px-4 py-6 rounded-md">
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <h2 className="text-shark text-center font-semibold text-2xl mb-3">
              {title}
            </h2>
            {!!messageInfo?.length && (
              <Alert severity="error" className="mb-2">
                {messageInfo?.[0]}
              </Alert>
            )}
            <label
              className="text-shark-300 font-semibold text-lg"
              htmlFor="title"
            >
              Título
            </label>
            <Input key="title" name="title" {...register("title")} />
            <label
              className="text-shark-300 font-semibold text-lg"
              htmlFor="subtitle"
            >
              Subtítulo
            </label>
            <Input key="subtitle" name="subtitle" {...register("subtitle")} />
            <label
              className="text-shark-300 font-semibold text-lg"
              htmlFor="subtitle"
              errorMsg={errors?.title?.sub}
            >
              Categorias
            </label>
            <TopicCategoriesSelectContainer
              value={topicCategoryIds}
              onChange={(values) => setValue("topicCategoryIds", values)}
            />
            <label
              className="text-shark-300 font-semibold text-lg"
              htmlFor="description"
            >
              Conteúdo
            </label>
            <RichTextInput
              value={getValues("description")}
              onChange={(value) => setValue("description", value)}
            />
            <div className="flex justify-end gap-2 mt-4">
              <Button variant="outline" onClick={closeModal}>
                Cancelar
              </Button>
              <Button type="submit" variant="secondary" loading={loadingSubmit}>
                Salvar
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
