import { useState } from "react";
import { Trash } from "@styled-icons/fa-solid";
import { toast } from "react-toastify";

import { gql, useMutation } from "../contexts/ApiContext";

import { ModalDelete } from "../components/ModalDelete";

const DELETE_TOPIC = gql`
  mutation deleteTopic($input: DeleteTopicMutationInput!) {
    deleteTopic(input: $input) {
      success
      errors
    }
  }
`;

export default function ModalDeleteTopicContainer({ id, onDelete, className }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [deleteTopicMutation, { loading: loadingDeleteTopic }] =
    useMutation(DELETE_TOPIC);

  const handleDeleteTopic = async () => {
    const result = await deleteTopicMutation({
      variables: { input: { id } },
    });

    const errors = result?.data?.deleteTopic?.errors;

    setIsModalOpen(false);

    if (errors) {
      toast.error(
        "Não foi possivel deletar o tópico, tente novamente mais tarde!"
      );
      return;
    }

    toast.success("Tópico deletado com sucesso!");
    onDelete && onDelete();
  };

  return (
    <span className={className}>
      <Trash
        className="text-red-500 w-5 h-5 cursor-pointer hover:text-red-600"
        onClick={() => setIsModalOpen(true)}
      />
      <ModalDelete
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleDeleteTopic}
        loading={loadingDeleteTopic}
        title="Deseja Excluir o Tópico?"
      />
    </span>
  );
}
