import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { getAllStates, getStateCities } from "easy-location-br";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { toast } from "react-toastify";

import Select from "../../components/Select";
import Input from "../../components/Input";
import { Navbar } from "../../components/Navbar";

import { gql, useMutation } from "../../contexts/ApiContext";
import { useApp } from "../../contexts/AppContext";
import { Footer } from "../../components/Footer";

import RegisterImagem from "../../assets/register_image.png";
import Button from "../../components/Button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Alert, Checkbox } from "@mui/material";
import { tryUserSignup } from "../../constants/Auth";

const Form = styled.form`
  width: 100%;
`;

const SIGNUP_MUTATION = gql`
  mutation signup($input: SignUpMutationInput!) {
    signUp(input: $input) {
      errors
      token {
        accessToken
      }
      user {
        id
      }
    }
  }
`;

const formSchema = z.object({
  name: z.string().min(3, { message: "Nome é obrigatório" }),
  email: z.string().email({ message: "Email é obrigatório" }),
  phone: z.string().min(1, { message: "Telefone é obrigatorio" }),
  governmentEntity: z
    .string()
    .min(2, { message: "Órgão/Entidade Governamental é obrigatório" }),
  state: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  password: z.string().min(6, { message: "Senha é obrigatorio" }),
  policy: z.boolean().refine(
    (value) => value,
    (_value) => ({ message: "Aceite nos termos de uso e política de privacidade é obrigatório" })
  ),
});

const Register = () => {
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/login");
  };

  const { signIn } = useApp();

  const [userSignup, { loading }] = useMutation(SIGNUP_MUTATION);

  const onSubmit = async (values) => {
    const strongValidations = validations.reduce(
      (acc, currentValue) => acc && currentValue?.isValid
    );
    if (!strongValidations) return;

    delete values.policy

    const result = await userSignup({
      variables: {
        input: values,
      },
    });
    const { token, errors } = result?.data?.signUp || {};

    if (errors) {
      toast.error(errors[0]);
      return;
    }

    if (token?.accessToken) {
      const newToken = await tryUserSignup({
        access_token: token.accessToken,
        refresh_token: token.refreshToken,
      });

      const keepSession = true;

      await signIn(newToken, keepSession);
      navigate("/");
    }
  };

  const getCitiesByState = (state) => {
    setCities(getStateCities(state));
  };

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    watch,
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      governmentEntity: "",
      password: "",
      state: "",
      city: "",
      policy: false,
    },
  });

  const passwordField = watch("password");
  const stateField = watch("state");

  const minLengthValidation = {
    text: "Mínimo 6 caracteres",
    isValid: passwordField?.trim()?.length >= 6,
    isFilled: !!passwordField,
  };
  const upperCaseValidation = {
    text: "Letra maiúscula",
    isValid: !!passwordField?.match(/[A-Z]+/g)?.length,
    isFilled: !!passwordField,
  };
  const lowerCaseValidation = {
    text: "Letra minúscula",
    isValid: !!passwordField?.match(/[a-z]+/g)?.length,
    isFilled: !!passwordField,
  };
  const digitValidation = {
    text: "Número",
    isValid: !!passwordField?.match(/\d+/g)?.length,
    isFilled: !!passwordField,
  };
  const haveSpecialCharacter = {
    text: "Caractere especial",
    isValid: !!passwordField?.match(/\W|_/)?.length,
    isFilled: !!passwordField,
  };

  const validations = [
    minLengthValidation,
    upperCaseValidation,
    lowerCaseValidation,
    digitValidation,
    haveSpecialCharacter,
  ];

  const messageInfo = Object.keys(errors)?.map((key) => errors?.[key]?.message);

  useEffect(() => {
    if (messageInfo.length) {
      // eslint-disable-next-line no-restricted-globals
      scrollTo(0, 0);
    }
  }, [messageInfo]);

  useEffect(() => {
    getCitiesByState(stateField);
  }, [stateField]);

  useEffect(() => {
    setStates(getAllStates());
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="flex-1 flex flex-col items-center max-w-6xl w-full mx-auto px-6 py-6 lg:flex-row">
        <div className="flex-1 shrink-0">
          <div className="max-w-sm">
            <div className="flex items-center justify-center">
              <h1 className="text-xl">Já possui uma conta?</h1>
              <Button variant="tertiary" onClick={handleClick}>
                Entrar
              </Button>
            </div>
            <div className="bg-lavender-canva rounded-3xl px-5 py-6 mt-6">
              <h3 className="text-xl font-extrabold">Cadastro</h3>
              <div className="my-4">
                {!!messageInfo?.length && (
                  <Alert severity="error">{messageInfo?.[0]}</Alert>
                )}
              </div>
              <div className="mt-4">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Input
                    name="name"
                    label="Nome"
                    className="rounded-3xl px-4"
                    {...register("name")}
                  />
                  <Input
                    name="email"
                    label="E-mail"
                    type="email"
                    className="rounded-3xl px-4"
                    {...register("email")}
                  />
                  <Input
                    label="Telefone"
                    name="phone"
                    mask="(99) 99999-9999"
                    className="rounded-3xl px-4"
                    onChange={(e) => setValue("phone", e.target.value)}
                  />
                  <Input
                    name="governmentEntity"
                    label="Órgão/Entidade Governamental"
                    className="rounded-3xl px-4"
                    {...register("governmentEntity")}
                  />
                  <div className="flex gap-6">
                    <Select
                      label="Estado"
                      name="state"
                      className="rounded-3xl px-4"
                      option={states.map((state) => ({
                        value: state.id,
                        label: state.name,
                      }))}
                      {...register("state")}
                    />
                    <Select
                      label="Cidade"
                      name="city"
                      className="rounded-3xl px-4"
                      option={cities.map((city) => ({
                        value: city.name,
                        label: city.name,
                      }))}
                      {...register("city")}
                    />
                  </div>
                  <Input
                    name="password"
                    label="Senha"
                    type="password"
                    className="rounded-3xl px-4"
                    {...register("password")}
                  />
                  <div className="flex flex-wrap gap-1 mb-3">
                    {validations?.map((validation) => (
                      <StrongPasswordItem
                        key={validation.text}
                        isValid={validation.isValid}
                        text={validation.text}
                        isFilled={validation.isFilled}
                      />
                    ))}
                  </div>
                  <div className="flex mb-6">
                    <Checkbox className="mt-2 text-purple-heart" {...register("policy")}></Checkbox>
                    <p
                      className="text-xs text-center font-medium mt-4"
                    >
                      Ao criar minha conta, estou de acordo com os
                      {" "}
                      <a
                        className="underline text-purple-heart"
                        href="https://www.solucoesgovernamentais.com.br/termos-de-uso"
                        target="_blank">
                        termos de uso
                      </a>
                      {" "} do software e
                      {" "}
                      <a
                        className="underline text-purple-heart"
                        href="https://www.solucoesgovernamentais.com.br/privacidade"
                        target="_blank">
                        política de privacidade
                      </a>
                      {" "}.
                    </p>
                  </div>
                  <div className="w-fit mx-auto">
                    <Button
                      variant="secondary"
                      type="submit"
                      disabled={loading}
                      loading={loading}
                    >
                      Criar minha conta
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 shrink-0">
          <img alt="postits" src={RegisterImagem} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

const StrongPasswordItem = ({ text, isValid, isFilled }) => {
  return (
    <li
      className={cn(
        "items-center flex flex-row gap-1 mt-1 bg-gray-100 py-1 px-3 rounded-lg",
        {
          "bg-red-50": !isValid && isFilled,
          "bg-green-100": isValid && isFilled,
        }
      )}
    >
      {isFilled && (
        <div className="shrink-0">
          {isValid ? (
            <CheckCircleOutlineIcon className="text-green-500 w-3.5" />
          ) : (
            <HighlightOffIcon className="text-red-500 w-3.5" />
          )}
        </div>
      )}
      <p
        className={cn("text-xs font-semibold", {
          "text-green-600": isValid && isFilled,
          "text-red-500": !isValid && isFilled,
        })}
      >
        {text}
      </p>
    </li>
  );
};

export default Register;
