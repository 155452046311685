import Select from "react-select";
import { gql, useQuery } from "../contexts/ApiContext";
import chroma from "chroma-js";
import { forwardRef } from "react";

const GET_TOPIC_CATEGORIES = gql`
  query getTopicCategories {
    topicCategories {
      color
      createdAt
      id
      name
    }
  }
`;

const selectStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : undefined,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.3).css()
          : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
  placeholder: (styles) => ({ ...styles, color: "#9ca3af" }),
};

export const TopicCategoriesSelectContainer = forwardRef(
  ({ value, onChange }, ref) => {
    const { data } = useQuery(GET_TOPIC_CATEGORIES);

    const topicCategories =
      data?.topicCategories?.map((category) => ({
        value: category?.id,
        label: category?.name,
        color: category?.color,
      })) || [];

    const selectedValues = value?.map((value) =>
      topicCategories?.find((category) => category?.value == value)
    );

    return (
      <Select
        ref={ref}
        placeholder="Selecionar categorias"
        className="mb-4"
        styles={selectStyles}
        isMulti
        options={topicCategories}
        value={selectedValues}
        onChange={(values) => {
          const mappedValues = values?.map((value) => value?.value);
          onChange(mappedValues);
        }}
      />
    );
  }
);
